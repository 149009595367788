import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { Search } from "../../types/search";
import CreateEditTaskForm from "./CreateEditTaskForm";
import useToaster from "../../hooks/useToaster";
import { Task } from "../../types/task";
import TasksService from "../../services/tasksService";
import TaskTable from "./TaskTable";
import useLoading from "../../hooks/useLoading";
import exportData from "../../utils/downloadFile";
import SmallButton from "../../components/SmallButton";

const Divider = styled(MuiDivider)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);
const Button = styled(MuiButton)<ButtonProps>(spacing);

function Tasks() {
  const { showToast } = useToaster();
  const { setIsLoading } = useLoading();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [totalElements, setTotalElements] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [elementsPerPage, setElementsPerPage] = useState<number>(25);
  const [tasks, setTasks] = useState<Task[]>([]);

  function getTasksHandler(
    page: number = 0,
    size: number = 10,
    filter: string = "",
    search: string = "",
    sortOrder: string = "",
    sortField: string = ""
  ) {
    setTableLoading(true);

    TasksService.getTasks(
      page,
      size,
      filter,
      search,
      sortOrder,
      sortField
    ).then((res: Search<Task>) => {
      setTasks(res.content);
      setTotalElements(res.totalElements);
      setTableLoading(false);
    });
  }

  async function submitHandler() {
    getTasksHandler(pageNumber, elementsPerPage, "", "", "asc", "id");
    setOpenCreateModal(false);
    setOpenUpdateModal(false);
  }

  function updateTask(task: Task) {
    setSelectedTask(task);
    setOpenUpdateModal(true);
  }

  function removeTask(task: Task) {
    setSelectedTask(task);
    setOpenRemoveModal(true);
  }

  async function clickRemoveHandler() {
    await TasksService.remove(selectedTask?.id!)
      .then(() => showToast("Task successfully removed!", "success"))
      .then(() => setOpenRemoveModal(false))
      .then(() =>
        getTasksHandler(pageNumber, elementsPerPage, "", "", "asc", "id")
      )
      .catch((err) => showToast(err.message));
  }

  return (
    <>
      <Helmet title="Tasks" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Tasks
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <SmallButton
              mr={2}
              onClick={() =>
                exportData(
                  TasksService,
                  "tasks-filters",
                  "tasks.xlsx",
                  setIsLoading
                )
              }
            >
              <FileDownloadOutlinedIcon />
              Export
            </SmallButton>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCreateModal(true)}
            >
              <AddIcon />
              New Task
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <TaskTable
            tasks={tasks}
            count={totalElements || 0}
            loading={tableLoading}
            getTasks={getTasksHandler}
            setPageNumber={setPageNumber}
            setElementsPerPage={setElementsPerPage}
            updateTask={(task: Task) => updateTask(task)}
            removeTask={(task: Task) => removeTask(task)}
          ></TaskTable>
        </Grid>
      </Grid>

      <Dialog
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Task</DialogTitle>
        <DialogContent>
          <CreateEditTaskForm
            mode="create"
            onSubmit={submitHandler}
            onCancel={() => setOpenCreateModal(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        aria-labelledby="update-task-dialog-title"
      >
        <DialogTitle id="update-task-dialog-title">Update Task</DialogTitle>
        <DialogContent>
          <CreateEditTaskForm
            mode="update"
            task={selectedTask}
            onSubmit={submitHandler}
            onCancel={() => setOpenUpdateModal(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-task-dialog-title"
      >
        <DialogTitle id="remove-task-dialog-title">Remove Task</DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-task-dialog-title">
            {`Do you want to remove ${selectedTask?.name}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Tasks;

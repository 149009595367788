export const CompanyTitles = {
  id: "Company ID",
  name: "Company Name",
  address: "Address",
  city: "City",
  state: "State",
  zip: "Zip",
  contactName: "Contact Name",
  emailAddress: "Email Address",
  phoneNumber: "Phone Number",
  active: "Active",
  activationCode: "Activation Code",
  activationUserLimit: "Activation User Limit",
  disableUserMeasurementImage: "Disable User Measurement Image",
};

import React from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CardContent,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import useToaster from "../../hooks/useToaster";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import { GoalPlan } from "../../types/goalPlan";
import { Task } from "../../types/task";
import GoalPlansService from "../../services/goalPlansService";
import { convertCamelCaseToTitle } from "../../utils/convertCamelCaseToTitle";
import TaskAutocomplete from "../../components/autocomplete/TaskAutocomplete";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type CreateGoalPlanFormProps = {
  mode: "create" | "update";
  goalPlan?: GoalPlan;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

export type GoalPlanForm = {
  name: string;
  affirmation: string;
  taskOne?: Task;
  taskTwo?: Task;
  taskThree?: Task;
  taskFour?: Task;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

function CreateEditGoalPlanForm({
  mode,
  goalPlan,
  onSubmit,
  onCancel,
}: CreateGoalPlanFormProps): JSX.Element {
  const { showToast } = useToaster();

  const taskNames = ["taskOne", "taskTwo"];

  const initialValues: GoalPlanForm = {
    name: goalPlan?.name || "",
    affirmation: goalPlan?.affirmation || "",
    taskOne: goalPlan?.taskOne || undefined,
    taskTwo: goalPlan?.taskTwo || undefined,
    taskThree: goalPlan?.taskThree || undefined,
    taskFour: goalPlan?.taskFour || undefined,
  };

  async function handleSubmit(values: GoalPlanForm) {
    const preparedData: GoalPlan = {
      name: values.name,
      affirmation: values.affirmation,
      taskOne: values.taskOne,
      taskTwo: values.taskTwo,
      taskThree: values.taskThree,
      taskFour: values.taskFour,
    };

    await (mode === "create"
      ? createExercise(preparedData)
      : updateExercise(preparedData, goalPlan?.id!)
    ).then(onSubmit);
  }

  async function createExercise(goalPlan: GoalPlan) {
    await GoalPlansService.create(goalPlan)
      .then(() => showToast("Goal Plan successfully created!", "success"))
      .catch((err) => showToast(err.message));
  }

  async function updateExercise(newGoalPlan: GoalPlan, id: number) {
    await GoalPlansService.update({ ...newGoalPlan, id })
      .then(() => showToast("Goal Plan successfully updated!", "success"))
      .catch((err) => showToast(err.message));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Goal Plan Name"
              value={values.name}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <TextField
              name="affirmation"
              label="Affirmation"
              value={values.affirmation}
              error={Boolean(touched.affirmation && errors.affirmation)}
              fullWidth
              helperText={touched.affirmation && errors.affirmation}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            {taskNames.map((taskName) => (
              <TaskAutocomplete
                id={taskName}
                key={taskName}
                label={convertCamelCaseToTitle(taskName)}
                selectedOption={values[taskName as keyof GoalPlanForm] as Task}
                onOptionSelect={(value) => setFieldValue(taskName, value)}
              />
            ))}

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditGoalPlanForm;

import axios from "../utils/axios";
import { Search } from "../types/search";
import { Exercise } from "../types/exercise";
import { GoalPlan } from "../types/goalPlan";

const GoalPlansService = {
  getGoalPlans: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<GoalPlan>>("/api/goal-plan", {
      params: { page, size, filter, search, sortOrder, sortField },
    });
    return response.data;
  },

  create: async (goalPlan: GoalPlan) => {
    const response = await axios.post<Search<Exercise>>(
      "/api/goal-plan",
      goalPlan
    );
    return response.data;
  },

  update: async (goalPlan: GoalPlan) => {
    const response = await axios.put<GoalPlan>(`/api/goal-plan`, goalPlan);
    return response.data;
  },

  remove: async (id: number) => {
    const response = await axios.delete(`/api/goal-plan/${id}`);
    return response.data;
  },

  getById: async (id: number) => {
    const response = await axios.get<GoalPlan>(`/api/goal-plan/${id}`);
    return response.data;
  },

  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/goal-plan/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default GoalPlansService;

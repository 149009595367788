import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/CognitoContext";
import Loading from "./components/Loading";
import { LoadingProvider } from "./contexts/LoadingContext";
import Toaster from "./components/Toaster";
import { ToasterProvider } from "./contexts/ToasterContext";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { baseConfig } from "./config";
import utc from "dayjs/plugin/utc";
import defaultDayjs from "dayjs";

LicenseInfo.setLicenseKey(baseConfig.muiKey!);

defaultDayjs.extend(utc);

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Myotrim"
          defaultTitle="Myotrim - Web Admin"
        />
        <Provider store={store}>
          {/* @ts-ignore */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ToasterProvider>
                <LoadingProvider>
                  <Toaster />
                  <Loading />
                  <AuthProvider>{content}</AuthProvider>
                </LoadingProvider>
              </ToasterProvider>
            </MuiThemeProvider>
          </LocalizationProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;

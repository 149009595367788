import axios from "../utils/axios";
import { Search } from "../types/search";
import { Program } from "../types/program";

const ProgramsService = {
  getPrograms: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<Program>>("/api/program", {
      params: { page, size, filter, search, sortOrder, sortField },
    });
    return response.data;
  },

  create: async (program: Program) => {
    const response = await axios.post<Search<Program>>("/api/program", program);
    return response.data;
  },

  update: async (program: Program) => {
    const response = await axios.put<Program>(`/api/program`, program);
    return response.data;
  },

  remove: async (id: number) => {
    const response = await axios.delete(`/api/program/${id}`);
    return response.data;
  },

  getById: async (id: number) => {
    const response = await axios.get<Program>(`/api/program/${id}`);
    return response.data;
  },

  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/program/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default ProgramsService;

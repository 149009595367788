import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import { HelpAndSupport } from "../../types/helpAndSupport";
import HelpAndSupportsService from "../../services/helpAndSupportsService";
import { CategoryType } from "../../enums/helpAndSupport";
import { HelpAndSupportTitles } from "./HelpAndSupportTitles";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function HelpAndSupportDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [helpAndSupport, setHelpAndSupport] = useState<HelpAndSupport>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  useEffect(() => {
    setIsLoading(true);
    getHelpAndSupport();
  }, []);

  function getHelpAndSupport() {
    HelpAndSupportsService.getById(Number(id)).then((res: HelpAndSupport) => {
      setHelpAndSupport(res);
      setIsLoading(false);
      getKeyValuesItems(res);
    });
  }

  const titles: { [key: string]: string } = HelpAndSupportTitles;

  function parseValueByEnum(
    key: string,
    value: string | number | boolean
  ): string {
    switch (key) {
      case "category":
        return CategoryType[value as keyof typeof CategoryType];
      case "active":
        return value ? "Active" : "Inactive";
      default:
        return String(value);
    }
  }

  function getKeyValuesItems(exercise: HelpAndSupport): void {
    const items = Object.entries(exercise).map(
      ([k, val]) =>
        ({
          key: titles[k] as string,
          value: parseValueByEnum(k, val),
        } as KeyValue)
    );
    setKeyValuesItems(items);
  }

  return (
    <>
      <Helmet title="HelpAndSupport Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Help & Support #{helpAndSupport?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/support">
          Help & Support
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <KeyValueComponent keyValueItems={keyValuesItems} />
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default HelpAndSupportDetails;

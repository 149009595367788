import React from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CardContent,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { MotivationalQuote } from "../../types/motivationalQuote";
import MotivationalQuotesService from "../../services/motivationalQuotesService";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import * as Yup from "yup";
import useToaster from "../../hooks/useToaster";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const validationSchema = Yup.object().shape({
  date: Yup.date().required("Required"),
  quote: Yup.string().required("Required"),
  day: Yup.number()
    .positive("Day must be a positive number")
    .integer("Day must be an integer")
    .notRequired(),
});

type CreateMotivationalQuoteFormProps = {
  mode: "create" | "update";
  motivationalQuote?: MotivationalQuote;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

export type MotivationalQuoteForm = {
  date: Date | null;
  quote: string;
  day: number;
  author: string;
};

function CreateEditMotivationalQuoteForm({
  mode,
  motivationalQuote,
  onSubmit,
  onCancel,
}: CreateMotivationalQuoteFormProps): JSX.Element {
  const { showToast } = useToaster();

  const initialValues: MotivationalQuoteForm = {
    date: motivationalQuote ? new Date(motivationalQuote.date) : null,
    quote: motivationalQuote?.quote || "",
    day: motivationalQuote?.day || 0,
    author: motivationalQuote?.author || "",
  };

  async function handleSubmit(values: MotivationalQuoteForm) {
    const preparedData: MotivationalQuote = {
      date: values?.date || new Date(),
      quote: values?.quote,
      day: values?.day,
      author: values?.author,
    };

    await (mode === "create"
      ? createMotivationalQuote(preparedData)
      : updateMotivationalQuote(preparedData, motivationalQuote?.id!)
    ).then(onSubmit);
  }

  async function createMotivationalQuote(motivationalQuote: MotivationalQuote) {
    await MotivationalQuotesService.create(motivationalQuote)
      .then(() =>
        showToast("Motivational Quote created successfully.", "success")
      )
      .catch((err) => showToast(err.message));
  }

  async function updateMotivationalQuote(
    newMotivationalQuote: MotivationalQuote,
    id: number
  ) {
    await MotivationalQuotesService.update({
      ...newMotivationalQuote,
      id,
    })
      .then(() =>
        showToast(`Motivational Quote #${id} updated successfully.`, "success")
      )
      .catch((err) => showToast(err.message));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent>
          <form onSubmit={handleSubmit}>
            <DemoContainer components={["DatePicker"]}>
              <Box
                component="div"
                my={2}
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <DatePicker
                  label="Day"
                  value={values.date}
                  format="MM-dd-yyyy"
                  onChange={(date) => setFieldValue("date", date)}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      fullWidth: true,
                      error: Boolean(touched.date && errors.date),
                      helperText: touched.date && errors.date,
                    },
                  }}
                />
              </Box>
            </DemoContainer>

            <TextField
              name="quote"
              label="Motivational Quote"
              value={values.quote}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              multiline
              my={2}
              rows={5}
              error={Boolean(touched.quote && errors.quote)}
              helperText={touched.quote && errors.quote}
            />

            <TextField
              name="day"
              label="Day"
              value={values.day}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              type="number"
              my={2}
              error={Boolean(touched.day && errors.day)}
              helperText={touched.day && errors.day}
            />

            <TextField
              name="author"
              label="Author"
              value={values.author}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditMotivationalQuoteForm;

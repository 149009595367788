import React from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import CreateEditWorkoutForm from "./CreateEditWorkoutForm";
import workoutsService from "../../services/workoutsService";
import { Workout } from "../../types/workout";
import useToaster from "../../hooks/useToaster";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
function DuplicateWorkout() {
  const navigate = useNavigate();
  const { showToast } = useToaster();
  const { state } = useLocation();

  function createWorkout(workout: Workout) {
    workoutsService
      .create(workout)
      .then(() => showToast("Workout successfully created!", "success"))
      .then(() => navigate("/private/workouts"))
      .catch((err) => showToast(err.message));
  }

  function cancel() {
    navigate("/private/workouts");
  }

  return (
    <>
      <Helmet title="Duplicate Workout" />
      <Typography variant="h3" gutterBottom display="inline">
        Duplicate Workout
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/workouts">
          Workouts
        </Link>
        <Typography>Duplicate</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <CreateEditWorkoutForm
                mode="duplicate"
                workoutId={state.workout?.id}
                onSubmit={(workout) => createWorkout(workout)}
                onCancel={cancel}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default DuplicateWorkout;

import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  GoalProgram,
  PhysicalGoalType,
  SelfImprovementGoalType,
} from "../../types/goalProgram";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import Table from "../../components/Table";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import { generateOptionsFromEnum } from "../../utils/generateOptionsFromEnum";
import useWindowWidth from "../../hooks/useWindowSize";

type GoalProgramsTableProps = {
  goalPrograms: GoalProgram[];
  count: number;
  loading: boolean;
  getGoalPrograms: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateGoalProgram: (goalProgram: GoalProgram) => void;
  removeGoalProgram: (goalProgram: GoalProgram) => void;
};

const GoalProgramsTable: React.FC<GoalProgramsTableProps> = ({
  goalPrograms,
  count,
  loading,
  getGoalPrograms,
  setPageNumber,
  setElementsPerPage,
  updateGoalProgram,
  removeGoalProgram,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowWidth();
  const SCREEN_WIDTH = 2200;

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/goal-programs/${params.row.id}/details`);
    }
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    goalProgram: GoalProgram
  ) => {
    event.stopPropagation();
    handleMenuClose(goalProgram.id!);
    updateGoalProgram(goalProgram);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    goalProgram: GoalProgram
  ) => {
    event.stopPropagation();
    handleMenuClose(goalProgram.id!);
    removeGoalProgram(goalProgram);
  };

  const physicalGoalTypeOptions = generateOptionsFromEnum(PhysicalGoalType);
  const selfImprovementGoalTypeOptions = generateOptionsFromEnum(
    SelfImprovementGoalType
  );

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Goal Program ID",
      width: 100,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "name",
      headerName: "Goal Program Name",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "physicalGoal",
      headerName: "Physical Goal",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(physicalGoalTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return PhysicalGoalType[value as keyof typeof PhysicalGoalType];
      },
    },
    {
      field: "selfImprovementGoal",
      headerName: "Self Improvement Goal",
      width: windowWidth < SCREEN_WIDTH ? 175 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(selfImprovementGoalTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return SelfImprovementGoalType[
          value as keyof typeof SelfImprovementGoalType
        ];
      },
    },
    {
      field: "sundayGoalPlan",
      headerName: "Sunday Goal Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "mondayGoalPlan",
      headerName: "Monday Goal Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "tuesdayGoalPlan",
      headerName: "Tuesday Goal Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "wednesdayGoalPlan",
      headerName: "Wednesday Goal Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "thursdayGoalPlan",
      headerName: "Thursday Goal Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "fridayGoalPlan",
      headerName: "Friday Goal Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "saturdayGoalPlan",
      headerName: "Saturday Goal Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const goalProgram = params.row as GoalProgram;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[goalProgram.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) =>
                handleDetailButtonClick(event, goalProgram.id!)
              }
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[goalProgram.id!]}
              open={Boolean(anchorEl[goalProgram.id!])}
              onClose={() => handleMenuClose(goalProgram.id!)}
            >
              <MenuItem
                onClick={(event) => handleUpdateClick(event, goalProgram)}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={(event) => handleRemoveClick(event, goalProgram)}
              >
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="goalPrograms"
      rows={goalPrograms}
      columns={columns}
      count={count}
      loading={loading}
      getData={getGoalPrograms}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
      initialState={{ pinnedColumns: { right: ["actions"] } }}
    />
  );
};

export default GoalProgramsTable;

import axios from "../utils/axios";
import { Search } from "../types/search";
import { HealthQuestionnaire } from "../types/health-questionnaire";

const HealthQuestionnairesService = {
  getQuestionnaireBy: async (id: number) => {
    const response = await axios.get<HealthQuestionnaire>(
      `/api/questionnaire/${id}`
    );
    return response.data;
  },
  getQuestionnaires: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<HealthQuestionnaire>>(
      "/api/questionnaire",
      {
        params: { page, size, filter, search, sortOrder, sortField },
      }
    );
    return response.data;
  },
  createQuestionnaire: async (questionnaire: HealthQuestionnaire) => {
    const response = await axios.post<HealthQuestionnaire>(
      "/api/questionnaire",
      questionnaire
    );
    return response.data;
  },
  editQuestionnaire: async (questionnaire: HealthQuestionnaire) => {
    const response = await axios.put<HealthQuestionnaire>(
      `/api/questionnaire`,
      questionnaire
    );
    return response.data;
  },
  removeQuestionnaire: async (id: number) => {
    const response = await axios.delete(`/api/questionnaire/${id}`);
    return response.data;
  },
  getByUserIdWithLastModifiedDate: async (userId: number) => {
    const response = await axios.get<HealthQuestionnaire>(
      `/api/questionnaire/user/${userId}`
    );
    return response.data;
  },
  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/questionnaire/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default HealthQuestionnairesService;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import { Notification } from "../../types/notification";
import NotificationService from "../../services/NotificationService";
import { GoalEnum } from "../../types/health-questionnaire";
import useLoading from "../../hooks/useLoading";
import { Company } from "../../types/company";
import { NotificationTitles } from "./NotificationTitles";
import { format } from "date-fns";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function NotificationDetails() {
  const { id } = useParams();
  const { setIsLoading } = useLoading();
  const [notification, setNotification] = useState<Notification>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  const titles: { [key: string]: string } = NotificationTitles;

  useEffect(() => {
    setIsLoading(true);
    getExercise();
  }, []);

  function getExercise() {
    NotificationService.getById(Number(id)).then((res: Notification) => {
      setNotification(res);
      setIsLoading(false);
      getKeyValuesItems(res);
    });
  }

  function parseValueByEnum(key: string, value: any): string {
    switch (key) {
      case "notifyDate":
        return format(new Date(value), "MM-dd-yyyy hh:mm a");
      case "goal":
        return GoalEnum[value as keyof typeof GoalEnum];
      case "company":
        return (value as Company).name;
      default:
        return value;
    }
  }

  function getKeyValuesItems(notification: Notification): void {
    const order: (keyof Notification)[] = [
      "id",
      "company",
      "gender",
      "goal",
      "title",
      "message",
      "notifyDate",
    ];

    const items = order.map((key) => {
      const value = notification[key];
      if (value === undefined) {
        return null;
      } else {
        return {
          key: titles[key] as string,
          value: parseValueByEnum(key, value),
        } as KeyValue;
      }
    });
    setKeyValuesItems(items as KeyValue[]);
  }

  return (
    <>
      <Helmet title="Notification Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Notification #{notification?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/notifications">
          Notification
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <KeyValueComponent keyValueItems={keyValuesItems} />
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default NotificationDetails;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import { MotivationalQuoteTitles } from "./MotivationalQuoteTitles";
import { MotivationalQuote } from "../../types/motivationalQuote";
import MotivationalQuotesService from "../../services/motivationalQuotesService";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function MotivationalQuoteDetails() {
  const { id } = useParams();
  const [motivationalQuote, setMotivationalQuote] =
    useState<MotivationalQuote>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  useEffect(() => {
    getExercise();
  }, []);

  function getExercise() {
    MotivationalQuotesService.getById(Number(id)).then(
      (res: MotivationalQuote) => {
        setMotivationalQuote(res);
        getKeyValuesItems(res);
      }
    );
  }

  const titles: { [key: string]: string } = MotivationalQuoteTitles;

  function parseValueByEnum(key: string, value: any): string {
    switch (key) {
      case "date":
        return new Date(value).toLocaleDateString();
      case "day":
        return value.toString();
      case "motivationalQuote":
      case "author":
      case "motivationalQuoteId":
        return value;
      default:
        return value;
    }
  }

  function getKeyValuesItems(motivationalQuote: MotivationalQuote): void {
    const order: (keyof MotivationalQuote)[] = [
      "id",
      "date",
      "quote",
      "author",
      "day",
    ];

    const items = order.map((key) => {
      const value = motivationalQuote[key];
      if (value === undefined) {
        return undefined;
      } else {
        return {
          key: titles[key] as string,
          value: parseValueByEnum(key, value),
        } as KeyValue;
      }
    });
    setKeyValuesItems(items as KeyValue[]);
  }

  return (
    <>
      <Helmet title="Motivational Quote Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Motivational Quote #{motivationalQuote?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/motivation-quotes">
          Motivational Quotes
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <KeyValueComponent keyValueItems={keyValuesItems} />
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default MotivationalQuoteDetails;

import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { WorkoutCheckin } from "../../types/workoutCheckin";
import { format } from "date-fns";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import {
  currentDateOperator,
  dateRangeOperator,
} from "../../utils/filters/dateField";
import Table from "../../components/Table";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";

type WorkoutCheckinsTableProps = {
  workoutCheckins: WorkoutCheckin[];
  count: number;
  loading: boolean;
  getWorkoutCheckins: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateWorkoutCheckin: (workoutCheckin: WorkoutCheckin) => void;
  removeWorkoutCheckin: (workoutCheckin: WorkoutCheckin) => void;
};

const WorkoutCheckinsTable: React.FC<WorkoutCheckinsTableProps> = ({
  workoutCheckins,
  count,
  loading,
  getWorkoutCheckins,
  setPageNumber,
  setElementsPerPage,
  updateWorkoutCheckin,
  removeWorkoutCheckin,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    workoutCheckin: WorkoutCheckin
  ) => {
    event.stopPropagation();
    handleMenuClose(workoutCheckin.id!);
    updateWorkoutCheckin(workoutCheckin);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    workoutCheckin: WorkoutCheckin
  ) => {
    event.stopPropagation();
    handleMenuClose(workoutCheckin.id!);
    removeWorkoutCheckin(workoutCheckin);
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/workout-checkins/${params.row.id}/details`);
    }
  };

  const rows = workoutCheckins.map((workoutCheckin) => ({
    ...workoutCheckin,
    email: workoutCheckin?.user?.email,
    company: workoutCheckin?.user?.company,
  }));

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Workout Checkin ID",
      width: 150,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "checkinDate",
      headerName: "Workout Checkin Date",
      flex: 1,
      filterOperators: [dateRangeOperator, currentDateOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return format(new Date(value), "MM-dd-yyyy");
      },
    },
    {
      field: "company",
      headerName: "Company Name",
      flex: 1,
      filterable: false,
      sortable: false,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name;
      },
    },
    {
      field: "user",
      headerName: "User",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueGetter: (params) =>
        `${params.row?.user?.firstName} ${params.row?.user?.lastName}` || "",
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: 1,
      filterable: false,
      sortable: false,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.username;
      },
    },
    {
      field: "checkinDayOfWeek",
      headerName: "Day of the Week",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const workoutCheckin = params.row as WorkoutCheckin;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={
                anchorEl[workoutCheckin.id!] ? "simple-menu" : undefined
              }
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) =>
                handleDetailButtonClick(event, workoutCheckin.id!)
              }
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[workoutCheckin.id!]}
              open={Boolean(anchorEl[workoutCheckin.id!])}
              onClose={() => handleMenuClose(workoutCheckin.id!)}
            >
              <MenuItem
                onClick={(event) => handleUpdateClick(event, workoutCheckin)}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={(event) => handleRemoveClick(event, workoutCheckin)}
              >
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="workoutCheckins"
      rows={rows}
      columns={columns}
      count={count}
      loading={loading}
      getData={getWorkoutCheckins}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
    />
  );
};

export default WorkoutCheckinsTable;

export const MeasurementsLabel = {
  id: "Measurement ID",
  date: "Measurement Date",
  program: "Program",
  companyName: "Company Name",
  status: "Measurement Status",
  email: "Email Address",
  user: "User",
  gender: "Sex",
  age: "Age",
  height: "Height",
  weight: "Weight",
  frontImage: "Front Image",
  sideImage: "Side Image",
  bodyModel: "Body Model",
  bodyModelUrl: "Body Model File",

  neck: "Neck",
  elbow: "Elbow",
  wrist: "Wrist",
  chest: "Chest",
  waist: "Waist",
  hip: "Hip",
  thigh: "Thigh",
  knee: "Knee",
  calf: "Calf",
  ankle: "Ankle",

  bodyFatPercentage: "Body Fat Percentage",
  fatWeight: "Fat Weight",
  leanBodyMass: "Lean Body Mass",
  lbmiTofbmiRatio: "LBMI to FBMI Ratio",
  bodyMassIndex: "Body Mass Index",
  waistToHeightRatio: "Waist to Height Ratio",
  waistToHipRatio: "Waist to Hip Ratio",
  recommendedCalories: "Recommended Calories",
  recommendedProtein: "Recommended Protein",
  recommendedWater: "Recommended Water",
  fitnessScore: "Fitness Score",
  wellnessScore: "Wellness Score",
  workoutScore: "Workout Score",
  vitalityScore: "Vitality Score",
};

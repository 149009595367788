import { EquipmentEnum } from "../../types/equipment";

const HomeEquipments: string[] = [
  EquipmentEnum.Barbells,
  EquipmentEnum.Dumbbells,
  EquipmentEnum.Elliptical,
  EquipmentEnum.RecumbentBike,
  EquipmentEnum.Treadmill,
  EquipmentEnum.NoEquipment,
];

export default HomeEquipments;

import axios from "../utils/axios";
import { Search } from "../types/search";
import { WorkoutCheckin } from "../types/workoutCheckin";

const WorkoutCheckinsService = {
  getWorkoutCheckins: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<WorkoutCheckin>>(
      "/api/workout-checkin",
      {
        params: { page, size, filter, search, sortOrder, sortField },
      }
    );
    return response.data;
  },

  create: async (workoutCheckin: WorkoutCheckin) => {
    const response = await axios.post<Search<WorkoutCheckin>>(
      "/api/workout-checkin",
      workoutCheckin
    );
    return response.data;
  },

  update: async (workoutCheckin: WorkoutCheckin) => {
    const response = await axios.put<WorkoutCheckin>(
      `/api/workout-checkin`,
      workoutCheckin
    );
    return response.data;
  },

  remove: async (id: number) => {
    const response = await axios.delete(`/api/workout-checkin/${id}`);
    return response.data;
  },

  getById: async (id: number) => {
    const response = await axios.get<WorkoutCheckin>(
      `/api/workout-checkin/${id}`
    );
    return response.data;
  },

  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/workout-checkin/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default WorkoutCheckinsService;

import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import AdminUsersTable from "./AdminUsersTable";
import CreateEditUserForm from "./CreateEditUserForm";
import useToaster from "../../hooks/useToaster";
import useAuth from "../../hooks/useAuth";
import { CognitoUser } from "amazon-cognito-identity-js";
import { TransformedUser } from "../../types/auth";
import { GROUPS } from "../../constants";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Divider = styled(MuiDivider)(spacing);
const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);
const Button = styled(MuiButton)<ButtonProps>(spacing);

function AdminUsers() {
  const { showToast } = useToaster();
  const [users, setUsers] = useState<CognitoUser[]>([]);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [userWillDoAction, setUserWillDoAction] =
    useState<TransformedUser | null>(null);
  const { getUsersFromGroup, deleteUser } = useAuth();

  function getUsersHandler() {
    setTableLoading(true);
    getUsersFromGroup(GROUPS.ADMINS).then((users) => {
      setUsers(users);
      setTableLoading(false);
    });
  }

  function createUserHandler() {
    getUsersHandler();
    setOpenCreateModal(false);
  }

  function clickRemoveHandler() {
    deleteUser(userWillDoAction?.email!, GROUPS.ADMINS)
      .then(() => getUsersHandler())
      .then(() => showToast("User successfully removed!", "success"))
      .then(() => setOpenRemoveModal(false));
  }

  function removeUserHandler(user: TransformedUser) {
    setUserWillDoAction(user);
    setOpenRemoveModal(true);
  }

  return (
    <React.Fragment>
      <Helmet title="Users" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Admins
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCreateModal(true)}
            >
              <AddIcon />
              New Admin
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {users!! && (
            <AdminUsersTable
              users={users}
              loading={tableLoading}
              getUsers={getUsersHandler}
              removeUser={(user: TransformedUser) => removeUserHandler(user)}
            ></AdminUsersTable>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        aria-labelledby="create-user-dialog-title"
      >
        <DialogTitle id="create-user-dialog-title">New Admin</DialogTitle>
        <DialogContent>
          <CreateEditUserForm
            mode="create"
            onSubmit={createUserHandler}
            onCancel={() => setOpenCreateModal(false)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-admin-dialog-title"
      >
        <DialogTitle id="remove-admin-dialog-title">Remove Admin</DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-admin-dialog-title">
            {`Do you want to remove ${userWillDoAction?.name} ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AdminUsers;

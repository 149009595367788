import {
  Autocomplete,
  CircularProgress,
  TextField as MuiTextField,
} from "@mui/material";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Search } from "../../types/search";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import MealsService from "../../services/mealsService";
import { Meal } from "../../types/meal";

type MealAutocompleteProps = {
  onOptionSelect: (meal: Meal | null) => void;
  selectedOption: Meal | undefined;
  label: string;
  id: string;
};

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
export default function MealAutocomplete({
  onOptionSelect,
  selectedOption,
  label,
  id,
}: MealAutocompleteProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Meal[]>([]);
  const [inputValue, setInputValue] = useState("");
  const loading = open && options.length === 0;

  const fetch = useCallback(
    debounce(async (value: string) => {
      const res: Search<Meal> = await MealsService.getMeals(
        0,
        10,
        value ? `name:${value}` : "",
        "",
        "",
        ""
      );
      setOptions(res.content);
    }, 300),
    []
  );

  useEffect(() => {
    fetch(inputValue);
  }, [inputValue, fetch]);

  const handleOpen = async () => {
    setOpen(true);
    if (options.length === 0) {
      const res: Search<Meal> = await MealsService.getMeals(
        0,
        10,
        "",
        "",
        "",
        ""
      );
      setOptions(res.content);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOptionSelect = (_: ChangeEvent<{}>, value: Meal | null) => {
    onOptionSelect(value);
  };

  const handleInputChange = (_: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  return (
    <Autocomplete
      id={id}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleOptionSelect}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      options={options}
      loading={loading}
      fullWidth
      inputValue={inputValue}
      onInputChange={handleInputChange}
      value={selectedOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          my={2}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

import React, { ChangeEvent, useState } from "react";
import { Autocomplete, TextField as MuiTextField } from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { WorkoutScoreLevel } from "../../enums/WorkoutScoreLevel";

type WorkoutScoreLevelAutocompleteProps = {
  onOptionSelect: (scoreLevel: string | null) => void;
  selectedOption: string | undefined;
  label: string;
  id: string;
  isTouched?: boolean;
  error?: string;
};

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export default function WorkoutScoreLevelAutocomplete({
  onOptionSelect,
  selectedOption,
  label,
  id,
  error,
  isTouched,
}: WorkoutScoreLevelAutocompleteProps) {
  const [inputValue, setInputValue] = useState("");

  const options = Object.entries(WorkoutScoreLevel).map(
    ([key, value]) => value
  );

  const handleOptionSelect = (_: ChangeEvent<{}>, value: string | null) => {
    onOptionSelect(value);
  };

  const handleInputChange = (_: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  return (
    <Autocomplete
      id={id}
      onChange={handleOptionSelect}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      options={options}
      fullWidth
      inputValue={inputValue}
      onInputChange={handleInputChange}
      value={selectedOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          my={2}
          error={isTouched && Boolean(error)}
          helperText={isTouched && error}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
    />
  );
}

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import { Equipment } from "../../types/equipment";
import { BodyPart } from "../../types/bodyPart";
import { ProgramsTitles } from "./ProgramTitles";
import ProgramsService from "../../services/programsService";
import { Program } from "../../types/program";
import { Injury } from "../../types/injury";
import { Workout } from "../../types/workout";
import { Location } from "../../types/location";
import useLoading from "../../hooks/useLoading";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function ProgramDetails() {
  const { id } = useParams();
  const { setIsLoading } = useLoading();
  const [program, setProgram] = useState<Program>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  useEffect(() => {
    setIsLoading(true);
    getExercise();
  }, []);

  function getExercise() {
    ProgramsService.getById(Number(id)).then((res: Program) => {
      setProgram(res);
      setIsLoading(false);
      getKeyValuesItems(res);
    });
  }

  const titles: { [key: string]: string } = ProgramsTitles;

  function parseValueByEnum(
    key: string,
    value:
      | string
      | number
      | Equipment[]
      | Location
      | Injury[]
      | Workout
      | BodyPart[]
      | Program
  ): string {
    switch (key) {
      case "equipments":
        return (value as Equipment[])?.map((e) => e.name).join(", ");
      case "injuries":
        return (value as Injury[])?.map((e) => e.name).join(", ");
      case "location":
        return (value as Location)?.name;
      case "sundayWorkout":
      case "mondayWorkout":
      case "tuesdayWorkout":
      case "wednesdayWorkout":
      case "thursdayWorkout":
      case "fridayWorkout":
      case "saturdayWorkout":
        return (value as Workout)?.name;
      case "easySubstitute":
      case "hardSubstitute":
        return (value as Program)?.name;
      default:
        return value as string;
    }
  }

  function getKeyValuesItems(program: Program): void {
    const orderedKeys = [
      "id",
      "name",
      "location",
      "equipments",
      "workoutScoreLevel",
      "injuries",
      "fitnessGoal",
      "sundayWorkout",
      "mondayWorkout",
      "tuesdayWorkout",
      "wednesdayWorkout",
      "thursdayWorkout",
      "fridayWorkout",
      "saturdayWorkout",
      "easySubstitute",
      "hardSubstitute",
    ];
    const items = orderedKeys.map((key) => {
      const value = program[key as keyof Program];
      if (value !== undefined) {
        return {
          key: titles[key] as string,
          value: parseValueByEnum(key, value),
        } as KeyValue;
      }
    });
    setKeyValuesItems(items as KeyValue[]);
  }

  return (
    <>
      <Helmet title="Program Details" />
      <Typography variant="h3" gutterBottom display="inline">
        Program #{program?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/programs">
          Programs
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <KeyValueComponent keyValueItems={keyValuesItems} />
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default ProgramDetails;

import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Page components
import Companies from "./pages/companies";
import CompanyDetails from "./pages/companies/CompanyDetails";

import Users from "./pages/users";
import UserDetails from "./pages/users/UserDetails";

import Meals from "./pages/meals";
import CreateMeal from "./pages/meals/CreateMeal";
import EditMeal from "./pages/meals/EditMeal";
import MealDetails from "./pages/meals/MealDetails";

import Workouts from "./pages/workouts";
import CreateWorkout from "./pages/workouts/CreateWorkout";
import EditWorkout from "./pages/workouts/EditWorkout";
import DuplicateWorkout from "./pages/workouts/DuplicateWorkout";
import WorkoutDetails from "./pages/workouts/WorkoutDetails";

import Exercises from "./pages/exercises";
import ExerciseDetails from "./pages/exercises/ExerciseDetails";

import HealthQuestionnaires from "./pages/health-questionnaires";
import CreateHealthQuestionnairePage from "./pages/health-questionnaires/CreateHealthQuestionnairePage";
import UpdateHealthQuestionnairePage from "./pages/health-questionnaires/UpdateHealthQuestionnairePage";
import HealthQuestionnaireDetailPage from "./pages/health-questionnaires/HealthQuestionnaireDetailPage";

import Measurements from "./pages/measurements";
import CreateMeasurementPage from "./pages/measurements/CreateMeasurementPage";
import UpdateMeasurementPage from "./pages/measurements/UpdateMeasurementPage";
import MeasurementDetails from "./pages/measurements/MeasurementDetails";

import Programs from "./pages/programs";
import ProgramDetails from "./pages/programs/ProgramDetails";
import CreateProgram from "./pages/programs/CreateProgram";
import EditProgram from "./pages/programs/EditProgram";

import NutritionPlans from "./pages/nutrition-plan";
import CreateNutritionPlan from "./pages/nutrition-plan/CreateNutritionPlan";
import EditNutritionPlan from "./pages/nutrition-plan/EditNutritionPlan";
import NutritionPlanDetails from "./pages/nutrition-plan/NutritionPlanDetails";

import NutritionPrograms from "./pages/nutrition-program";
import CreateNutritionProgram from "./pages/nutrition-program/CreateNutritionProgram";
import EditNutritionProgram from "./pages/nutrition-program/EditNutritionProgram";
import NutritionProgramDetails from "./pages/nutrition-program/NutritionProgramDetails";

import MotivationalQuotes from "./pages/motivational-quotes";
import MotivationalQuoteDetails from "./pages/motivational-quotes/MotivationalQuoteDetails";

import HealthArticles from "./pages/health-article";
import CreateHealthArticle from "./pages/health-article/CreateHealthArticle";
import EditHealthArticle from "./pages/health-article/EditHealthArticle";
import HealthArticleDetails from "./pages/health-article/HealthArticleDetails";

import Notifications from "./pages/notification";
import NotificationDetails from "./pages/notification/NotificationDetails";

import Rewards from "./pages/rewards";
import RewardsDetailsPage from "./pages/rewards/RewardsDetailsPage";

import HelpAndSupports from "./pages/help-and-support";
import HelpAndSupportDetails from "./pages/help-and-support/HelpAndSupportDetails";

import WorkoutCheckins from "./pages/workout-checkins";
import WorkoutCheckinDetailsPage from "./pages/workout-checkins/WorkoutCheckinDetailsPage";

import GoalPrograms from "./pages/goal-program";
import CreateGoalProgram from "./pages/goal-program/CreateGoalProgram";
import EditGoalProgram from "./pages/goal-program/EditGoalProgram";
import GoalProgramDetails from "./pages/goal-program/GoalProgramDetails";

import GoalPlans from "./pages/goal-plan";
import GoalPlanDetails from "./pages/goal-plan/GoalPlanDetails";

import Tasks from "./pages/tasks";
import TaskDetails from "./pages/tasks/TaskDetails";

import AdminUsers from "./pages/admins";

import ConfirmPassword from "./pages/auth/ConfirmPassword";

// Protected routes
import { Navigate } from "react-router-dom";
// Private pages

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

const routes = [
  {
    path: "/",
    element: <Navigate to="/sign-in" />,
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <Default />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "saas",
        element: <SaaS />,
      },
    ],
  },
  {
    path: "sign-in",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "reset-password",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "confirm-password",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <ConfirmPassword />,
      },
    ],
  },
  {
    path: "404",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "500",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="companies?page=0&pageSize=25" />,
      },
      {
        path: "dashboard",
        children: [
          {
            path: "default",
            element: <Default />,
          },
          {
            path: "analytics",
            element: <Analytics />,
          },
          {
            path: "saas",
            element: <SaaS />,
          },
        ],
      },
      {
        path: "companies",
        children: [
          { path: "", element: <Companies /> },
          { path: ":id/details", element: <CompanyDetails /> },
        ],
      },
      {
        path: "users",
        children: [
          { path: "", element: <Users /> },
          { path: ":id/details", element: <UserDetails /> },
        ],
      },
      {
        path: "admins",
        children: [{ path: "", element: <AdminUsers /> }],
      },
      {
        path: "health-questionnaires",
        children: [
          { path: "", element: <HealthQuestionnaires /> },
          { path: "create", element: <CreateHealthQuestionnairePage /> },
          { path: "edit", element: <UpdateHealthQuestionnairePage /> },
          { path: ":id/details", element: <HealthQuestionnaireDetailPage /> },
        ],
      },
      {
        path: "measurements",
        children: [
          { path: "", element: <Measurements /> },
          { path: "create", element: <CreateMeasurementPage /> },
          { path: "edit", element: <UpdateMeasurementPage /> },
          { path: ":id/details", element: <MeasurementDetails /> },
        ],
      },
      {
        path: "programs",
        children: [
          { path: "", element: <Programs /> },
          { path: "create", element: <CreateProgram /> },
          { path: "edit", element: <EditProgram /> },
          { path: ":id/details", element: <ProgramDetails /> },
        ],
      },
      {
        path: "workouts",
        children: [
          { path: "", element: <Workouts /> },
          { path: "create", element: <CreateWorkout /> },
          { path: "edit", element: <EditWorkout /> },
          { path: "duplicate", element: <DuplicateWorkout /> },
          { path: ":id/details", element: <WorkoutDetails /> },
        ],
      },
      {
        path: "exercises",
        children: [
          { path: "", element: <Exercises /> },
          { path: ":id/details", element: <ExerciseDetails /> },
        ],
      },
      {
        path: "nutrition-programs",
        children: [
          { path: "", element: <NutritionPrograms /> },
          { path: "create", element: <CreateNutritionProgram /> },
          { path: "edit", element: <EditNutritionProgram /> },
          { path: ":id/details", element: <NutritionProgramDetails /> },
        ],
      },
      {
        path: "nutrition-plans",
        children: [
          { path: "", element: <NutritionPlans /> },
          { path: "create", element: <CreateNutritionPlan /> },
          { path: "edit", element: <EditNutritionPlan /> },
          { path: ":id/details", element: <NutritionPlanDetails /> },
        ],
      },
      {
        path: "meals",
        children: [
          { path: "", element: <Meals /> },
          { path: "create", element: <CreateMeal /> },
          { path: "edit", element: <EditMeal /> },
          { path: ":id/details", element: <MealDetails /> },
        ],
      },
      {
        path: "health-articles",
        children: [
          { path: "", element: <HealthArticles /> },
          { path: "create", element: <CreateHealthArticle /> },
          { path: "edit", element: <EditHealthArticle /> },
          { path: ":id/details", element: <HealthArticleDetails /> },
        ],
      },
      {
        path: "motivation-quotes",
        children: [
          { path: "", element: <MotivationalQuotes /> },
          { path: ":id/details", element: <MotivationalQuoteDetails /> },
        ],
      },
      {
        path: "notifications",
        children: [
          { path: "", element: <Notifications /> },
          { path: ":id/details", element: <NotificationDetails /> },
        ],
      },
      {
        path: "rewards",
        children: [
          { path: "", element: <Rewards /> },
          { path: ":id/details", element: <RewardsDetailsPage /> },
        ],
      },
      {
        path: "workout-checkins",
        children: [
          { path: "", element: <WorkoutCheckins /> },
          { path: ":id/details", element: <WorkoutCheckinDetailsPage /> },
        ],
      },
      {
        path: "support",
        children: [
          { path: "", element: <HelpAndSupports /> },
          { path: ":id/details", element: <HelpAndSupportDetails /> },
        ],
      },
      {
        path: "goal-programs",
        children: [
          { path: "", element: <GoalPrograms /> },
          { path: "create", element: <CreateGoalProgram /> },
          { path: "edit", element: <EditGoalProgram /> },
          { path: ":id/details", element: <GoalProgramDetails /> },
        ],
      },
      {
        path: "goal-plans",
        children: [
          { path: "", element: <GoalPlans /> },
          { path: ":id/details", element: <GoalPlanDetails /> },
        ],
      },
      {
        path: "tasks",
        children: [
          { path: "", element: <Tasks /> },
          { path: ":id/details", element: <TaskDetails /> },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;

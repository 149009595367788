export const HealthQuestionnaireQuestions = {
  id: "HealthQuestionnaire ID",
  date: "Questionnaire Date*",
  company: "Company Name*",
  email: "Email Address*",
  currentWeight: "What is your Current Weight?",
  imageMeasurement:
    "Would you like to get measured via our Myosize image measurement process?",
  pantsSize: "What is your current Pants Size?",
  waistSize: "What is your current Jeans Waist Size?",
  highBloodPressure: "Do you have High Blood Pressure?*",
  highCholesterol: "Do you have High Cholesterol?*",
  diabetes: "Do you have Diabetes?*",
  acidReflux: "Do you have Acid Reflux?*",
  heartDisease: "Do you have Heart Disease?*",
  asthma: "Do you have Asthma?*",
  medication: "Are you currently taking any medications?*",
  injuries:
    "Do you have any muscle/joint injuries or pain? Select all that apply.*",
  exerciseLocation: "What location will you be Exercising at?*",
  equipments:
    "What type of exercise equipment do you have at Home? Select all that apply.*",
  fitnessGoal: "What's your most important Fitness Goal?*",
  weightTrainingLevel: "What best describes your Weight Training Level?*",
  cardioFitnessLevel: "What best describes your Cardio Fitness Level?*",
  exerciseFrequency: "How often do you exercise?*",
  exerciseIntensityLevel:
    "What intensity level do you prefer while exercising?*",
  workoutDifficulty: "How difficult were last month’s workouts for you?*",
  physicalGoal: "Select a physical goal?*",
  selfImprovementGoal: "Select a self-improvement goal?*",
  currentDiet: "What best describes your current diet?*",
  mealFrequency: "How many times a day do you eat? (Meal and/or Snack)*",
  mealPreference: "Do you have any meal preferences?*",
  socialSupportLevel:
    "How would you rate your social support system with your friends and family?*",
  financialSituationLevel:
    "How happy are you in your current financial situation?*",
  dailyStressLevel: "What is your daily stress level?*",
  sleepLevel: "What is your current sleep pattern?*",
  confidenceLevel: "How confident of a person are you?*",
  relationshipLevel:
    "How happy are you with your love life? (If you’re single, rate your level of happiness with being single)*",
  alcohol: "How much Alcohol do you consume?*",
  smokeCigarettes: "Do you Smoke Cigarettes?*",
  difficultTime:
    "Are you currently going through a difficult time in life with any of the following?*",
  user: "User*",
};

export type OptionsFromEnum = {
  value: string;
  label: string;
};

export function generateOptionsFromEnum(
  enumeration: Record<string, string | number>
): OptionsFromEnum[] {
  return Object.entries(enumeration).map(([value, label]) => ({
    value,
    label: String(label),
  }));
}

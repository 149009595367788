import React, { useEffect, useState, useMemo } from "react";
import { Box3, Vector3, Group, PerspectiveCamera } from "three";
import { OrbitControls, Plane } from "@react-three/drei"; // import Plane from drei
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { useThree } from "@react-three/fiber";

const ObjViewer: React.FC<{ objPath: string }> = ({ objPath }) => {
  const { camera } = useThree();
  const perspectiveCamera = camera as PerspectiveCamera;
  const [model, setModel] = useState<Group | null>(null);

  const box = useMemo(() => new Box3(), []);

  useEffect(() => {
    let isMounted = true;
    const loader = new OBJLoader();

    loader.load(
      objPath,
      (object) => {
        if (isMounted) {
          box.setFromObject(object);
          const center: Vector3 = box.getCenter(new Vector3());
          const size = box.getSize(new Vector3());
          const maxDim = Math.max(size.x, size.y, size.z);
          const fov = perspectiveCamera.fov * (Math.PI / 180);
          let cameraZ = Math.abs((maxDim / 4) * Math.tan(fov * 2));
          cameraZ *= 1.1;

          object.position.x += object.position.x - center.x;
          object.position.y += object.position.y - center.y;
          object.position.z += object.position.z - center.z;

          perspectiveCamera.position.z = cameraZ;
          perspectiveCamera.lookAt(center);

          // Set the near and far properties of the camera
          perspectiveCamera.near = 0.1; // objects closer than 0.1 units won't be rendered
          perspectiveCamera.far = 10000; // objects further than 10000 units won't be rendered
          perspectiveCamera.updateProjectionMatrix(); // always call this after changing camera properties

          setModel(object);
        }
      },
      undefined,
      (error: ErrorEvent) => console.error("An error occurred:", error)
    );

    return () => {
      isMounted = false;
    };
  }, [objPath, box, camera]);

  return (
    <>
      <ambientLight />
      <pointLight position={[0.1, 0.1, 0.1]} />
      {model && <primitive object={model} />}
      <OrbitControls target={box.getCenter(new Vector3())} />
      <Plane
        args={[100, 100]}
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, -1, 0]}
      />
      {/* Add this line for floor */}
    </>
  );
};

export default ObjViewer;

import { queryFilterBuilder } from "./queryFilterBuilder";

interface ExportableService {
  export(filter: string): Promise<BlobPart>;
}

function downloadFile(data: BlobPart, filename: string): void {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export default async function exportData(
  service: ExportableService,
  storageKey: string,
  fileName: string,
  setIsLoading: (isLoading: boolean) => void
): Promise<void> {
  try {
    setIsLoading(true);
    const savedFilters = sessionStorage.getItem(storageKey);
    const filters = queryFilterBuilder(
      savedFilters ? JSON.parse(savedFilters) : undefined
    );

    const response = await service.export(filters);
    downloadFile(response, fileName);
  } catch (error) {
    console.error(`Download error in ${storageKey}`, error);
  } finally {
    setIsLoading(false);
  }
}

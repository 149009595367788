import React from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CardContent,
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import RewardsService from "../../services/rewardsService";
import { Reward } from "../../types/reward";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { PointsType, TransactionType } from "../../enums/reward";
import * as Yup from "yup";
import UserAutocomplete from "../../components/autocomplete/UserAutocomplete";
import { UserDTO } from "../../types/user";
import useToaster from "../../hooks/useToaster";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const validationSchema = Yup.object().shape({
  user: Yup.object().test(
    "has-properties",
    "User is required",
    (value) => value && Object.keys(value).length > 0
  ),
  transactionDate: Yup.date().required("Required"),
  transactionType: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
  points: Yup.string().required("Required"),
  balance: Yup.number().required("Required"),
});

type CreateRewardFormProps = {
  mode: "create" | "update";
  reward?: Reward;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

export type RewardForm = {
  user?: UserDTO;
  transactionDate: Date | null;
  company: string;
  transactionType: string;
  points: string | null;
  balance: number | null;
};

function CreateEditRewardForm({
  mode,
  reward,
  onSubmit,
  onCancel,
}: CreateRewardFormProps): JSX.Element {
  const { showToast } = useToaster();

  const initialValues: RewardForm = {
    user: reward?.user || undefined,
    transactionDate: reward ? new Date(reward.transactionDate) : null,
    transactionType: reward?.transactionType || "",
    company: reward?.user?.company?.name || "",
    points: reward?.points || null,
    balance: reward?.balance || null,
  };

  async function handleSubmit(values: RewardForm) {
    const preparedData: Reward = {
      user: values.user,
      transactionDate: values?.transactionDate || new Date(),
      transactionType: values.transactionType,
      points: values.points || "",
      balance: values.balance || 0,
    };

    await (mode === "create"
      ? createReward(preparedData)
      : updateWReward(preparedData, reward?.id!)
    ).then(onSubmit);
  }

  async function createReward(reward: Reward) {
    await RewardsService.create(reward)
      .then(() => showToast("Reward successfully created!", "success"))
      .catch((err) => showToast(err.message));
  }

  async function updateWReward(newReward: Reward, id: number) {
    await RewardsService.update({ ...newReward, id })
      .then(() => showToast("Reward successfully updated!", "success"))
      .catch((err) => showToast(err.message));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent style={{ width: "430px" }}>
          <form onSubmit={handleSubmit}>
            <UserAutocomplete
              id="user-select"
              label="User"
              selectedOption={values.user}
              onOptionSelect={(value) => {
                setFieldValue("user", value);
                setFieldValue("company", value?.company?.name);
              }}
              isTouched={!!touched.user}
              error={errors.user || ""}
            />

            <TextField
              name="company"
              label="Company"
              value={values.company}
              disabled={true}
              error={Boolean(touched.company && errors.company)}
              fullWidth
              helperText={touched.company && errors.company}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <DemoContainer components={["DatePicker"]}>
              <Box
                component="div"
                my={2}
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <DatePicker
                  label="Transaction Date"
                  value={values.transactionDate}
                  format="MM-dd-yyyy"
                  onChange={(date) => setFieldValue("transactionDate", date)}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      fullWidth: true,
                      error: Boolean(
                        touched.transactionDate && errors.transactionDate
                      ),
                      helperText:
                        touched.transactionDate && errors.transactionDate,
                    },
                  }}
                />
              </Box>
            </DemoContainer>

            <FormControl
              my={2}
              fullWidth
              error={touched.transactionType && Boolean(errors.transactionType)}
            >
              <InputLabel id="transactionType-select-label">
                Reward Transaction
              </InputLabel>
              <Select
                name="transactionType"
                labelId="transactionType-select-label"
                id="transactionType-select"
                value={values.transactionType}
                label="Reward Transaction"
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("transactionType", e.target.value)
                }
              >
                {Object.keys(TransactionType).map((transactionTypePrefKey) => (
                  <MenuItem
                    key={`transactionType-${transactionTypePrefKey}`}
                    value={transactionTypePrefKey}
                  >
                    {
                      TransactionType[
                        transactionTypePrefKey as keyof typeof TransactionType
                      ]
                    }
                  </MenuItem>
                ))}
              </Select>
              {touched.transactionType && errors.transactionType && (
                <FormHelperText>{errors.transactionType}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              my={2}
              fullWidth
              error={touched.points && Boolean(errors.points)}
            >
              <InputLabel id="points-select-label">Reward Points</InputLabel>
              <Select
                name="points"
                labelId="points-select-label"
                id="points-select"
                value={values.points}
                label="Reward Points"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("points", e.target.value)}
              >
                {Object.keys(PointsType).map((pointsTypePrefKey) => (
                  <MenuItem
                    key={`points-${pointsTypePrefKey}`}
                    value={pointsTypePrefKey}
                  >
                    {PointsType[pointsTypePrefKey as keyof typeof PointsType]}
                  </MenuItem>
                ))}
              </Select>
              {touched.points && errors.points && (
                <FormHelperText>{errors.points}</FormHelperText>
              )}
            </FormControl>

            <TextField
              name="balance"
              label="Reward Balance"
              value={values.balance}
              type="number"
              error={Boolean(touched.balance && errors.balance)}
              fullWidth
              helperText={touched.balance && errors.balance}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditRewardForm;

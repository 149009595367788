import React, { useEffect } from "react";

import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { CognitoUser } from "amazon-cognito-identity-js";
import TableWithoutPagination from "../../components/TableWithoutPagination";
import { format } from "date-fns";
import { Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { TransformedUser } from "../../types/auth";

type ListUserInGroupResponse = {
  Attributes: { Name: string; Value: string }[];
  Enabled: boolean;
  UserCreateDate: number;
  UserLastModifiedDate: number;
  UserStatus: string;
  Username: string;
};

type UsersTableProps = {
  users: CognitoUser[];
  loading: boolean;
  getUsers: () => void;
  removeUser: (user: TransformedUser) => void;
};

const UsersTable: React.FC<UsersTableProps> = ({
  users,
  loading,
  getUsers,
  removeUser,
}) => {
  useEffect(() => {
    getUsers();
  }, []);
  const handleRemoveClick = (user: TransformedUser) => {
    removeUser(user);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: 1,
    },
    {
      field: "emailVerified",
      headerName: "Email Verified",
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "userCreateDate",
      headerName: "User Create Date",
      flex: 1,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return format(new Date(value), "MM-dd-yyyy");
      },
    },
    {
      field: "userStatus",
      headerName: "User Status",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const user = params.row as TransformedUser;
        return (
          <Box component="div" mr={1}>
            <IconButton onClick={() => handleRemoveClick(user)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const transformUsers = (
    users: ListUserInGroupResponse[]
  ): TransformedUser[] => {
    return users.map((user) => {
      const attributes = user.Attributes.reduce((acc, attribute) => {
        acc[attribute.Name] = attribute.Value;
        return acc;
      }, {} as Record<string, string>);

      return {
        id: attributes["sub"],
        name: attributes["name"],
        email: attributes["email"],
        emailVerified: attributes["email_verified"] === "true",
        userCreateDate: user.UserCreateDate,
        userStatus: user.UserStatus,
      };
    });
  };

  // @ts-ignore
  const rows = transformUsers(users);

  return (
    <TableWithoutPagination rows={rows} columns={columns} loading={loading} />
  );
};

export default UsersTable;

import React from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import CreateEditHealthQuestionnaireForm from "./CreateEditHealthQuestionnaireForm";
import { HealthQuestionnaire } from "../../types/health-questionnaire";
import HealthQuestionnairesService from "../../services/HealthQuestionnairesService";
import useToaster from "../../hooks/useToaster";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
function CreateHealthQuestionnairePage() {
  const navigate = useNavigate();
  const { showToast } = useToaster();
  function createHealthQuestionnaireHandler(
    questionnaire: HealthQuestionnaire
  ) {
    HealthQuestionnairesService.createQuestionnaire(questionnaire)
      .then(() =>
        showToast("Health Questionnaire successfully created!", "success")
      )
      .then(() => navigate("/private/health-questionnaires"))
      .catch((err) => showToast(err.message));
  }

  function cancel() {
    navigate("/private/health-questionnaires");
  }

  return (
    <React.Fragment>
      <Helmet title="Create Health Questionnaire" />
      <Typography variant="h3" gutterBottom display="inline">
        Create Health Questionnaire
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/health-questionnaires">
          Health Questionnaires
        </Link>
        <Typography>Create</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <CreateEditHealthQuestionnaireForm
                mode="create"
                onSubmit={(questionnaire) =>
                  createHealthQuestionnaireHandler(questionnaire)
                }
                onCancel={cancel}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CreateHealthQuestionnairePage;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import { TaskTitles } from "./TaskTitles";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import TasksService from "../../services/tasksService";
import { GoalTypeEnum, Task } from "../../types/task";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Description = styled.div`
  text-align: initial;

  p,
  pre,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ol,
  ul {
    margin: 0;
  }
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function TaskDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [task, setTask] = useState<Task>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  useEffect(() => {
    setIsLoading(true);
    getExercise();
  }, []);

  function getExercise() {
    TasksService.getById(Number(id)).then((res: Task) => {
      setTask(res);
      setIsLoading(false);
      getKeyValuesItems(res);
    });
  }

  const titles: { [key: string]: string } = TaskTitles;

  function parseValueByEnum(
    key: string,
    value: string | number | Task
  ): JSX.Element | string {
    switch (key) {
      case "goalType":
        return GoalTypeEnum[value as keyof typeof GoalTypeEnum];
      case "description":
        return (
          <Description>
            {parse(DOMPurify.sanitize((value as string) || ""))}
          </Description>
        );
      default:
        return value as string;
    }
  }

  function getKeyValuesItems(task: Task): void {
    const items = Object.entries(task).map(
      ([k, val]) =>
        ({
          key: titles[k] as string,
          value: parseValueByEnum(k, val),
        } as KeyValue)
    );
    setKeyValuesItems(items);
  }

  return (
    <>
      <Helmet title="Task Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Task #{task?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/tasks">
          Tasks
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <KeyValueComponent keyValueItems={keyValuesItems} />
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default TaskDetails;

export const NutritionPlanTitles = {
  id: "Nutrition Plan ID",
  name: "Nutrition Plan Name",
  mealPreference: "Meal Preference",
  excludedFoods: "Excluded Foods",
  dailyCalories: "Daily Calories",
  weekNumber: "Week Number",
  weekDay: "Week Day",
  breakfast: "Breakfast",
  morningSnack: "Morning Snack",
  lunch: "Lunch",
  afternoonSnack: "Afternoon Snack",
  dinner: "Dinner",
};

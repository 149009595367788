import React from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CardContent,
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import {
  DailyCaloriesType,
  ExcludedFoodType,
  MealPreferenceType,
} from "../../enums/meal";
import { NutritionPlan } from "../../types/nutritionPlan";
import { convertCamelCaseToTitle } from "../../utils/convertCamelCaseToTitle";
import { NutritionProgram } from "../../types/nutritionProgram";
import NutritionPlanAutocomplete from "../../components/autocomplete/NutritionPlanAutocomplete";
import * as Yup from "yup";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type CreateEditNutritionProgramFormProps = {
  mode: "create" | "update";
  nutritionProgram?: NutritionProgram;
  onSubmit: (
    nutritionProgram: NutritionProgram,
    nutritionPlanId?: number
  ) => void;
  onCancel: () => void;
};

export type NutritionProgramForm = {
  name: string;
  mealPreference: string;
  excludedFoods: string;
  dailyCalories: string;
  weekNumber: number | string;
  sundayNutritionPlan?: NutritionPlan;
  mondayNutritionPlan?: NutritionPlan;
  tuesdayNutritionPlan?: NutritionPlan;
  wednesdayNutritionPlan?: NutritionPlan;
  thursdayNutritionPlan?: NutritionPlan;
  fridayNutritionPlan?: NutritionPlan;
  saturdayNutritionPlan?: NutritionPlan;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

function CreateEditNutritionProgramForm({
  mode,
  nutritionProgram,
  onSubmit,
  onCancel,
}: CreateEditNutritionProgramFormProps): JSX.Element {
  const dailyNutritionPlans = [
    "sundayNutritionPlan",
    "mondayNutritionPlan",
    "tuesdayNutritionPlan",
    "wednesdayNutritionPlan",
    "thursdayNutritionPlan",
    "fridayNutritionPlan",
    "saturdayNutritionPlan",
  ];

  const initialValues: NutritionProgramForm = {
    name: nutritionProgram?.name || "",
    mealPreference: nutritionProgram?.mealPreference || "",
    excludedFoods: nutritionProgram?.excludedFoods || "",
    dailyCalories: nutritionProgram?.dailyCalories || "",
    weekNumber: nutritionProgram?.weekNumber?.toString() || "",
    sundayNutritionPlan: nutritionProgram?.sundayNutritionPlan || undefined,
    mondayNutritionPlan: nutritionProgram?.mondayNutritionPlan || undefined,
    tuesdayNutritionPlan: nutritionProgram?.tuesdayNutritionPlan || undefined,
    wednesdayNutritionPlan:
      nutritionProgram?.wednesdayNutritionPlan || undefined,
    thursdayNutritionPlan: nutritionProgram?.thursdayNutritionPlan || undefined,
    fridayNutritionPlan: nutritionProgram?.fridayNutritionPlan || undefined,
    saturdayNutritionPlan: nutritionProgram?.saturdayNutritionPlan || undefined,
  };

  async function handleSubmit(values: NutritionProgramForm) {
    const preparedData: NutritionProgram = {
      name: values.name,
      mealPreference: values.mealPreference,
      excludedFoods: values.excludedFoods,
      dailyCalories: values.dailyCalories,
      weekNumber: parseInt(values.weekNumber.toString(), 10),
      sundayNutritionPlan: values.sundayNutritionPlan!,
      mondayNutritionPlan: values.mondayNutritionPlan!,
      tuesdayNutritionPlan: values.tuesdayNutritionPlan!,
      wednesdayNutritionPlan: values.wednesdayNutritionPlan!,
      thursdayNutritionPlan: values.thursdayNutritionPlan!,
      fridayNutritionPlan: values.fridayNutritionPlan!,
      saturdayNutritionPlan: values.saturdayNutritionPlan!,
    } as unknown as NutritionProgram;

    await (mode === "create"
      ? onSubmit(preparedData)
      : onSubmit(preparedData, nutritionProgram?.id!));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Program Name"
              value={values.name}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <FormControl my={2} fullWidth>
              <InputLabel id="mealPreference-select-label">
                Meal Preference
              </InputLabel>
              <Select
                name="mealPreference"
                labelId="mealPreference-select-label"
                id="mealPreference-select"
                value={values.mealPreference}
                label="Meal Preference"
                onChange={(e) =>
                  setFieldValue("mealPreference", e.target.value)
                }
              >
                {Object.keys(MealPreferenceType).map((mealPrefKey) => (
                  <MenuItem
                    key={`mealPreference-${mealPrefKey}`}
                    value={mealPrefKey}
                  >
                    {
                      MealPreferenceType[
                        mealPrefKey as keyof typeof MealPreferenceType
                      ]
                    }
                  </MenuItem>
                ))}
              </Select>
              {touched.mealPreference && errors.mealPreference && (
                <FormHelperText>{errors.mealPreference}</FormHelperText>
              )}
            </FormControl>

            <FormControl my={2} fullWidth>
              <InputLabel id="excludedFood-select-label">
                Excluded Foods
              </InputLabel>
              <Select
                name="excludedFood"
                labelId="excludedFood-select-label"
                id="excludedFood-select"
                value={values.excludedFoods}
                label="Excluded Foods"
                onChange={(e) => setFieldValue("excludedFoods", e.target.value)}
              >
                {Object.keys(ExcludedFoodType).map((excludedFoodKey) => (
                  <MenuItem
                    key={`excludedFood-${excludedFoodKey}`}
                    value={excludedFoodKey}
                  >
                    {
                      ExcludedFoodType[
                        excludedFoodKey as keyof typeof ExcludedFoodType
                      ]
                    }
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl my={2} fullWidth>
              <InputLabel id="dailyCalories-select-label">
                Daily Calories
              </InputLabel>
              <Select
                name="dailyCalories"
                labelId="dailyCalories-select-label"
                id="dailyCalories-select"
                value={values.dailyCalories}
                label="Daily Calories"
                onChange={(e) => setFieldValue("dailyCalories", e.target.value)}
              >
                {Object.keys(DailyCaloriesType).map((dailyCaloriesKey) => (
                  <MenuItem
                    key={`dailyCalories-${dailyCaloriesKey}`}
                    value={dailyCaloriesKey}
                  >
                    {
                      DailyCaloriesType[
                        dailyCaloriesKey as keyof typeof DailyCaloriesType
                      ]
                    }
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl my={2} fullWidth>
              <InputLabel id="weekNumber-select-label">Week Number</InputLabel>
              <Select
                name="weekNumber"
                labelId="weekNumber-select-label"
                id="weekNumber-select"
                value={values.weekNumber.toString()}
                label="Week Number"
                onChange={(e) => setFieldValue("weekNumber", e.target.value)}
              >
                {Array.from({ length: 52 }, (_, i) => i + 1).map(
                  (weekNumber) => (
                    <MenuItem
                      key={`weekNumber-${weekNumber}`}
                      value={weekNumber.toString()}
                    >
                      {weekNumber}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            {dailyNutritionPlans.map((plan) => (
              <NutritionPlanAutocomplete
                id={plan}
                key={plan}
                label={convertCamelCaseToTitle(plan)}
                selectedOption={
                  values[plan as keyof NutritionProgramForm] as NutritionPlan
                }
                onOptionSelect={(value) => setFieldValue(plan, value)}
              />
            ))}

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditNutritionProgramForm;

export enum DailyCaloriesType {
  CALORIES_1400 = "1400",
  CALORIES_1600 = "1600",
  CALORIES_1800 = "1800",
  CALORIES_2000 = "2000",
  CALORIES_2200 = "2200",
  CALORIES_2500 = "2500",
}

export enum ExcludedFoodType {
  RED_MEAT = "Red Meat",
  SEAFOOD = "Seafood",
  RED_MEAT_AND_SEAFOOD = "Red Meat and Seafood",
  NONE = "None",
}

export enum MealCategoryType {
  DEFAULT = "Default",
  DIABETES = "Diabetes",
  GLUTEN_FREE = "Gluten Free",
  HEART_HEALTHY = "Heart Healthy",
  VEGETARIAN = "Vegetarian",
}

export enum MealPreferenceType {
  VEGETARIAN = "Vegetarian",
  LOW_GLYCEMIC = "Low Glycemic",
  HEART_HEALTHY = "Heart Healthy",
  NO_PREFERENCE = "No preference",
}

export enum MealType {
  HOME_COOKED = "Home Cooked",
  FAST_FOOD = "Fast Food",
  PACKAGED_FOOD = "Packaged Food",
  SIMPLE_PREP = "Simple Prep",
  GRAB_ON_THE_GO = "Grab On The Go",
}

export enum MealTimeType {
  BREAKFAST = "Breakfast",
  LUNCH = "Lunch",
  DINNER = "Dinner",
  SNACK = "Snack",
}

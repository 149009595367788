import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
} from "react";

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import {
  ActionMap,
  AuthState,
  AuthUser,
  CognitoContextType,
} from "../types/auth";
import { awsConfig, cognitoConfig } from "../config";
import axiosInstance from "../utils/axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import AWS from "aws-sdk";
import { AdminAddUserToGroupRequest } from "aws-sdk/clients/cognitoidentityserviceprovider";
import { GROUPS } from "../constants";

const INITIALIZE = "INITIALIZE";
const SIGN_OUT = "SIGN_OUT";

const UserPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.userPoolId || "",
  ClientId: cognitoConfig.clientId || "",
});

AWS.config.update({
  region: "us-east-2",
  accessKeyId: awsConfig.awsAccessKey,
  secretAccessKey: awsConfig.awsSecretKey,
});

const cognito = new AWS.CognitoIdentityServiceProvider();

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_OUT]: undefined;
};

type CognitoActions =
  ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>];

interface CognitoGroup {
  GroupName: string;
}

const reducer = (state: AuthState, action: CognitoActions) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext<CognitoContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getUserAttributes = useCallback(
    (currentUser: CognitoUser): Record<string, any> =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err);
          } else {
            const results: Record<string, any> = {};

            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value;
            });
            resolve(results);
          }
        });
      }),
    []
  );

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser();
        if (user) {
          user.getSession(
            async (err: Error | null, session: CognitoUserSession | null) => {
              if (err) {
                reject(err);
              } else if (session) {
                const attributes = await getUserAttributes(user);
                const token = session.getIdToken().getJwtToken();

                if (session.isValid()) {
                  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
                } else {
                  // Code to refresh the token using the refresh token
                  user.refreshSession(
                    session.getRefreshToken(),
                    (refreshErr, refreshSession) => {
                      if (refreshErr) {
                        reject(refreshErr);
                      } else {
                        axiosInstance.defaults.headers.common.Authorization = `Bearer ${refreshSession
                          ?.getIdToken()
                          .getJwtToken()}`;
                      }
                    }
                  );
                }

                dispatch({
                  type: INITIALIZE,
                  payload: { isAuthenticated: true, user: attributes },
                });

                resolve({
                  user,
                  session,
                  headers: { Authorization: token },
                });
              }
            }
          );
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [getUserAttributes]
  );

  const initialize = useCallback(async () => {
    try {
      await getSession();
    } catch {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getSession]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const signIn = useCallback(
    async (email: string, password: string) =>
      new Promise(async (resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });

        user.authenticateUser(authDetails, {
          onSuccess: async (data) => {
            const cognitoUser = UserPool.getCurrentUser();
            if (cognitoUser) {
              cognitoUser.getSession(async (err: any, session: any) => {
                if (err) {
                  reject(err);
                } else {
                  const userPoolId = cognitoConfig.userPoolId;
                  if (!userPoolId) {
                    reject(new Error("UserPoolId is undefined"));
                    return;
                  }

                  const params = {
                    UserPoolId: userPoolId,
                    Username: email,
                  };

                  cognito.adminListGroupsForUser(
                    params,
                    async (error, response) => {
                      if (error) {
                        reject(error);
                      } else {
                        const groups: CognitoGroup[] =
                          response.Groups as CognitoGroup[];
                        const isValhallaMember = groups.some(
                          (group) => group.GroupName === GROUPS.ADMINS
                        );

                        if (isValhallaMember) {
                          await getSession();
                          resolve(data);
                        } else {
                          reject(
                            new Error("User is not a member of the admin group")
                          );
                        }
                      }
                    }
                  );
                }
              });
            }
          },
          onFailure: (err) => {
            reject(err);
          },
          newPasswordRequired: () => {
            resolve({ message: "New password required" });
          },
        });
      }),
    [getSession]
  );

  const signOut = () => {
    const user = UserPool.getCurrentUser();
    if (user) {
      user.signOut();
      dispatch({ type: SIGN_OUT });
    }
  };

  const signUp = (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) =>
    new Promise((resolve, reject) => {
      UserPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({ Name: "email", Value: email }),
          new CognitoUserAttribute({
            Name: "name",
            Value: `${firstName} ${lastName}`,
          }),
        ],
        [],
        (err, data) => {
          if (err) {
            reject(err);
            return;
          } else {
            const params: AdminAddUserToGroupRequest = {
              GroupName: GROUPS.ADMINS,
              Username: email,
              UserPoolId: cognitoConfig.userPoolId!,
            };

            cognito.adminAddUserToGroup(params, (error) => {
              if (error) {
                reject(error);
                return;
              }
              resolve(data);
            });
          }
        }
      );
    });

  const resetPassword = (email: string) =>
    new Promise((resolve, reject) => {
      const userData = {
        Username: email,
        Pool: UserPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });

  const confirmPassword = (
    email: string,
    verificationCode: string,
    newPassword: string
  ) =>
    new Promise((resolve, reject) => {
      const userData = {
        Username: email,
        Pool: UserPool,
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          resolve("Password confirmed and changed successfully");
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });

  const confirmEmail = (email: string, confirmationCode: string) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    return new Promise((resolve, reject) => {
      user.confirmRegistration(confirmationCode, true, function (err, result) {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  };

  const getUsersFromGroup = (groupName: string): Promise<CognitoUser[]> => {
    return new Promise((resolve, reject) => {
      const params: AWS.CognitoIdentityServiceProvider.ListUsersInGroupRequest =
        {
          GroupName: groupName,
          UserPoolId: cognitoConfig.userPoolId!,
        };

      cognito.listUsersInGroup(params, (error, data) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(data.Users as CognitoUser[]);
      });
    });
  };

  const getUsernameByEmail = (email: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const params: AWS.CognitoIdentityServiceProvider.ListUsersRequest = {
        UserPoolId: cognitoConfig.userPoolId!,
        Filter: `email = "${email}"`,
        Limit: 1,
      };

      cognito.listUsers(params, (error, data) => {
        if (error) {
          reject(error);
          return;
        }

        if (data.Users && data.Users.length > 0) {
          const user = data.Users[0];
          resolve(user.Username!);
        } else {
          reject(new Error("User not found"));
        }
      });
    });
  };

  const deleteUser = (email: string, groupName: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      getUsernameByEmail(email)
        .then((username) => {
          // Then delete the user from the user pool
          const params: AWS.CognitoIdentityServiceProvider.AdminDeleteUserRequest =
            {
              Username: username,
              UserPoolId: cognitoConfig.userPoolId!,
            };

          cognito.adminDeleteUser(params, (error) => {
            if (error) {
              reject(error);
              return;
            }
            resolve();
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "cognito",
        user: {
          displayName: state?.user?.name || "Undefined",
          role: "admin",
          ...state.user,
        },
        signIn,
        signUp,
        signOut,
        resetPassword,
        confirmPassword,
        confirmEmail,
        getUsersFromGroup,
        deleteUser,
      }}
    >
      {state.isInitialized ? (
        children
      ) : (
        <Box
          component="div"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            zIndex: 9999,
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

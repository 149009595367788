import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { Notification } from "../../types/notification";
import Table from "../../components/Table";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { GoalEnum } from "../../types/health-questionnaire";
import {
  currentDateOperator,
  dateRangeOperator,
} from "../../utils/filters/dateField";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import { GenderEnum } from "../../types/user";
import { generateOptionsFromEnum } from "../../utils/generateOptionsFromEnum";

type NotificationsTableProps = {
  notifications: Notification[];
  count: number;
  loading: boolean;
  getNotifications: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateNotification: (notification: Notification) => void;
  removeNotification: (notification: Notification) => void;
};

const NotificationTable: React.FC<NotificationsTableProps> = ({
  notifications,
  count,
  loading,
  getNotifications,
  setPageNumber,
  setElementsPerPage,
  updateNotification,
  removeNotification,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/notifications/${params.row.id}/details`);
    }
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    notification: Notification
  ) => {
    event.stopPropagation();
    handleMenuClose(notification.id!);
    updateNotification(notification);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    notification: Notification
  ) => {
    handleMenuClose(notification.id!);
    removeNotification(notification);
  };

  const goalOptions = generateOptionsFromEnum(GoalEnum);
  const genderOptions = generateOptionsFromEnum(GenderEnum);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Notification ID",
      width: 100,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "company",
      headerName: "Company Name",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name;
      },
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      filterOperators: [multiSelectOperator(genderOptions)],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "goal",
      headerName: "Goal",
      flex: 1,
      filterOperators: [multiSelectOperator(goalOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return GoalEnum[value as keyof typeof GoalEnum];
      },
    },
    {
      field: "title",
      headerName: "Notification Title",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "message",
      headerName: "Notification Message",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "notifyDate",
      headerName: "Notification Date",
      width: 160,
      filterOperators: [currentDateOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return format(new Date(value), "MM-dd-yyyy hh:mm a");
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const notification = params.row as Notification;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[notification.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) =>
                handleDetailButtonClick(event, notification.id!)
              }
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[notification.id!]}
              open={Boolean(anchorEl[notification.id!])}
              onClose={() => handleMenuClose(notification.id!)}
            >
              <MenuItem
                onClick={(event) => handleUpdateClick(event, notification)}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={(event) => handleRemoveClick(event, notification)}
              >
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="notifications"
      rows={notifications}
      columns={columns}
      count={count}
      loading={loading}
      getData={getNotifications}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
    />
  );
};

export default NotificationTable;

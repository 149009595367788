import React, { useLayoutEffect, useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import {
  AlcoholLevelEnum,
  BooleanEnum,
  CardioFitnessLevelEnum,
  ConfidenceLevelEnum,
  ExerciseFrequencyEnum,
  FinancialLevelEnum,
  GoalEnum,
  HealthQuestionnaire,
  MealFrequencyLevelEnum,
  MealPreferenceLevelEnum,
  NutritionLevelEnum,
  PantsSizeEnum,
  RelationshipLevelEnum,
  SleepLevelEnum,
  SocialSupportLevelEnum,
  StressLevelEnum,
  WaistSizeEnum,
  WeightTrainingLevelEnum,
  WorkoutDifficultyLevelEnum,
  WorkoutIntensityLevelEnum,
} from "../../types/health-questionnaire";
import { Equipment } from "../../types/equipment";
import { Injury } from "../../types/injury";
import Table from "../../components/Table";
import { useNavigate } from "react-router-dom";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import { dateRangeOperator } from "../../utils/filters/dateField";
import { format } from "date-fns";
import {
  generateOptionsFromEnum,
  OptionsFromEnum,
} from "../../utils/generateOptionsFromEnum";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import InjuriesService from "../../services/injuriesService";
import EquipmentsService from "../../services/equipmentsService";
import ExerciseLocationsService from "../../services/exerciseLocationsService";

type HealthQuestionnairesTableProps = {
  questionnaires: HealthQuestionnaire[];
  count: number;
  loading: boolean;
  getQuestionnaires: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateQuestionnaires: (questionnaire: HealthQuestionnaire) => void;
  removeQuestionnaires: (questionnaire: HealthQuestionnaire) => void;
};

const HealthQuestionnairesTable: React.FC<HealthQuestionnairesTableProps> = ({
  questionnaires,
  count,
  loading,
  getQuestionnaires,
  updateQuestionnaires,
  removeQuestionnaires,
  setPageNumber,
  setElementsPerPage,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const [equipmentOptions, setEquipmentOptions] = useState<OptionsFromEnum[]>(
    []
  );
  const [locationOptions, setLocationOptions] = useState<OptionsFromEnum[]>([]);
  const [injuriesOptions, setInjuriesOptions] = useState<OptionsFromEnum[]>([]);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    fetchDataForSelects();
  }, []);

  async function fetchDataForSelects() {
    const page: number = 0;
    const size: number = 100;
    const filter: string = "";
    const sortOrder: string = "";
    const sortField: string = "";

    const [equipmentsData, injuriesData, locationsData] = await Promise.all([
      EquipmentsService.getEquipments(page, size, filter, sortOrder, sortField),
      InjuriesService.getInjuries(page, size, filter, sortOrder, sortField),
      ExerciseLocationsService.getLocations(
        page,
        size,
        filter,
        sortOrder,
        sortField
      ),
    ]);
    setEquipmentOptions(
      equipmentsData.content.map((i) => ({ value: i.name, label: i.name }))
    );
    setInjuriesOptions(
      injuriesData.content
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        .map((i) => ({ value: i.name, label: i.name }))
    );
    setLocationOptions(
      locationsData.content.map((i) => ({ value: i.name, label: i.name }))
    );
  }

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    questionnaire: HealthQuestionnaire
  ) => {
    event.stopPropagation();
    handleMenuClose(questionnaire.id!);
    updateQuestionnaires(questionnaire);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    questionnaire: HealthQuestionnaire
  ) => {
    event.stopPropagation();
    handleMenuClose(questionnaire.id!);
    removeQuestionnaires(questionnaire);
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/health-questionnaires/${params.row.id}/details`);
    }
  };

  const goalOptions = generateOptionsFromEnum(GoalEnum);
  const weightTrainingLevelOptions = generateOptionsFromEnum(
    WeightTrainingLevelEnum
  );
  const cardioFitnessLevelOptions = generateOptionsFromEnum(
    CardioFitnessLevelEnum
  );
  const exerciseFrequencyOptions = generateOptionsFromEnum(
    ExerciseFrequencyEnum
  );
  const workoutIntensityLevelOptions = generateOptionsFromEnum(
    WorkoutIntensityLevelEnum
  );
  const workoutDifficultyLevelOptions = generateOptionsFromEnum(
    WorkoutDifficultyLevelEnum
  );
  const nutritionLevelOptions = generateOptionsFromEnum(NutritionLevelEnum);
  const mealFrequencyLevelOptions = generateOptionsFromEnum(
    MealFrequencyLevelEnum
  );
  const mealPreferenceLevelOptions = generateOptionsFromEnum(
    MealPreferenceLevelEnum
  );
  const socialSupportLevelOptions = generateOptionsFromEnum(
    SocialSupportLevelEnum
  );
  const financialLevelOptions = generateOptionsFromEnum(FinancialLevelEnum);
  const stressLevelOptions = generateOptionsFromEnum(StressLevelEnum);
  const sleepLevelOptions = generateOptionsFromEnum(SleepLevelEnum);
  const confidenceLevelOptions = generateOptionsFromEnum(ConfidenceLevelEnum);
  const relationshipLevelOptions = generateOptionsFromEnum(
    RelationshipLevelEnum
  );
  const alcoholLevellOptions = generateOptionsFromEnum(AlcoholLevelEnum);
  const booleanOptions = generateOptionsFromEnum(BooleanEnum);
  const yesNoOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const rows = questionnaires.map((questionnaire) => ({
    ...questionnaire,
    company: questionnaire?.user?.company,
    email: questionnaire?.user?.email,
  }));

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "HealthQuestionnaire ID",
      width: 200,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      filterOperators: [dateRangeOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return format(new Date(value), "MM-dd-yyyy");
      },
    },
    {
      field: "company",
      headerName: "Company Name",
      width: 150,
      filterable: false,
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name;
      },
    },
    {
      field: "user",
      headerName: "User",
      width: 150,
      filterOperators: [textContainsOperator],
      sortingOrder: ["desc", "asc"],
      valueGetter: (params) =>
        `${params.row?.user?.firstName} ${params.row?.user?.lastName}` || "",
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 150,
      filterable: false,
      sortable: false,
    },
    {
      field: "currentWeight",
      headerName: "Current Weight",
      width: 150,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "highBloodPressure",
      headerName: "High Blood Pressure",
      width: 150,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "highCholesterol",
      headerName: "High Cholesterol",
      width: 150,
      filterOperators: [multiSelectOperator(yesNoOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "diabetes",
      headerName: "Diabetes",
      width: 150,
      filterOperators: [multiSelectOperator(yesNoOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "acidReflux",
      headerName: "Acid Reflux",
      width: 150,
      filterOperators: [multiSelectOperator(yesNoOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "heartDisease",
      headerName: "Heart Disease",
      width: 150,
      filterOperators: [multiSelectOperator(yesNoOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "asthma",
      headerName: "Asthma",
      width: 150,
      filterOperators: [multiSelectOperator(yesNoOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "medication",
      headerName: "Medication",
      width: 150,
      filterOperators: [multiSelectOperator(yesNoOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "injuries",
      headerName: "Injuries",
      width: 200,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(injuriesOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.map((i: Injury) => i.name).join(", ");
      },
    },
    {
      field: "exerciseLocation",
      headerName: "Location",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(locationOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name;
      },
    },
    {
      field: "equipments",
      headerName: "Equipments",
      width: 200,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(equipmentOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.map((i: Equipment) => i.name).join(", ");
      },
    },
    {
      field: "fitnessGoal",
      headerName: "Fitness Goal",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(goalOptions)],
    },
    {
      field: "weightTrainingLevel",
      headerName: "Weight Training Level",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(weightTrainingLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return WeightTrainingLevelEnum[
          value as keyof typeof WeightTrainingLevelEnum
        ];
      },
    },
    {
      field: "cardioFitnessLevel",
      headerName: "Cardio Fitness Level",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(cardioFitnessLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return CardioFitnessLevelEnum[
          value as keyof typeof CardioFitnessLevelEnum
        ];
      },
    },
    {
      field: "exerciseFrequency",
      headerName: "Exercise Frequency",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(exerciseFrequencyOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return ExerciseFrequencyEnum[
          value as keyof typeof ExerciseFrequencyEnum
        ];
      },
    },
    {
      field: "exerciseIntensityLevel",
      headerName: "Exercise Intensity Level",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(workoutIntensityLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return WorkoutIntensityLevelEnum[
          value as keyof typeof WorkoutIntensityLevelEnum
        ];
      },
    },
    {
      field: "workoutDifficulty",
      headerName: "Workout Difficulty",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(workoutDifficultyLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return WorkoutDifficultyLevelEnum[
          value as keyof typeof WorkoutDifficultyLevelEnum
        ];
      },
    },
    {
      field: "currentDiet",
      headerName: "Current Diet",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(nutritionLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return NutritionLevelEnum[value as keyof typeof NutritionLevelEnum];
      },
    },
    {
      field: "pantsSize",
      headerName: "Pants Size",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterable: false,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return PantsSizeEnum[value as keyof typeof PantsSizeEnum];
      },
    },
    {
      field: "waistSize",
      headerName: "Waist Size",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterable: false,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return WaistSizeEnum[value as keyof typeof WaistSizeEnum];
      },
    },
    {
      field: "mealFrequency",
      headerName: "Meal Frequency",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(mealFrequencyLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return MealFrequencyLevelEnum[
          value as keyof typeof MealFrequencyLevelEnum
        ];
      },
    },
    {
      field: "mealPreference",
      headerName: "Meal Preference",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(mealPreferenceLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return MealPreferenceLevelEnum[
          value as keyof typeof MealPreferenceLevelEnum
        ];
      },
    },
    {
      field: "socialSupportLevel",
      headerName: "Social Support Level",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(socialSupportLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return SocialSupportLevelEnum[
          value as keyof typeof SocialSupportLevelEnum
        ];
      },
    },
    {
      field: "financialSituationLevel",
      headerName: "Financial Situation Level",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(financialLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return FinancialLevelEnum[value as keyof typeof FinancialLevelEnum];
      },
    },
    {
      field: "dailyStressLevel",
      headerName: "Daily Stress Level",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(stressLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return StressLevelEnum[value as keyof typeof StressLevelEnum];
      },
    },
    {
      field: "sleepLevel",
      headerName: "Sleep Level",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(sleepLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return SleepLevelEnum[value as keyof typeof SleepLevelEnum];
      },
    },
    {
      field: "confidenceLevel",
      headerName: "Confidence Level",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(confidenceLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return ConfidenceLevelEnum[value as keyof typeof ConfidenceLevelEnum];
      },
    },
    {
      field: "relationshipLevel",
      headerName: "Relationship Level",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(relationshipLevelOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return RelationshipLevelEnum[
          value as keyof typeof RelationshipLevelEnum
        ];
      },
    },
    {
      field: "alcohol",
      headerName: "Alcohol",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(alcoholLevellOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return AlcoholLevelEnum[value as keyof typeof AlcoholLevelEnum];
      },
    },
    {
      field: "smokeCigarettes",
      headerName: "Smoke Cigarettes",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(booleanOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "imageMeasurement",
      headerName: "Image Measurement",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(yesNoOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const questionary = params.row as HealthQuestionnaire;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[questionary.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) =>
                handleDetailButtonClick(event, questionary.id!)
              }
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[questionary.id!]}
              open={Boolean(anchorEl[questionary.id!])}
              onClose={() => handleMenuClose(questionary.id!)}
            >
              <MenuItem
                onClick={(event) => handleUpdateClick(event, questionary)}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={(event) => handleRemoveClick(event, questionary)}
              >
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="questionnaires"
      rows={rows}
      columns={columns}
      count={count}
      loading={loading}
      getData={getQuestionnaires}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
      initialState={{ pinnedColumns: { right: ["actions"] } }}
    />
  );
};

export default HealthQuestionnairesTable;

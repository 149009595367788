export const WorkoutTitles = {
  id: "Workout ID",
  name: "Workout Name",
  location: "Workout Location",
  type: "Workout Type",
  bodyParts: "Workout Bodyparts",
  equipments: "Workout Equipment",
  workoutScoreLevel: "Workout Score Level",
  description: "Work Description",
  substitute: "Workout Substitute",
  injuries: "Workout Injuries",
};

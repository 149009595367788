import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import Table from "../../components/Table";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import { generateOptionsFromEnum } from "../../utils/generateOptionsFromEnum";
import { GoalTypeEnum, Task } from "../../types/task";

type ExercisesTableProps = {
  tasks: Task[];
  count: number;
  loading: boolean;
  getTasks: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateTask: (task: Task) => void;
  removeTask: (task: Task) => void;
};

const TaskTable: React.FC<ExercisesTableProps> = ({
  tasks,
  count,
  loading,
  getTasks,
  setPageNumber,
  setElementsPerPage,
  updateTask,
  removeTask,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    task: Task
  ) => {
    event.stopPropagation();
    handleMenuClose(task.id!);
    updateTask(task);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    task: Task
  ) => {
    event.stopPropagation();
    handleMenuClose(task.id!);
    removeTask(task);
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/tasks/${params.row.id}/details`);
    }
  };

  const goalTypeOptions = generateOptionsFromEnum(GoalTypeEnum);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Task ID",
      width: 100,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "name",
      headerName: "Task Name",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "goalType",
      headerName: "Goal Type",
      flex: 1,
      filterOperators: [multiSelectOperator(goalTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return GoalTypeEnum[value as keyof typeof GoalTypeEnum];
      },
    },
    {
      field: "description",
      headerName: "Task Description",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.replace(/<\/?[^>]+(>|$)/g, "");
      },
    },
    {
      field: "link",
      headerName: "Task Link",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const task = params.row as Task;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[task.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) => handleDetailButtonClick(event, task.id!)}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[task.id!]}
              open={Boolean(anchorEl[task.id!])}
              onClose={() => handleMenuClose(task.id!)}
            >
              <MenuItem onClick={(event) => handleUpdateClick(event, task)}>
                Edit
              </MenuItem>
              <MenuItem onClick={(event) => handleRemoveClick(event, task)}>
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="tasks"
      rows={tasks}
      columns={columns}
      count={count}
      loading={loading}
      getData={getTasks}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
    />
  );
};

export default TaskTable;

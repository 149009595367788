import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { MeasurementDTO } from "../../types/measurement";
import { useNavigate } from "react-router-dom";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { dateRangeOperator } from "../../utils/filters/dateField";
import { format } from "date-fns";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import { Program } from "../../types/program";
import Table from "../../components/Table";
import { generateOptionsFromEnum } from "../../utils/generateOptionsFromEnum";
import { GenderEnum } from "../../types/user";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import { convertCamelCaseToTitle } from "../../utils/convertCamelCaseToTitle";
import { roundedNumber } from "../../utils/roundedNumber";

type MeasurementsTableProps = {
  measurements: MeasurementDTO[];
  count: number;
  loading: boolean;
  getMeasurements: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateMeasurement: (measurement: MeasurementDTO) => void;
  removeMeasurement: (measurement: MeasurementDTO) => void;
};

const MeasurementsTable: React.FC<MeasurementsTableProps> = ({
  measurements,
  count,
  loading,
  getMeasurements,
  updateMeasurement,
  removeMeasurement,
  setPageNumber,
  setElementsPerPage,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    measurement: MeasurementDTO
  ) => {
    event.stopPropagation();
    handleMenuClose(measurement.id!);
    updateMeasurement(measurement);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    measurement: MeasurementDTO
  ) => {
    event.stopPropagation();
    handleMenuClose(measurement.id!);
    removeMeasurement(measurement);
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/measurements/${params.row.id}/details`);
    }
  };

  const genderOptions = generateOptionsFromEnum(GenderEnum);

  const rows = measurements.map((measurement) => ({
    ...measurement,
    email: measurement?.user?.email,
    companyName: measurement?.user?.company?.name,
  }));

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Measurement ID",
      width: 100,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "date",
      headerName: "Measurement Date",
      width: 150,
      filterOperators: [dateRangeOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return format(new Date(value), "MM-dd-yyyy");
      },
    },
    {
      field: "status",
      headerName: "Measurement Status",
      width: 150,
      filterable: false,
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.toUpperCase();
      },
    },
    {
      field: "companyName",
      headerName: "Company Name",
      width: 150,
      filterable: false,
      sortable: false,
    },
    {
      field: "user",
      headerName: "User",
      width: 150,
      filterOperators: [textContainsOperator],
      sortingOrder: ["desc", "asc"],
      valueGetter: (params) =>
        `${params.row?.user?.firstName} ${params.row?.user?.lastName}` || "",
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 150,
      filterable: false,
      sortable: false,
    },
    {
      field: "gender",
      headerName: "Sex",
      width: 150,
      filterOperators: [multiSelectOperator(genderOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return convertCamelCaseToTitle(value);
      },
    },
    {
      field: "age",
      headerName: "Age",
      width: 150,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "height",
      headerName: "Height",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.height)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.weight)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "neck",
      headerName: "Neck",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.volumeParams?.neck)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "elbow",
      headerName: "Elbow",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.volumeParams?.elbowGirth)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "wrist",
      headerName: "Wrist",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.volumeParams?.wrist)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "chest",
      headerName: "Chest",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.volumeParams?.chest)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "waist",
      headerName: "Waist",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.volumeParams?.waist)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "hip",
      headerName: "Hip",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.volumeParams?.highHips)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "thigh",
      headerName: "Thigh",
      width: 200,
      valueGetter: (params) =>
        roundedNumber(params.row.volumeParams?.thigh)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "knee",
      headerName: "Knee",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.volumeParams?.knee)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "calf",
      headerName: "Calf",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.volumeParams?.calf)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "ankle",
      headerName: "Ankle",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.volumeParams?.ankle)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "bodyFatPercentage",
      headerName: "Body Fat Percentage",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.bodyFatPercentage, 4)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "fatWeight",
      headerName: "Fat Weight",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.fatWeight)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "leanBodyMass",
      headerName: "Lean Body Mass",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.leanBodyMass)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "bodyMassIndex",
      headerName: "Body Mass Index",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.bodyMassIndex)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "recommendedCalories",
      headerName: "Recommended Calories",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.recommendedCalories)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "recommendedProtein",
      headerName: "Recommended Protein",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.recommendedProtein)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "recommendedWater",
      headerName: "Recommended Water",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.recommendedWater)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "fitnessScore",
      headerName: "Fitness Score",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.fitnessScore)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "wellnessScore",
      headerName: "Wellness Score",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.wellnessScore)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "workoutScore",
      headerName: "Workout Score",
      width: 150,
      valueGetter: (params) =>
        roundedNumber(params.row.workoutScore)?.toString() || "",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "program",
      headerName: "Program ID",
      width: 150,
      filterOperators: [textContainsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return (value as Program)?.id || "";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const measurement = params.row as MeasurementDTO;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[measurement.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) =>
                handleDetailButtonClick(event, measurement.id!)
              }
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[measurement.id!]}
              open={Boolean(anchorEl[measurement.id!])}
              onClose={() => handleMenuClose(measurement.id!)}
            >
              <MenuItem
                onClick={(event) => handleUpdateClick(event, measurement)}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={(event) => handleRemoveClick(event, measurement)}
              >
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="measurements"
      rows={rows}
      columns={columns}
      count={count}
      loading={loading}
      getData={getMeasurements}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
      initialState={{ pinnedColumns: { right: ["actions"] } }}
    />
  );
};

export default MeasurementsTable;

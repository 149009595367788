import React, { useEffect, useLayoutEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Backdrop,
  Box,
  Button as MuiButton,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { spacing, SpacingProps } from "@mui/system";

import ExerciseLocationsService from "../../services/exerciseLocationsService";
import InjuriesService from "../../services/injuriesService";

import { Workout } from "../../types/workout";
import { Location } from "../../types/location";
import { Injury, InjuryEnum } from "../../types/injury";
import { Equipment } from "../../types/equipment";
import { BodyPart } from "../../types/bodyPart";
import { WorkoutExerciseInfo } from "../../types/workoutExerciseInfo";
import CreateEditWorkoutExerciseInfoForm from "./workoutExerciseInfo/CreateEditWorkoutExerciseInfoForm";
import WorkoutExerciseInfoTable from "./workoutExerciseInfo/WorkoutExerciseInfoTable";
import WorkoutAutocomplete from "../../components/autocomplete/WorkoutAutocomplete";
import BodyPartsAutocompleteCheckbox from "../../components/autocomplete/checkbox/BodyPartsAutocompleteCheckbox";
import EquipmentsAutocompleteCheckbox from "../../components/autocomplete/checkbox/EquipmentsAutocompleteCheckbox";
import WorkoutScoreLevelAutocomplete from "../../components/autocomplete/WorkoutScoreLevelAutocomplete";
import useToaster from "../../hooks/useToaster";
import WorkoutsService from "../../services/workoutsService";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const TitleWrapper = styled(Typography)`
  display: flex;
  align-items: center;
`;
const StyledBackdrop = styled(Backdrop)`
  color: #376fd0;
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}
const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);

const Button = styled(MuiButton)<ButtonProps>(spacing);
const ButtonInfo = styled(MuiButton)`
  align-items: center;
  width: 40px;
  min-width: 40px;
  margin-left: 5px;

  span {
    margin: 0;
  }
`;

const validationSchema = Yup.object().shape({
  workoutName: Yup.string().required("Required"),
  exerciseLocation: Yup.object().required("Required"),
  type: Yup.string().required("Required"),
  bodyParts: Yup.array().min(1, "Required"),
  equipmentIds: Yup.array().min(1, "Required"),
  workoutScoreLevel: Yup.string().required("Required"),
});

type CreateWorkoutFormProps = {
  mode: "create" | "update" | "duplicate";
  workoutId?: number;
  onSubmit: (workout: Workout, workoutId?: number) => void;
  onCancel: () => void;
};

export type WorkoutForm = {
  workoutName: string;
  exerciseLocation?: Location;
  type: string;
  bodyParts: BodyPart[];
  injuryIds: number[];
  equipments: Equipment[];
  workoutScoreLevel: string;
  description: string;
  substitute?: Workout;
};

function CreateEditWorkoutForm({
  mode,
  workoutId,
  onSubmit,
  onCancel,
}: CreateWorkoutFormProps): JSX.Element {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [injuries, setInjuries] = useState<Injury[]>([]);
  const [selectedWorkoutExerciseInfo, setSelectedWorkoutExerciseInfo] =
    useState<WorkoutExerciseInfo>();
  const [workoutExerciseInfos, setWorkoutExerciseInfos] = useState<
    WorkoutExerciseInfo[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [workout, setWorkout] = useState<Workout>();
  const { showToast } = useToaster();

  const initialValues: WorkoutForm = {
    workoutName: workout?.name || "",
    exerciseLocation: workout?.location,
    type: workout?.type || "",
    bodyParts: workout?.bodyParts || [],
    injuryIds: workout?.injuries.map((i) => i.id!) || [],
    equipments: workout?.equipments || [],
    workoutScoreLevel: workout?.workoutScoreLevel || "",
    description: workout?.description || "",
    substitute: workout?.substitute || undefined,
  };

  useLayoutEffect(() => {
    fetchDataForSelects();
  }, [workoutId]);

  useEffect(() => {
    if (mode === "update" && workout?.workoutExerciseInfos) {
      setWorkoutExerciseInfos(workout?.workoutExerciseInfos);
    } else if (mode === "duplicate" && workout?.workoutExerciseInfos) {
      let modifiedWorkoutExerciseInfos = workout?.workoutExerciseInfos?.map(
        ({ id, ...rest }) => rest
      );
      setWorkoutExerciseInfos(modifiedWorkoutExerciseInfos);
    }
  }, [workout]);

  async function fetchDataForSelects() {
    const page: number = 0;
    const size: number = 100;
    const filter: string = "";
    const sortOrder: string = "";
    const sortField: string = "";
    let workoutData;

    setIsLoading(true);

    if (workoutId) {
      try {
        workoutData = await WorkoutsService.getById(workoutId);
      } catch (error) {
        if (error instanceof Error) {
          showToast(error.message);
        } else {
          showToast("An unexpected error occurred with loading workout.");
        }
      }
    }

    const [locationsData, injuriesData] = await Promise.all([
      ExerciseLocationsService.getLocations(
        page,
        size,
        filter,
        sortOrder,
        sortField
      ),
      InjuriesService.getInjuries(page, size, filter, sortOrder, sortField),
    ]);

    setLocations(locationsData.content);
    setInjuries(injuriesData.content);
    if (workoutData) setWorkout(workoutData);

    setIsLoading(false);
  }

  async function handleSubmit(values: WorkoutForm) {
    const preparedData: Workout = {
      name: values.workoutName,
      location: values.exerciseLocation!,
      type: values.type,
      bodyParts: values.bodyParts,
      injuries: injuries.filter((i) => values.injuryIds?.includes(i.id!)),
      equipments: values.equipments,
      workoutScoreLevel: values.workoutScoreLevel,
      description: values.description,
      substitute: values.substitute,
      workoutExerciseInfos: workoutExerciseInfos,
    };

    const modified = {
      ...preparedData,
      workoutExerciseInfos: preparedData.workoutExerciseInfos.map((info) => {
        // @ts-ignore
        if (info?.isNewRecord) {
          delete info.id;
          // @ts-ignore
          delete info.isNewRecord;
        }
        return info;
      }),
    };

    await (mode === "create" || mode === "duplicate"
      ? onSubmit(modified)
      : onSubmit(modified, workout?.id!));
  }

  function generateTempID() {
    return Date.now() * 1000 + Math.floor(Math.random() * 1000);
  }

  function createSubmitHandle(info: WorkoutExerciseInfo) {
    const newInfo = {
      ...info,
      id: info.id ? info.id : generateTempID(),
      isNewRecord: true,
    };
    setWorkoutExerciseInfos((prevState) => [...prevState, newInfo]);
    setOpenCreateModal(false);
  }

  function editHandle(info: WorkoutExerciseInfo) {
    setSelectedWorkoutExerciseInfo(info);
    setOpenEditModal(true);
  }

  function removeHandle(info: WorkoutExerciseInfo) {
    setSelectedWorkoutExerciseInfo(info);
    setOpenRemoveModal(true);
  }

  function editSubmitHandle(info: WorkoutExerciseInfo) {
    let index: number;
    if (info.id === undefined) {
      index = workoutExerciseInfos.findIndex(
        (w) => w.exercise?.name === info.exercise?.name
      );
    }
    index = workoutExerciseInfos.findIndex((w) => w.id === info.id);
    if (index !== -1) {
      setWorkoutExerciseInfos((prevState) =>
        prevState.map((item, i) => (i === index ? info : item))
      );
      setOpenEditModal(false);
    }
  }

  function clickRemoveHandler() {
    setWorkoutExerciseInfos((prevState) =>
      prevState.filter(
        (info) =>
          info?.exercise?.name !== selectedWorkoutExerciseInfo?.exercise?.name
      )
    );
    setOpenRemoveModal(false);
  }

  function locationFieldChangeHandler(
    e: SelectChangeEvent<unknown>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) {
    const selectedLocation = locations.find(
      (l) => l.id === parseInt(e.target.value as string)
    );

    setFieldValue("exerciseLocation", selectedLocation);
  }

  function getNoneInjuresID(): number {
    return injuries.find((inj) => inj.name === InjuryEnum.None)!.id || 1;
  }

  return (
    <>
      {isLoading ? (
        <StyledBackdrop open={isLoading}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <CardContent>
              <form onSubmit={handleSubmit}>
                <TextField
                  name="workoutName"
                  label="Workout Name"
                  value={values.workoutName}
                  error={Boolean(touched.workoutName && errors.workoutName)}
                  fullWidth
                  helperText={touched.workoutName && errors.workoutName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />

                <FormControl
                  my={2}
                  fullWidth
                  error={
                    touched.exerciseLocation && Boolean(errors.exerciseLocation)
                  }
                >
                  <InputLabel id="location-select-label">
                    Workout Location
                  </InputLabel>
                  <Select
                    name="exerciseLocation"
                    labelId="location-select-label"
                    id="location-select"
                    value={values.exerciseLocation?.id ?? ""}
                    label="Workout Location"
                    onBlur={handleBlur}
                    onChange={(e) =>
                      locationFieldChangeHandler(e, setFieldValue)
                    }
                  >
                    {locations.length > 0 &&
                      locations.map((location) => (
                        <MenuItem
                          key={`location-${location.id}`}
                          value={location.id}
                        >
                          {location.name}
                        </MenuItem>
                      ))}
                  </Select>

                  {touched.exerciseLocation && errors.exerciseLocation && (
                    <FormHelperText>{errors.exerciseLocation}</FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  my={2}
                  fullWidth
                  error={touched.type && !!errors.type}
                >
                  <InputLabel id="type-select-label">Workout Type</InputLabel>
                  <Select
                    name="type"
                    labelId="type-select-label"
                    id="type-select"
                    value={values.type}
                    label="Workout Type"
                    onChange={(e) => setFieldValue("type", e.target.value)}
                  >
                    <MenuItem key={"type-weights"} value="Weights">
                      Weights
                    </MenuItem>
                    <MenuItem key={`type-cardio`} value="Cardio">
                      Cardio
                    </MenuItem>
                  </Select>
                  {touched.type && errors.type && (
                    <FormHelperText>{errors.type}</FormHelperText>
                  )}
                </FormControl>

                <BodyPartsAutocompleteCheckbox
                  label="Workout Bodyparts"
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />

                <FormControl my={2} fullWidth>
                  <InputLabel id="injury-select-label">
                    Workout Injuries
                  </InputLabel>
                  <Select
                    labelId="injury-select-label"
                    id="injury-select"
                    multiple
                    value={values.injuryIds}
                    onChange={(e) =>
                      (e.target.value as number[]).includes(getNoneInjuresID())
                        ? setFieldValue("injuryIds", [getNoneInjuresID()])
                        : setFieldValue("injuryIds", e.target.value as number[])
                    }
                    input={<OutlinedInput label="Workout Injuries" />}
                    renderValue={(selected) =>
                      injuries
                        .filter(
                          (injury) =>
                            injury.id !== undefined &&
                            (selected as number[]).includes(injury.id)
                        )
                        .map((injury) => injury.name)
                        .join(", ")
                    }
                  >
                    {injuries.length > 0 &&
                      injuries.map((injury) => (
                        <MenuItem
                          key={`injury-${injury.id}`}
                          value={injury.id}
                          disabled={
                            Boolean(injury.id !== getNoneInjuresID()) &&
                            Boolean(
                              values.injuryIds.includes(getNoneInjuresID())
                            )
                          }
                        >
                          <Checkbox
                            checked={
                              injury.id !== undefined &&
                              values.injuryIds.includes(injury.id)
                            }
                          />
                          <ListItemText primary={injury.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <EquipmentsAutocompleteCheckbox
                  label="Workout Equipment"
                  location={null}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />

                <WorkoutScoreLevelAutocomplete
                  id="workout-score-level-type-select"
                  label="Workout Score Level"
                  selectedOption={values.workoutScoreLevel}
                  onOptionSelect={(value) => {
                    setFieldValue("workoutScoreLevel", value || "");
                  }}
                  isTouched={!!touched.workoutScoreLevel}
                  error={errors.workoutScoreLevel || ""}
                />

                <TextField
                  name="description"
                  label="Workout Description"
                  value={values.description || ""}
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  multiline
                  rows={4}
                  my={2}
                />

                <WorkoutAutocomplete
                  id="substitute"
                  key="substitute"
                  label="Workout Substitute"
                  selectedOption={values.substitute}
                  onOptionSelect={(value) => setFieldValue("substitute", value)}
                />

                <TitleWrapper variant="h3" mt={8} align="left">
                  Exercise Info
                  <ButtonInfo
                    onClick={() => setOpenCreateModal(true)}
                    startIcon={<AddBoxIcon fontSize="inherit" />}
                  />
                </TitleWrapper>

                {workoutExerciseInfos && (
                  <WorkoutExerciseInfoTable
                    workoutExerciseInfos={workoutExerciseInfos}
                    updateWorkoutExerciseInfo={editHandle}
                    removeWorkoutExerciseInfo={removeHandle}
                    setWorkoutExerciseInfos={setWorkoutExerciseInfos}
                  />
                )}

                <Dialog
                  open={openCreateModal}
                  onClose={() => setOpenCreateModal(false)}
                  aria-labelledby="edit-workout-exercise-info-dialog-title"
                >
                  <DialogTitle id="edit-workout-exercise-info-dialog-title">
                    Create Workout Exercise Info
                  </DialogTitle>
                  <DialogContent>
                    <CreateEditWorkoutExerciseInfoForm
                      mode="create"
                      onSubmit={createSubmitHandle}
                      onCancel={() => setOpenCreateModal(false)}
                    />
                  </DialogContent>
                </Dialog>

                <Dialog
                  open={openEditModal}
                  onClose={() => setOpenEditModal(false)}
                  aria-labelledby="edit-workout-exercise-info-dialog-title"
                >
                  <DialogTitle id="edit-workout-exercise-info-dialog-title">
                    Update Workout Exercise Info
                  </DialogTitle>
                  <DialogContent>
                    <CreateEditWorkoutExerciseInfoForm
                      mode="update"
                      workoutExerciseInfo={selectedWorkoutExerciseInfo}
                      onSubmit={editSubmitHandle}
                      onCancel={() => setOpenEditModal(false)}
                    />
                  </DialogContent>
                </Dialog>

                <Dialog
                  open={openRemoveModal}
                  keepMounted
                  fullWidth={true}
                  maxWidth="xs"
                  onClose={() => setOpenRemoveModal(false)}
                  aria-labelledby="remove-workout-exercise-info-dialog-title"
                >
                  <DialogTitle id="remove-workout-exercise-info-dialog-title">
                    Remove Workout Exercise Info
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="remove-workout-exercise-info-dialog-title">
                      {`Do you want to remove workout exercise info with exercise '${selectedWorkoutExerciseInfo?.exercise?.name}'?`}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <ModalButton
                      color="primary"
                      mt={3}
                      mr={2}
                      onClick={() => setOpenRemoveModal(false)}
                    >
                      Cancel
                    </ModalButton>
                    <ModalButton
                      variant="contained"
                      color="error"
                      mt={3}
                      onClick={clickRemoveHandler}
                    >
                      Remove
                    </ModalButton>
                  </DialogActions>
                </Dialog>

                <Box
                  component="div"
                  display="flex"
                  justifyContent="flex-end"
                  mt={6}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    mt={3}
                    mr={5}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    {mode === "update" ? "Update" : "Save"}
                  </Button>
                </Box>
              </form>
            </CardContent>
          )}
        </Formik>
      )}
    </>
  );
}

export default CreateEditWorkoutForm;

import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import Table from "../../components/Table";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import { GoalPlan } from "../../types/goalPlan";

type ExercisesTableProps = {
  goalPlans: GoalPlan[];
  count: number;
  loading: boolean;
  getGoalPlans: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateGoalPlan: (goalPlan: GoalPlan) => void;
  removeGoalPlan: (goalPlan: GoalPlan) => void;
};

const GoalPlanTable: React.FC<ExercisesTableProps> = ({
  goalPlans,
  count,
  loading,
  getGoalPlans,
  setPageNumber,
  setElementsPerPage,
  updateGoalPlan,
  removeGoalPlan,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    goalPlan: GoalPlan
  ) => {
    event.stopPropagation();
    handleMenuClose(goalPlan.id!);
    updateGoalPlan(goalPlan);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    goalPlan: GoalPlan
  ) => {
    event.stopPropagation();
    handleMenuClose(goalPlan.id!);
    removeGoalPlan(goalPlan);
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/goal-plans/${params.row.id}/details`);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Goal Plan ID",
      width: 100,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "name",
      headerName: "Goal Plan Name",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "affirmation",
      headerName: "Affirmation",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "taskOne",
      headerName: "Task One",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "taskTwo",
      headerName: "Task Two",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const goalPlan = params.row as GoalPlan;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[goalPlan.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) => handleDetailButtonClick(event, goalPlan.id!)}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[goalPlan.id!]}
              open={Boolean(anchorEl[goalPlan.id!])}
              onClose={() => handleMenuClose(goalPlan.id!)}
            >
              <MenuItem onClick={(event) => handleUpdateClick(event, goalPlan)}>
                Edit
              </MenuItem>
              <MenuItem onClick={(event) => handleRemoveClick(event, goalPlan)}>
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="goalPlan"
      rows={goalPlans}
      columns={columns}
      count={count}
      loading={loading}
      getData={getGoalPlans}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
    />
  );
};

export default GoalPlanTable;

import { GoalPlan } from "./goalPlan";

export enum PhysicalGoalType {
  LOWER_BLOOD_PRESSURE = "Lower Blood Pressure",
  IMPROVE_SLEEP = "Improve Sleep",
  DECREASE_CHOLESTEROL = "Decrease Cholesterol",
  HELP_YOUR_ACID_REFLUX = "Help Your Acid Reflux",
  LOWER_YOUR_A1C_LEVELS = "Lower Your A1C Levels",
  INCREASE_YOUR_ENERGY_LEVELS = "Increase Your Energy Levels",
  RELIEVE_YOUR_LOWER_BACK_PAIN = "Relieve Your Lower Back Pain",
  INCREASE_YOUR_STRENGTH = "Increase Your Strength",
  IMPROVE_YOUR_CARDIOVASCULAR_ENDURANCE = "Improve Your Cardiovascular Endurance",
  IMPROVE_METABOLISM = "Improve Metabolism",
}

export enum SelfImprovementGoalType {
  BUILD_MORE_CONFIDENCE = "Build More Confidence",
  BECOME_MORE_PROACTIVE = "Become More Proactive",
  HELP_EMOTIONAL_EATING = "Help Emotional Eating",
  MANAGE_STRESS_BETTER = "Manage Stress Better",
  IMPROVE_RELATIONSHIPS = "Improve Relationships",
  INCREASE_EXERCISE_MOTIVATION = "Increase Exercise Motivation",
  BECOME_MORE_POSITIVE = "Become More Positive",
  DRINK_LESS_ALCOHOL = "Drink Less Alcohol",
  HELP_TO_STOP_SMOKING = "Help to Stop Smoking",
  DECREASE_ANXIETY = "Decrease Anxiety",
  IMPROVE_FINANCIAL_FUTURE = "Improve Financial Future",
  HELP_BUILD_MORE_RESILIENCE = "Help Build More Resilience",
}

export type GoalProgram = {
  id?: number;
  name: string;
  physicalGoal: string;
  selfImprovementGoal: string;
  sundayGoalPlan?: GoalPlan;
  mondayGoalPlan?: GoalPlan;
  tuesdayGoalPlan?: GoalPlan;
  wednesdayGoalPlan?: GoalPlan;
  thursdayGoalPlan?: GoalPlan;
  fridayGoalPlan?: GoalPlan;
  saturdayGoalPlan?: GoalPlan;
};

import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Paper as MuiPaper, TablePaginationProps } from "@mui/material";
import { spacing } from "@mui/system";
import {
  DataGridPro,
  GridColDef,
  GridCellParams,
  GridFilterModel,
  GridSortModel,
  GridPaginationModel,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
  GridPagination,
} from "@mui/x-data-grid-pro";
import { queryFilterBuilder } from "../utils/queryFilterBuilder";
import { CustomNoRowsOverlay } from "./CustomNoRowsOverlay";
import { SkeletonLoadingOverlay } from "./SkeletonLoadingOverlay";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import useWindowWidth from "../hooks/useWindowSize";
import MuiPagination from "@mui/material/Pagination";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import { useLocation, useNavigate } from "react-router-dom";

const Paper = styled(MuiPaper)(spacing);

type GeneralTableProps = {
  id: string;
  rows: any[];
  count: number;
  loading?: boolean;
  initialState?: GridInitialStatePro;
  getData: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  columns: GridColDef[];
  orderByField: string;
  onCellClick: (params: GridCellParams) => void;
};

function Pagination({
  page,
  onPageChange,
  className,
  rowsPerPage,
  rowCount,
}: Pick<TablePaginationProps, "page" | "onPageChange" | "className"> & {
  rowsPerPage: number;
  rowCount: number;
}) {
  return (
    <MuiPagination
      variant="outlined"
      shape="rounded"
      className={className}
      count={Math.ceil(rowCount / rowsPerPage) || 1}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}
const Table: React.FC<GeneralTableProps> = ({
  id,
  rows,
  columns,
  count,
  loading,
  initialState = {},
  getData,
  setPageNumber,
  setElementsPerPage,
  orderByField,
  onCellClick,
}) => {
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState<string>(orderByField);
  const savedFilters = sessionStorage.getItem(`${id}-filters`);
  const [filters, setFilters] = useState<GridFilterModel>(
    savedFilters ? JSON.parse(savedFilters) : undefined
  );
  const [height, setHeight] = useState<number>(520);
  const initialRender = useRef(true);

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const initialPage = searchParams.get("page")
    ? Number(searchParams.get("page"))
    : 0;
  const initialRowsPerPage = searchParams.get("pageSize")
    ? Number(searchParams.get("pageSize"))
    : 25;

  const [page, setPage] = useState<number>(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(initialRowsPerPage);

  const { height: windowHeight } = useWindowWidth();
  const HEIGHT_HEADER_AND_FOOTER = 305;

  useEffect(() => {
    const tableHeight = Math.min(
      calculateTableHeight(rowsPerPage),
      windowHeight - HEIGHT_HEADER_AND_FOOTER
    );
    setHeight(tableHeight);
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      updateURLParams(page, rowsPerPage);
      if (page === 0) {
        getData(
          0,
          rowsPerPage,
          queryFilterBuilder(filters),
          "",
          order,
          orderBy
        );
        setPage(0);
        setPageNumber(0);
      }
      setElementsPerPage(rowsPerPage);
    }
  }, [rowsPerPage]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      updateURLParams(page, rowsPerPage);
      getData(
        page,
        rowsPerPage,
        queryFilterBuilder(filters),
        "",
        order,
        orderBy
      );
      setPageNumber(page);
    }
  }, [page]);

  useEffect(() => {
    const allItemsHaveValue = filters?.items?.every((item) => {
      return item.value !== undefined;
    });

    if (allItemsHaveValue) {
      const filterQuery = queryFilterBuilder(filters);
      getData(0, rowsPerPage, filterQuery, "", order, orderBy);
      setPage(0);
    }
  }, [filters]);

  const calculateTableHeight = (rowsPerPage: number) => {
    return 52 * rowsPerPage + 111;
  };

  const updateURLParams = (newPage: number, newPageSize: number) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", String(newPage));
    searchParams.set("pageSize", String(newPageSize));
    navigate({
      ...location,
      search: `?${searchParams.toString()}`,
    });
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length > 0) {
      const isAsc = orderBy === model[0].field && order === "asc";
      const direction = isAsc ? "desc" : "asc";
      getData(
        0,
        rowsPerPage,
        queryFilterBuilder(filters),
        "",
        direction,
        model[0].field
      );
      setPage(0);
      setOrder(direction);
      setOrderBy(model[0].field);
    }
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    sessionStorage.setItem(`${id}-filters`, JSON.stringify(model));
    setFilters(model);
  };

  const setPaginationModel = (model: GridPaginationModel) => {
    setPage(model.page);
    setRowsPerPage(model.pageSize);
  };

  const filterColumns = ({
    field,
    columns,
    currentFilters,
  }: FilterColumnsArgs) => {
    const filteredFields = currentFilters?.map((item) => item.field);
    return columns
      .filter(
        (colDef) =>
          colDef.filterable &&
          (colDef.field === field || !filteredFields.includes(colDef.field))
      )
      .map((column) => column.field);
  };

  const getColumnForNewFilter = ({
    currentFilters,
    columns,
  }: GetColumnForNewFilterArgs) => {
    const filteredFields = currentFilters?.map(({ field }) => field);
    const columnForNewFilter = columns
      .filter(
        (colDef) => colDef.filterable && !filteredFields.includes(colDef.field)
      )
      .find((colDef) => colDef.filterOperators?.length);
    return columnForNewFilter?.field || null;
  };

  return (
    <>
      <Paper style={{ height: height, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          rowCount={count}
          onSortModelChange={handleSortModelChange}
          onCellClick={onCellClick}
          filterMode="server"
          filterModel={filters}
          onFilterModelChange={handleFilterModelChange}
          pageSizeOptions={[25, 50, 75, 100]}
          paginationModel={{ page: page, pageSize: rowsPerPage }}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          pagination={true}
          rowSelection={false}
          loading={loading}
          initialState={{
            ...initialState,
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          slotProps={{
            filterPanel: {
              logicOperators: [],
              filterFormProps: {
                filterColumns,
              },
              getColumnForNewFilter,
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            loadingOverlay: SkeletonLoadingOverlay,
            pagination: () => (
              <GridPagination
                ActionsComponent={(props: TablePaginationActionsProps) => (
                  <Pagination
                    {...props}
                    page={page}
                    rowCount={count}
                    rowsPerPage={rowsPerPage}
                  />
                )}
              />
            ),
          }}
          sx={{
            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
        />
      </Paper>
    </>
  );
};

export default Table;

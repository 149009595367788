export enum GoalTypeEnum {
  PHYSICAL = "Physical",
  SELF_IMPROVEMENT = "Self Improvement",
}

export type Task = {
  id?: number;
  name: string;
  goalType: string;
  description: string;
  link: string;
};

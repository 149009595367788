import React from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CardContent,
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";

import { Meal } from "../../types/meal";
import {
  DailyCaloriesType,
  ExcludedFoodType,
  MealPreferenceType,
} from "../../enums/meal";
import { NutritionPlan } from "../../types/nutritionPlan";
import { WeekDayType } from "../../enums/nutritionPlan";
import MealAutocomplete from "../../components/autocomplete/MealAutocomplete";
import { convertCamelCaseToTitle } from "../../utils/convertCamelCaseToTitle";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type CreateEditNutritionPlanFormProps = {
  mode: "create" | "update";
  nutritionPlan?: NutritionPlan;
  onSubmit: (nutritionPlan: NutritionPlan, nutritionPlanId?: number) => void;
  onCancel: () => void;
};

export type NutritionPlanForm = {
  name: string;
  mealPreference: string;
  excludedFoods: string;
  dailyCalories: string;
  weekNumber: number | string;
  weekDay: string;
  breakfast?: Meal;
  morningSnack?: Meal;
  lunch?: Meal;
  afternoonSnack?: Meal;
  dinner?: Meal;
};

function CreateEditNutritionPlanForm({
  mode,
  nutritionPlan,
  onSubmit,
  onCancel,
}: CreateEditNutritionPlanFormProps): JSX.Element {
  const mealTimes = [
    "breakfast",
    "morningSnack",
    "lunch",
    "afternoonSnack",
    "dinner",
  ];

  const initialValues: NutritionPlanForm = {
    name: nutritionPlan?.name || "",
    mealPreference: nutritionPlan?.mealPreference || "",
    excludedFoods: nutritionPlan?.excludedFoods || "",
    dailyCalories: nutritionPlan?.dailyCalories || "",
    weekNumber: nutritionPlan?.weekNumber?.toString() || "",
    weekDay: nutritionPlan?.weekDay || "",
    breakfast: nutritionPlan?.breakfast || undefined,
    morningSnack: nutritionPlan?.morningSnack || undefined,
    lunch: nutritionPlan?.lunch || undefined,
    afternoonSnack: nutritionPlan?.afternoonSnack || undefined,
    dinner: nutritionPlan?.dinner || undefined,
  };

  async function handleSubmit(values: NutritionPlanForm) {
    const preparedData: NutritionPlan = {
      name: values.name,
      mealPreference: values.mealPreference,
      excludedFoods: values.excludedFoods,
      dailyCalories: values.dailyCalories,
      weekNumber: parseInt(values.weekNumber.toString(), 10),
      weekDay: values.weekDay,
      breakfast: values.breakfast,
      morningSnack: values.morningSnack,
      lunch: values.lunch,
      afternoonSnack: values.afternoonSnack,
      dinner: values.dinner,
    };

    await (mode === "create"
      ? onSubmit(preparedData)
      : onSubmit(preparedData, nutritionPlan?.id!));
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Plan Name"
              value={values.name}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <FormControl my={2} fullWidth>
              <InputLabel id="mealPreference-select-label">
                Meal Preference
              </InputLabel>
              <Select
                name="mealPreference"
                labelId="mealPreference-select-label"
                id="mealPreference-select"
                value={values.mealPreference}
                label="Meal Preference"
                onChange={(e) =>
                  setFieldValue("mealPreference", e.target.value)
                }
              >
                {Object.keys(MealPreferenceType).map((mealPrefKey) => (
                  <MenuItem
                    key={`mealPreference-${mealPrefKey}`}
                    value={mealPrefKey}
                  >
                    {
                      MealPreferenceType[
                        mealPrefKey as keyof typeof MealPreferenceType
                      ]
                    }
                  </MenuItem>
                ))}
              </Select>
              {touched.mealPreference && errors.mealPreference && (
                <FormHelperText>{errors.mealPreference}</FormHelperText>
              )}
            </FormControl>

            <FormControl my={2} fullWidth>
              <InputLabel id="excludedFood-select-label">
                Excluded Foods
              </InputLabel>
              <Select
                name="excludedFood"
                labelId="excludedFood-select-label"
                id="excludedFood-select"
                value={values.excludedFoods}
                label="Excluded Foods"
                onChange={(e) => setFieldValue("excludedFoods", e.target.value)}
              >
                {Object.keys(ExcludedFoodType).map((excludedFoodKey) => (
                  <MenuItem
                    key={`excludedFood-${excludedFoodKey}`}
                    value={excludedFoodKey}
                  >
                    {
                      ExcludedFoodType[
                        excludedFoodKey as keyof typeof ExcludedFoodType
                      ]
                    }
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl my={2} fullWidth>
              <InputLabel id="dailyCalories-select-label">
                Daily Calories
              </InputLabel>
              <Select
                name="dailyCalories"
                labelId="dailyCalories-select-label"
                id="dailyCalories-select"
                value={values.dailyCalories}
                label="Daily Calories"
                onChange={(e) => setFieldValue("dailyCalories", e.target.value)}
              >
                {Object.keys(DailyCaloriesType).map((dailyCaloriesKey) => (
                  <MenuItem
                    key={`dailyCalories-${dailyCaloriesKey}`}
                    value={dailyCaloriesKey}
                  >
                    {
                      DailyCaloriesType[
                        dailyCaloriesKey as keyof typeof DailyCaloriesType
                      ]
                    }
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl my={2} fullWidth>
              <InputLabel id="weekNumber-select-label">Week Number</InputLabel>
              <Select
                name="weekNumber"
                labelId="weekNumber-select-label"
                id="weekNumber-select"
                value={values.weekNumber.toString()}
                label="Week Number"
                onChange={(e) => setFieldValue("weekNumber", e.target.value)}
              >
                {Array.from({ length: 52 }, (_, i) => i + 1).map(
                  (weekNumber) => (
                    <MenuItem
                      key={`weekNumber-${weekNumber}`}
                      value={weekNumber.toString()}
                    >
                      {weekNumber}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <FormControl my={2} fullWidth>
              <InputLabel id="weekDay-select-label">Week Day</InputLabel>
              <Select
                name="weekDay"
                labelId="weekDay-select-label"
                id="weekDay-select"
                value={values.weekDay}
                label="Week Day"
                onChange={(e) => setFieldValue("weekDay", e.target.value)}
              >
                {Object.keys(WeekDayType).map((weekDayTypeKey) => (
                  <MenuItem
                    key={`weekDay-${weekDayTypeKey}`}
                    value={weekDayTypeKey}
                  >
                    {WeekDayType[weekDayTypeKey as keyof typeof WeekDayType]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {mealTimes.map((mealTime) => (
              <MealAutocomplete
                id={mealTime}
                key={mealTime}
                label={convertCamelCaseToTitle(mealTime)}
                selectedOption={
                  values[mealTime as keyof NutritionPlanForm] as Meal
                }
                onOptionSelect={(value) => setFieldValue(mealTime, value)}
              />
            ))}

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditNutritionPlanForm;

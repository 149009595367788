import {
  Autocomplete,
  CircularProgress,
  TextField as MuiTextField,
} from "@mui/material";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Search } from "../../types/search";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { NutritionPlan } from "../../types/nutritionPlan";
import NutritionPlansService from "../../services/nutritionPlansService";

type NutritionPlanAutocompleteProps = {
  onOptionSelect: (nutritionPlan: NutritionPlan | null) => void;
  selectedOption: NutritionPlan | undefined;
  label: string;
  id: string;
};

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
export default function NutritionPlanAutocomplete({
  onOptionSelect,
  selectedOption,
  label,
  id,
}: NutritionPlanAutocompleteProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<NutritionPlan[]>([]);
  const [inputValue, setInputValue] = useState("");
  const loading = open && options.length === 0;

  const fetch = useCallback(
    debounce(async (value: string) => {
      const res: Search<NutritionPlan> =
        await NutritionPlansService.getNutritionPlans(
          0,
          10,
          value ? `name:${value}` : "",
          "",
          "",
          ""
        );
      setOptions(res.content);
    }, 300),
    []
  );

  useEffect(() => {
    fetch(inputValue);
  }, [inputValue, fetch]);

  const handleOpen = async () => {
    setOpen(true);
    if (options.length === 0) {
      const res: Search<NutritionPlan> =
        await NutritionPlansService.getNutritionPlans(0, 10, "", "", "", "");
      setOptions(res.content);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOptionSelect = (
    _: ChangeEvent<{}>,
    value: NutritionPlan | null
  ) => {
    onOptionSelect(value);
  };

  const handleInputChange = (_: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  return (
    <Autocomplete
      id={id}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={handleOptionSelect}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      options={options}
      loading={loading}
      fullWidth
      inputValue={inputValue}
      onInputChange={handleInputChange}
      value={selectedOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          my={2}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

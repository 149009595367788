import { Company } from "./company";

export type User = {
  id: string;
  displayName: string;
  email: string;
  password: string;
  avatar: File | any;
};

export type UserDTO = {
  id: number;
  lastName: string;
  firstName: string;
  username: string;
  email: string;
  phone: string;
  birthDate: Date;
  company: Company;
  gender: string;
  height: number;
  memberId: number;
  active: boolean;
  enabled: boolean;
  deleted: boolean;
  isDefaultPassword: boolean;
};

export enum GenderEnum {
  Male = "Male",
  Female = "Female",
}

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import { Equipment } from "../../types/equipment";
import { Exercise } from "../../types/exercise";
import { BodyPart } from "../../types/bodyPart";
import ExercisesService from "../../services/exercisesService";
import { ExerciseTitles } from "./ExerciseTitles";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Description = styled.div`
  text-align: initial;

  p,
  pre,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ol,
  ul {
    margin: 0;
  }
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function ExerciseDetails() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exercise, setExercise] = useState<Exercise>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  useEffect(() => {
    setIsLoading(true);
    getExercise();
  }, []);

  function getExercise() {
    ExercisesService.getById(Number(id)).then((res: Exercise) => {
      setExercise(res);
      setIsLoading(false);
      getKeyValuesItems(res);
    });
  }

  const titles: { [key: string]: string } = ExerciseTitles;

  function parseValueByEnum(
    key: string,
    value: string | BodyPart[] | number | Equipment[] | Exercise
  ): JSX.Element | string {
    switch (key) {
      case "equipments":
        return (value as Equipment[]).map((e) => e.name).join(", ");
      case "bodyParts":
        return (value as BodyPart[]).map((e) => e.name).join(", ");
      case "substitute":
        return (value as Exercise)?.name;
      case "description":
        return (
          <Description>
            {parse(DOMPurify.sanitize((value as string) || ""))}
          </Description>
        );
      case "videoUrl":
        return (
          <iframe
            width="400"
            height="225"
            src={value as string}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            loading="lazy"
          ></iframe>
        );
      default:
        return value as string;
    }
  }

  function getKeyValuesItems(exercise: Exercise): void {
    const items = Object.entries(exercise).map(
      ([k, val]) =>
        ({
          key: titles[k] as string,
          value: parseValueByEnum(k, val),
        } as KeyValue)
    );
    setKeyValuesItems(items);
  }

  return (
    <>
      <Helmet title="Exercise Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Exercise #{exercise?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/exercises">
          Exercises
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <KeyValueComponent keyValueItems={keyValuesItems} />
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default ExerciseDetails;

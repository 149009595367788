export const MealTitles = {
  id: "Meal ID",
  name: "Meal Name",
  mealPreference: "Meal Preference",
  excludedFoods: "Excluded Foods",
  dailyCalories: "Daily Calories",
  mealCategory: "Meal Category",
  mealType: "Meal Type",
  mealTime: "Meal Time",
  calories: "Calories",
  carbs: "Carbs",
  protein: "Protein",
  fat: "Fat",
  description: "Meal Description",
  substitute: "Meal Substitute",
};

export const GoalProgramTitles = {
  id: "Goal Program ID",
  name: "Goal Program Name",
  physicalGoal: "Physical Goal",
  selfImprovementGoal: "Self Improvement Goal",
  sundayGoalPlan: "Sunday Goal Plan",
  mondayGoalPlan: "Monday Goal Plan",
  tuesdayGoalPlan: "Tuesday Goal Plan",
  wednesdayGoalPlan: "Wednesday Goal Plan",
  thursdayGoalPlan: "Thursday Goal Plan",
  fridayGoalPlan: "Friday Goal Plan",
  saturdayGoalPlan: "Saturday Goal Plan",
};

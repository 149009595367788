import axios from "../utils/axios";
import { Search } from "../types/search";
import { Notification } from "../types/notification";

const NotificationService = {
  getNotificationBy: async (id: number) => {
    const response = await axios.get<Notification>(`/api/health-article/${id}`);
    return response.data;
  },
  getNotifications: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<Notification>>(
      "/api/notification",
      {
        params: { page, size, filter, search, sortOrder, sortField },
      }
    );
    return response.data;
  },
  createNotification: async (notification: Notification) => {
    const response = await axios.post<Notification>(
      "api/notification",
      notification
    );
    return response.data;
  },
  editNotification: async (notification: Notification) => {
    const response = await axios.put<Notification>(
      `/api/notification`,
      notification
    );
    return response.data;
  },
  removeNotification: async (id: number) => {
    const response = await axios.delete(`/api/notification/${id}`);
    return response.data;
  },
  getById: async (id: number) => {
    const response = await axios.get<Notification>(`/api/notification/${id}`);
    return response.data;
  },
  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/notification/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default NotificationService;

export const NutritionProgramTitles = {
  id: "Nutrition Program ID",
  name: "Nutrition Program Name",
  mealPreference: "Meal Preference",
  excludedFoods: "Excluded Foods",
  dailyCalories: "Daily Calories",
  weekNumber: "Week Number",
  sundayNutritionPlan: "Sunday Plan",
  mondayNutritionPlan: "Monday Plan",
  tuesdayNutritionPlan: "Tuesday Plan",
  wednesdayNutritionPlan: "Wednesday Plan",
  thursdayNutritionPlan: "Thursday Plan",
  fridayNutritionPlan: "Friday Plan",
  saturdayNutritionPlan: "Saturday Plan",
};

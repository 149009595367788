import { SidebarItemsType } from "../../types/sidebar";
import {
  AssignmentOutlined,
  AssignmentTurnedInOutlined,
  EmojiEventsOutlined,
  FeedOutlined,
  FitnessCenterOutlined,
  FormatQuoteOutlined,
  HelpOutlineOutlined,
  MenuBookOutlined,
  NotificationsOutlined,
  PeopleAltOutlined,
  RestaurantOutlined,
  RunCircleOutlined,
  SpaceDashboardOutlined,
  StoreOutlined,
  StraightenOutlined,
  TaskOutlined,
} from "@mui/icons-material";

const categorySection = [
  // {
  //   href: "/private/dashboard/default",
  //   icon: SpaceDashboardOutlined,
  //   title: "Dashboard",
  // },
  {
    href: "/private/companies",
    icon: StoreOutlined,
    title: "Companies",
  },
  {
    href: "/private/users",
    icon: PeopleAltOutlined,
    title: "Users",
  },
  {
    href: "/private/health-questionnaires",
    icon: AssignmentOutlined,
    title: "Health Questionnaires",
  },
  {
    href: "/private/measurements",
    icon: StraightenOutlined,
    title: "Measurements",
  },
  {
    href: "/private/programs",
    icon: AssignmentOutlined,
    title: "Programs",
  },
  {
    href: "/private/workouts",
    icon: FitnessCenterOutlined,
    title: "Workouts",
  },
  {
    href: "/private/exercises",
    icon: RunCircleOutlined,
    title: "Exercises",
  },
  {
    href: "/private/nutrition-programs",
    icon: AssignmentOutlined,
    title: "Nutrition Programs",
  },
  {
    href: "/private/nutrition-plans",
    icon: MenuBookOutlined,
    title: "Nutrition Plans",
  },
  {
    href: "/private/meals",
    icon: RestaurantOutlined,
    title: "Meals",
  },
  {
    href: "/private/health-articles",
    icon: FeedOutlined,
    title: "Health Articles",
  },
  {
    href: "/private/motivation-quotes",
    icon: FormatQuoteOutlined,
    title: "Motivation Quotes",
  },
  {
    href: "/private/notifications",
    icon: NotificationsOutlined,
    title: "Notifications",
  },
  {
    href: "/private/rewards",
    icon: EmojiEventsOutlined,
    title: "Rewards",
  },
  {
    href: "/private/workout-checkins",
    icon: AssignmentTurnedInOutlined,
    title: "Workout Checkins",
  },
  {
    href: "/private/support",
    icon: HelpOutlineOutlined,
    title: "Help & Support",
  },

  {
    href: "/private/goal-programs",
    icon: AssignmentOutlined,
    title: "Goal Programs",
  },
  {
    href: "/private/goal-plans",
    icon: MenuBookOutlined,
    title: "Goal Plans",
  },
  {
    href: "/private/tasks",
    icon: TaskOutlined,
    title: "Tasks",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "",
    pages: categorySection,
  },
];

export default navItems;

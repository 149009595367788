import axios from "../utils/axios";
import { Search } from "../types/search";
import { Location } from "../types/location";

const ExerciseLocationsService = {
  getLocations: async (
    page: number,
    size: number,
    filter: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<Location>>(
      "/api/exercise-location",
      {
        params: { page, size, filter, sortOrder, sortField },
      }
    );
    return response.data;
  },
};

export default ExerciseLocationsService;

import axios from "../utils/axios";
import { Search } from "../types/search";
import { NutritionProgram } from "../types/nutritionProgram";

const NutritionProgramsService = {
  getNutritionPrograms: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<NutritionProgram>>(
      "/api/nutrition-program",
      {
        params: { page, size, filter, search, sortOrder, sortField },
      }
    );
    return response.data;
  },

  create: async (nutritionProgram: NutritionProgram) => {
    const response = await axios.post<Search<NutritionProgram>>(
      "/api/nutrition-program",
      nutritionProgram
    );
    return response.data;
  },

  update: async (nutritionProgram: NutritionProgram) => {
    const response = await axios.put<NutritionProgram>(
      `/api/nutrition-program`,
      nutritionProgram
    );
    return response.data;
  },

  remove: async (id: number) => {
    const response = await axios.delete(`/api/nutrition-program/${id}`);
    return response.data;
  },

  getById: async (id: number) => {
    const response = await axios.get<NutritionProgram>(
      `/api/nutrition-program/${id}`
    );
    return response.data;
  },
  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(
      `/api/nutrition-program/export`,
      {
        params: filter ? { filter } : undefined,
        responseType: "blob",
      }
    );
    return response.data;
  },
};

export default NutritionProgramsService;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import HealthQuestionnairesService from "../../services/HealthQuestionnairesService";
import {
  AlcoholLevelEnum,
  CardioFitnessLevelEnum,
  ConfidenceLevelEnum,
  DifficultTimeReasonEnum,
  ExerciseFrequencyEnum,
  FinancialLevelEnum,
  HealthQuestionnaire,
  MealFrequencyLevelEnum,
  MealPreferenceLevelEnum,
  NutritionLevelEnum,
  PantsSizeEnum,
  RelationshipLevelEnum,
  SleepLevelEnum,
  SocialSupportLevelEnum,
  StressLevelEnum,
  WaistSizeEnum,
  WeightTrainingLevelEnum,
  WorkoutDifficultyLevelEnum,
  WorkoutIntensityLevelEnum,
} from "../../types/health-questionnaire";
import { HealthQuestionnaireQuestions } from "./healthQuestionnaireQuestions";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import { Injury } from "../../types/injury";
import { Equipment } from "../../types/equipment";
import { Location } from "../../types/location";
import { UserDTO } from "../../types/user";
import { format } from "date-fns";
import {
  PhysicalGoalType,
  SelfImprovementGoalType,
} from "../../types/goalProgram";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

type PageField = {
  header?: string;
  items: string[];
};

const pageStructureless: PageField[] = [
  { items: ["id", "date", "company", "user", "email"] },
  {
    header: "Health History",
    items: [
      "currentWeight",
      "highBloodPressure",
      "highCholesterol",
      "diabetes",
      "acidReflux",
      "heartDisease",
      "asthma",
      "medication",
      "injuries",
    ],
  },
  {
    header: "Exercise & Goals",
    items: [
      "exerciseLocation",
      "equipments",
      "fitnessGoal",
      "weightTrainingLevel",
      "cardioFitnessLevel",
      "exerciseFrequency",
      "exerciseIntensityLevel",
      "workoutDifficulty",
      "physicalGoal",
      "selfImprovementGoal",
    ],
  },
  {
    header: "Nutrition",
    items: ["currentDiet", "mealFrequency", "mealPreference"],
  },
  {
    header: "Lifestyle",
    items: [
      "socialSupportLevel",
      "financialSituationLevel",
      "dailyStressLevel",
      "sleepLevel",
      "confidenceLevel",
      "relationshipLevel",
      "alcohol",
      "smokeCigarettes",
      "difficultTime",
    ],
  },
  {
    header: "Measurement",
    items: ["imageMeasurement", "pantsSize", "waistSize"],
  },
];
function CreateHealthQuestionnairePage() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [questionnaire, setQuestionnaire] = useState<HealthQuestionnaire>();
  useEffect(() => {
    setIsLoading(true);
    getQuestionnaireHandler();
  }, []);

  function getQuestionnaireHandler() {
    HealthQuestionnairesService.getQuestionnaireBy(Number(id)).then(
      (res: HealthQuestionnaire) => {
        setQuestionnaire(res);
        setIsLoading(false);
      }
    );
  }

  const questions: { [key: string]: string } = HealthQuestionnaireQuestions;

  function parseValueByEnum(
    key: string,
    value:
      | string
      | Injury[]
      | Location
      | number
      | Equipment[]
      | UserDTO
      | boolean
      | null
  ): string {
    switch (key) {
      case "highBloodPressure":
      case "highCholesterol":
      case "diabetes":
      case "acidReflux":
      case "heartDisease":
      case "asthma":
      case "medication":
      case "imageMeasurement":
      case "smokeCigarettes":
        return (value as boolean) ? "Yes" : "No";
      case "user":
        return `${(value as UserDTO).firstName} ${(value as UserDTO).lastName}`;
      case "injuries":
      case "equipments":
        return (value as Injury[]).map((e) => e.name).join(", ");
      case "exerciseLocation":
        return (value as Location).name;
      case "weightTrainingLevel":
        return WeightTrainingLevelEnum[
          value as keyof typeof WeightTrainingLevelEnum
        ];
      case "cardioFitnessLevel":
        return CardioFitnessLevelEnum[
          value as keyof typeof CardioFitnessLevelEnum
        ];
      case "exerciseFrequency":
        return ExerciseFrequencyEnum[
          value as keyof typeof ExerciseFrequencyEnum
        ];
      case "exerciseIntensityLevel":
        return WorkoutIntensityLevelEnum[
          value as keyof typeof WorkoutIntensityLevelEnum
        ];
      case "workoutDifficulty":
        return WorkoutDifficultyLevelEnum[
          value as keyof typeof WorkoutDifficultyLevelEnum
        ];
      case "currentDiet":
        return NutritionLevelEnum[value as keyof typeof NutritionLevelEnum];
      case "mealFrequency":
        return MealFrequencyLevelEnum[
          value as keyof typeof MealFrequencyLevelEnum
        ];
      case "mealPreference":
        return MealPreferenceLevelEnum[
          value as keyof typeof MealPreferenceLevelEnum
        ];
      case "socialSupportLevel":
        return SocialSupportLevelEnum[
          value as keyof typeof SocialSupportLevelEnum
        ];
      case "financialSituationLevel":
        return FinancialLevelEnum[value as keyof typeof FinancialLevelEnum];
      case "dailyStressLevel":
        return StressLevelEnum[value as keyof typeof StressLevelEnum];
      case "sleepLevel":
        return SleepLevelEnum[value as keyof typeof SleepLevelEnum];
      case "confidenceLevel":
        return ConfidenceLevelEnum[value as keyof typeof ConfidenceLevelEnum];
      case "relationshipLevel":
        return RelationshipLevelEnum[
          value as keyof typeof RelationshipLevelEnum
        ];
      case "alcohol":
        return AlcoholLevelEnum[value as keyof typeof AlcoholLevelEnum];
      case "difficultTime":
        if (value === null) return "No";
        return DifficultTimeReasonEnum[
          value as keyof typeof DifficultTimeReasonEnum
        ];
      case "waistSize":
        return WaistSizeEnum[value as keyof typeof WaistSizeEnum];
      case "pantsSize":
        return PantsSizeEnum[value as keyof typeof PantsSizeEnum]!;
      case "physicalGoal":
        return PhysicalGoalType[value as keyof typeof PhysicalGoalType];
      case "selfImprovementGoal":
        return SelfImprovementGoalType[
          value as keyof typeof SelfImprovementGoalType
        ];
      case "date":
        return format(new Date(value as string), "MM-dd-yyyy");
      default:
        return value as string;
    }
  }

  function getKeyValuesItems(
    questionnaires: HealthQuestionnaire,
    categoryKey: string[]
  ): KeyValue[] {
    const data = {
      ...questionnaires,
      company: questionnaires.user?.company.name,
      email: questionnaires.user?.email,
    };

    return Object.entries(data)
      .filter(([key, val]) => categoryKey.includes(key))
      .map(
        ([k, val]) =>
          ({
            key: questions[k] as string,
            value: parseValueByEnum(k, val),
          } as KeyValue)
      );
  }

  return (
    <>
      <Helmet title="Create Health Questionnaire" />
      <Typography variant="h3" gutterBottom display="inline">
        Health Questionnaire #{questionnaire?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/health-questionnaires">
          Health Questionnaires
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {!isLoading && questionnaire && (
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Shadow>
              <Card px={6} pt={6}>
                {pageStructureless.map((category, index) => (
                  <React.Fragment key={index}>
                    <Typography variant="h3" mt={8} align="center">
                      {category.header}
                    </Typography>
                    <KeyValueComponent
                      keyValueItems={getKeyValuesItems(
                        questionnaire!,
                        category.items
                      )}
                    />
                  </React.Fragment>
                ))}
              </Card>
            </Shadow>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default CreateHealthQuestionnairePage;

import React, { useState } from "react";
import styled from "@emotion/styled";
import { Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import {
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
} from "@mui/x-data-grid-pro";
import { queryFilterBuilder } from "../utils/queryFilterBuilder";
import { CustomNoRowsOverlay } from "./CustomNoRowsOverlay";
import { SkeletonLoadingOverlay } from "./SkeletonLoadingOverlay";

const Paper = styled(MuiPaper)(spacing);

type GeneralTableProps = {
  rows: any[];
  loading?: boolean;
  columns: GridColDef[];
};

const TableWithoutPagination: React.FC<GeneralTableProps> = ({
  rows,
  columns,
  loading,
}) => {
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState<string>("");

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length > 0) {
      const isAsc = orderBy === model[0].field && order === "asc";
      const direction = isAsc ? "desc" : "asc";
      setOrder(direction);
      setOrderBy(model[0].field);
    }
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    const allItemsHaveValue = model.items.every((item) => {
      return item.value !== undefined;
    });

    if (allItemsHaveValue) {
      const filterQuery = queryFilterBuilder(model);
      console.log("Apply this filter locally:", filterQuery);
    }
  };

  const filterColumns = ({
    field,
    columns,
    currentFilters,
  }: FilterColumnsArgs) => {
    const filteredFields = currentFilters?.map((item) => item.field);
    return columns
      .filter(
        (colDef) =>
          colDef.filterable &&
          (colDef.field === field || !filteredFields.includes(colDef.field))
      )
      .map((column) => column.field);
  };

  const getColumnForNewFilter = ({
    currentFilters,
    columns,
  }: GetColumnForNewFilterArgs) => {
    const filteredFields = currentFilters?.map(({ field }) => field);
    const columnForNewFilter = columns
      .filter(
        (colDef) => colDef.filterable && !filteredFields.includes(colDef.field)
      )
      .find((colDef) => colDef.filterOperators?.length);
    return columnForNewFilter?.field || null;
  };

  return (
    <>
      <Paper style={{ height: "100%", width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          filterMode="client"
          rowSelection={false}
          loading={loading}
          hideFooter
          slotProps={{
            filterPanel: {
              logicOperators: [],
              filterFormProps: {
                filterColumns,
              },
              getColumnForNewFilter,
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            loadingOverlay: SkeletonLoadingOverlay,
          }}
          sx={{
            ".MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
        />
      </Paper>
    </>
  );
};

export default TableWithoutPagination;

export enum TransactionType {
  UPDATED_QUESTIONNAIRE = "Updated Questionnaire",
  SUCCESSFUL_MEASUREMENT = "Successful Measurement",
  WORKOUT_CHECKIN = "Workout Checkin",
  REDEEM = "Redeem",
  NONCOMPLIANCE = "Noncompliance",
  MANUAL = "Manual",
  ENGAGEMENT = "Engagement",
  RESULTS = "Results",
}

export const PointsType = {
  POINTS_30: 30,
  POINTS_500: 500,
  POINTS_1000: 1000,
  POINTS_5000: 5000,
  POINTS_10000: 10000,
  POINTS_15000: 15000,
  POINTS_20000: 20000,
  POINTS_25000: 25000,
  POINTS_30000: 30000,
  POINTS_35000: 35000,
  POINTS_40000: 40000,
  POINTS_45000: 45000,
  POINTS_50000: 50000,
} as const;

import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { Search } from "../../types/search";
import { useNavigate } from "react-router-dom";
import { NutritionProgram } from "../../types/nutritionProgram";
import NutritionProgramsTable from "./NutritionProgramsTable";
import NutritionProgramsService from "../../services/nutritionProgramsService";
import useToaster from "../../hooks/useToaster";
import useLoading from "../../hooks/useLoading";
import exportData from "../../utils/downloadFile";
import SmallButton from "../../components/SmallButton";

const Divider = styled(MuiDivider)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);

function NutritionPrograms() {
  const { showToast } = useToaster();
  const { setIsLoading } = useLoading();
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [selectedProgram, setSelectedProgram] = useState<NutritionProgram>();
  const [totalElements, setTotalElements] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [elementsPerPage, setElementsPerPage] = useState<number>(25);
  const [nutritionPrograms, setNutritionPrograms] = useState<
    NutritionProgram[]
  >([]);

  const navigate = useNavigate();

  function getNutritionProgramsHandler(
    page: number = 0,
    size: number = 10,
    filter: string = "",
    search: string = "",
    sortOrder: string = "",
    sortField: string = ""
  ) {
    setTableLoading(true);
    NutritionProgramsService.getNutritionPrograms(
      page,
      size,
      filter,
      search,
      sortOrder,
      sortField
    ).then((res: Search<NutritionProgram>) => {
      setNutritionPrograms(res.content);
      setTotalElements(res.totalElements);
      setTableLoading(false);
    });
  }

  function updateNutritionProgram(nutritionProgram: NutritionProgram) {
    setSelectedProgram(nutritionProgram);
    navigate("/private/nutrition-programs/edit", {
      state: { nutritionProgram },
    });
  }

  function removeNutritionProgram(nutritionProgram: NutritionProgram) {
    setSelectedProgram(nutritionProgram);
    setOpenRemoveModal(true);
  }

  async function clickRemoveHandler() {
    await NutritionProgramsService.remove(selectedProgram?.id!)
      .then(() =>
        showToast("Nutrition Program successfully removed!", "success")
      )
      .then(() => setOpenRemoveModal(false))
      .then(() =>
        getNutritionProgramsHandler(pageNumber, elementsPerPage, "", "", "", "")
      )
      .catch((err) => showToast(err.message));
  }

  return (
    <>
      <Helmet title="Nutrition Programs" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Nutrition Programs
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <SmallButton
              mr={2}
              onClick={() =>
                exportData(
                  NutritionProgramsService,
                  "nutritionPrograms-filters",
                  "nutrition-programs.xlsx",
                  setIsLoading
                )
              }
            >
              <FileDownloadOutlinedIcon />
              Export
            </SmallButton>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("create")}
            >
              <AddIcon />
              New Nutrition Program
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <NutritionProgramsTable
            nutritionPrograms={nutritionPrograms}
            count={totalElements || 0}
            loading={tableLoading}
            getNutritionPrograms={getNutritionProgramsHandler}
            setPageNumber={setPageNumber}
            setElementsPerPage={setElementsPerPage}
            updateNutritionProgram={(nutritionProgram: NutritionProgram) =>
              updateNutritionProgram(nutritionProgram)
            }
            removeNutritionProgram={(nutritionProgram: NutritionProgram) =>
              removeNutritionProgram(nutritionProgram)
            }
          ></NutritionProgramsTable>
        </Grid>
      </Grid>

      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-nutrition-program-dialog-title"
      >
        <DialogTitle id="remove-nutrition-program-dialog-title">
          Remove Nutrition Program
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-nutrition-program-dialog-title">
            {`Do you want to remove ${selectedProgram?.name} ${selectedProgram?.id}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NutritionPrograms;

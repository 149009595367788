import axios from "../utils/axios";
import { Search } from "../types/search";
import { Exercise } from "../types/exercise";

const ExercisesService = {
  getExercises: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<Exercise>>("/api/exercise", {
      params: { page, size, filter, search, sortOrder, sortField },
    });
    return response.data;
  },

  create: async (exercise: Exercise) => {
    const response = await axios.post<Search<Exercise>>(
      "/api/exercise",
      exercise
    );
    return response.data;
  },

  update: async (exercise: Exercise) => {
    const response = await axios.put<Exercise>(`/api/exercise`, exercise);
    return response.data;
  },

  remove: async (id: number) => {
    const response = await axios.delete(`/api/exercise/${id}`);
    return response.data;
  },

  getById: async (id: number) => {
    const response = await axios.get<Exercise>(`/api/exercise/${id}`);
    return response.data;
  },

  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/exercise/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default ExercisesService;

import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { Search } from "../../types/search";
import MotivationalQuotesService from "../../services/motivationalQuotesService";
import MotivationalQuotesTable from "./MotivationalQuotesTable";
import { MotivationalQuote } from "../../types/motivationalQuote";
import CreateEditMotivationalQuoteForm from "./CreateEditMotivationalQuoteForm";
import useToaster from "../../hooks/useToaster";
import useLoading from "../../hooks/useLoading";
import exportData from "../../utils/downloadFile";
import SmallButton from "../../components/SmallButton";

const Divider = styled(MuiDivider)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);
const Button = styled(MuiButton)<ButtonProps>(spacing);

function MotivationalQuotes() {
  const { showToast } = useToaster();
  const { setIsLoading } = useLoading();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [selectedMotivationalQuote, setSelectedMotivationalQuote] =
    useState<MotivationalQuote>();
  const [totalElements, setTotalElements] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [elementsPerPage, setElementsPerPage] = useState<number>(25);
  const [MotivationalQuotes, setMotivationalQuotes] = useState<
    MotivationalQuote[]
  >([]);

  function getMotivationalQuotesHandler(
    page: number = 0,
    size: number = 10,
    filter: string = "",
    search: string = "",
    sortOrder: string = "",
    sortField: string = ""
  ) {
    setTableLoading(true);
    MotivationalQuotesService.getMotivationalQuotes(
      page,
      size,
      filter,
      search,
      sortOrder,
      sortField
    ).then((res: Search<MotivationalQuote>) => {
      setMotivationalQuotes(res.content);
      setTotalElements(res.totalElements);
      setTableLoading(false);
    });
  }

  async function submitHandler() {
    getMotivationalQuotesHandler(
      pageNumber,
      elementsPerPage,
      "",
      "",
      "asc",
      "id"
    );
    setOpenCreateModal(false);
    setOpenUpdateModal(false);
  }

  function updateExercise(motivationalQuote: MotivationalQuote) {
    setSelectedMotivationalQuote(motivationalQuote);
    setOpenUpdateModal(true);
  }

  function removeExercise(motivationalQuote: MotivationalQuote) {
    setSelectedMotivationalQuote(motivationalQuote);
    setOpenRemoveModal(true);
  }

  async function clickRemoveHandler() {
    await MotivationalQuotesService.remove(selectedMotivationalQuote?.id!)
      .then(() => setOpenRemoveModal(false))
      .then(() =>
        showToast(
          `Motivational Quote #${selectedMotivationalQuote?.id} removed successfully.`,
          "success"
        )
      )
      .then(() =>
        getMotivationalQuotesHandler(
          pageNumber,
          elementsPerPage,
          "",
          "",
          "asc",
          "id"
        )
      )
      .catch((err) => showToast(err.message));
  }

  return (
    <>
      <Helmet title="MotivationalQuotes" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Motivational Quotes
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <SmallButton
              mr={2}
              onClick={() =>
                exportData(
                  MotivationalQuotesService,
                  "motivationalQuotes-filters",
                  "motivational-quotes.xlsx",
                  setIsLoading
                )
              }
            >
              <FileDownloadOutlinedIcon />
              Export
            </SmallButton>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCreateModal(true)}
            >
              <AddIcon />
              New Motivational Quote
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MotivationalQuotesTable
            motivationalQuotes={MotivationalQuotes}
            count={totalElements || 0}
            loading={tableLoading}
            getMotivationalQuotes={getMotivationalQuotesHandler}
            setPageNumber={setPageNumber}
            setElementsPerPage={setElementsPerPage}
            updateMotivationalQuote={(motivationalQuote: MotivationalQuote) =>
              updateExercise(motivationalQuote)
            }
            removeMotivationalQuote={(motivationalQuote: MotivationalQuote) =>
              removeExercise(motivationalQuote)
            }
          />
        </Grid>
      </Grid>

      <Dialog
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Motivational Quote</DialogTitle>
        <DialogContent>
          <CreateEditMotivationalQuoteForm
            mode="create"
            onSubmit={submitHandler}
            onCancel={() => setOpenCreateModal(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        aria-labelledby="update-company-dialog-title"
      >
        <DialogTitle id="update-company-dialog-title">
          Update Motivational Quote
        </DialogTitle>
        <DialogContent>
          <CreateEditMotivationalQuoteForm
            mode="update"
            motivationalQuote={selectedMotivationalQuote}
            onSubmit={submitHandler}
            onCancel={() => setOpenUpdateModal(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-exercise-dialog-title"
      >
        <DialogTitle id="remove-exercise-dialog-title">
          Remove Exercise
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-exercise-dialog-title">
            {`Do you want to remove the Motivational Quote with ID #${selectedMotivationalQuote?.id} ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MotivationalQuotes;

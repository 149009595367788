import axios from "../utils/axios";
import { Search } from "../types/search";
import { MotivationalQuote } from "../types/motivationalQuote";

const MotivationalQuotesService = {
  getMotivationalQuotes: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<MotivationalQuote>>(
      "/api/motivational-quote",
      {
        params: { page, size, filter, search, sortOrder, sortField },
      }
    );
    return response.data;
  },

  create: async (motivationalQuote: MotivationalQuote) => {
    const response = await axios.post<Search<MotivationalQuote>>(
      "/api/motivational-quote",
      motivationalQuote
    );
    return response.data;
  },

  update: async (motivationalQuote: MotivationalQuote) => {
    const response = await axios.put<MotivationalQuote>(
      `/api/motivational-quote`,
      motivationalQuote
    );
    return response.data;
  },

  remove: async (id: number) => {
    const response = await axios.delete(`/api/motivational-quote/${id}`);
    return response.data;
  },

  getById: async (id: number) => {
    const response = await axios.get<MotivationalQuote>(
      `/api/motivational-quote/${id}`
    );
    return response.data;
  },

  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(
      `/api/motivational-quote/export`,
      {
        params: filter ? { filter } : undefined,
        responseType: "blob",
      }
    );
    return response.data;
  },
};

export default MotivationalQuotesService;

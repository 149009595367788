import {
  GridCellParams,
  GridColDef,
  GridFilterInputValue,
  GridFilterItem,
} from "@mui/x-data-grid-pro";

export const textContainsOperator = {
  value: "containsText",
  label: "Contains",
  InputComponent: GridFilterInputValue,
  getApplyFilterFn: (
    filterItem: GridFilterItem,
    column: GridColDef
  ): ((params: GridCellParams) => boolean) | null => {
    if (!filterItem.value) {
      return null;
    }

    return (params): boolean => {
      const value = params.value;
      const filterValue = String(filterItem.value).toLowerCase();
      return String(value).toLowerCase().includes(filterValue);
    };
  },
};

export const textEqualsOperator = {
  value: "equalsText",
  label: "Equals",
  InputComponent: GridFilterInputValue,
  getApplyFilterFn: (
    filterItem: GridFilterItem,
    column: GridColDef
  ): ((params: GridCellParams) => boolean) | null => {
    if (!filterItem.value) {
      return null;
    }

    return (params): boolean => {
      const value = params.value;
      const filterValue = String(filterItem.value).toLowerCase();
      return String(value).toLowerCase() === filterValue;
    };
  },
};

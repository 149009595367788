import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import { CompanyTitles } from "./CompanyTitles";
import { Company } from "../../types/company";
import useLoading from "../../hooks/useLoading";
import CompaniesService from "../../services/companiesService";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function CompanyDetails() {
  const { id } = useParams();
  const { setIsLoading } = useLoading();
  const [company, setCompany] = useState<Company>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  useEffect(() => {
    setIsLoading(true);
    getCompany();
  }, []);

  function getCompany() {
    CompaniesService.getById(Number(id)).then((res: Company) => {
      setCompany(res);
      setIsLoading(false);
      getKeyValuesItems(res);
    });
  }

  const titles: { [key: string]: string } = CompanyTitles;

  function parseValueByEnum(
    key: string,
    value: string | number | boolean
  ): string {
    switch (key) {
      case "active":
      case "disableUserMeasurementImage":
        return (value as boolean) ? "Yes" : "No";
      default:
        return value as string;
    }
  }

  function getKeyValuesItems(company: Company): void {
    const items = Object.entries(company).map(
      ([k, val]) =>
        ({
          key: titles[k] as string,
          value: parseValueByEnum(k, val),
        } as KeyValue)
    );
    setKeyValuesItems(items);
  }

  return (
    <>
      <Helmet title="Company Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Company #{company?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/companies">
          Companies
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            {keyValuesItems && (
              <Card px={6} pt={6}>
                <KeyValueComponent keyValueItems={keyValuesItems} />
              </Card>
            )}
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default CompanyDetails;

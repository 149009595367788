import React from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CardContent,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";

import { BodyPart } from "../../types/bodyPart";
import { Exercise } from "../../types/exercise";
import ExercisesService from "../../services/exercisesService";
import ExerciseAutocomplete from "../../components/autocomplete/ExerciseAutocomplete";
import useToaster from "../../hooks/useToaster";
import BodyPartsAutocompleteCheckbox from "../../components/autocomplete/checkbox/BodyPartsAutocompleteCheckbox";
import EquipmentsAutocompleteCheckbox from "../../components/autocomplete/checkbox/EquipmentsAutocompleteCheckbox";
import { Location } from "../../types/location";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const DescriptionContainer = styled.div`
  height: 300px;
`;
const StyledReactQuill = styled(ReactQuill)`
  height: 258px;
  .quill {
    height: 100%;
  }
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type CreateExerciseFormProps = {
  mode: "create" | "update";
  exercise?: Exercise;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

export type ExerciseForm = {
  name: string;
  bodyParts: BodyPart[];
  equipments: Location[];
  videoUrl: string;
  description: string;
  substitute?: Exercise;
};

function CreateEditExerciseForm({
  mode,
  exercise,
  onSubmit,
  onCancel,
}: CreateExerciseFormProps): JSX.Element {
  const { showToast } = useToaster();

  const initialValues: ExerciseForm = {
    name: exercise?.name || "",
    bodyParts: exercise?.bodyParts || [],
    equipments: exercise?.equipments || [],
    videoUrl: exercise?.videoUrl || "",
    description: exercise?.description || "",
    substitute: exercise?.substitute || undefined,
  };

  async function handleSubmit(values: ExerciseForm) {
    const preparedData: Exercise = {
      name: values.name,
      bodyParts: values.bodyParts,
      equipments: values.equipments,
      videoUrl: values.videoUrl,
      description: values.description,
      substitute: values.substitute,
    };

    await (mode === "create"
      ? createExercise(preparedData)
      : updateExercise(preparedData, exercise?.id!)
    ).then(onSubmit);
  }

  async function createExercise(exercise: Exercise) {
    await ExercisesService.create(exercise)
      .then(() => showToast("Exercise successfully created!", "success"))
      .catch((err) => showToast(err.message));
  }

  async function updateExercise(newExercise: Exercise, id: number) {
    await ExercisesService.update({ ...newExercise, id })
      .then(() => showToast("Exercise successfully updated!", "success"))
      .catch((err) => showToast(err.message));
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Exercise Name"
              value={values.name}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <BodyPartsAutocompleteCheckbox
              label="Exercise Bodyparts"
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />

            <EquipmentsAutocompleteCheckbox
              label="Exercise Equipment"
              location={null}
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />

            <TextField
              name="videoUrl"
              label="Exercise Video Url"
              value={values.videoUrl}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <DescriptionContainer data-name="description">
              <StyledReactQuill
                value={values.description || ""}
                modules={{
                  clipboard: {
                    matchVisual: false,
                  },
                }}
                onChange={(content) => {
                  setFieldValue("description", content);
                }}
              />
            </DescriptionContainer>

            <ExerciseAutocomplete
              id="substitute"
              key="substitute"
              label="Exercise Substitute"
              selectedOption={values.substitute}
              onOptionSelect={(value) => setFieldValue("substitute", value)}
            />

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditExerciseForm;

import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { Search } from "../../types/search";
import { useNavigate } from "react-router-dom";
import { Program } from "../../types/program";
import ProgramsService from "../../services/programsService";
import ProgramsTable from "./ProgramsTable";
import useToaster from "../../hooks/useToaster";
import useLoading from "../../hooks/useLoading";
import exportData from "../../utils/downloadFile";
import SmallButton from "../../components/SmallButton";

const Divider = styled(MuiDivider)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);
const Button = styled(MuiButton)<ButtonProps>(spacing);

function Programs() {
  const { showToast } = useToaster();
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [selectedProgram, setSelectedProgram] = useState<Program>();
  const [totalElements, setTotalElements] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [elementsPerPage, setElementsPerPage] = useState<number>(25);
  const [programs, setPrograms] = useState<Program[]>([]);
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();

  function getProgramsHandler(
    page: number = 0,
    size: number = 10,
    filter: string = "",
    search: string = "",
    sortOrder: string = "",
    sortField: string = ""
  ) {
    setTableLoading(true);
    ProgramsService.getPrograms(
      page,
      size,
      filter,
      search,
      sortOrder,
      sortField
    ).then((res: Search<Program>) => {
      setPrograms(res.content);
      setTotalElements(res.totalElements);
      setTableLoading(false);
    });
  }

  function updateProgram(program: Program) {
    setSelectedProgram(program);
    navigate("/private/programs/edit", {
      state: { program },
    });
  }

  function removeProgram(program: Program) {
    setSelectedProgram(program);
    setOpenRemoveModal(true);
  }

  async function clickRemoveHandler() {
    await ProgramsService.remove(selectedProgram?.id!)
      .then(() => showToast("Program successfully removed!", "success"))
      .then(() => setOpenRemoveModal(false))
      .then(() =>
        getProgramsHandler(pageNumber, elementsPerPage, "", "", "", "")
      )
      .catch((err) => showToast(err.message));
  }

  return (
    <>
      <Helmet title="Programs" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Programs
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <SmallButton
              mr={2}
              onClick={() =>
                exportData(
                  ProgramsService,
                  "programs-filters",
                  "programs.xlsx",
                  setIsLoading
                )
              }
            >
              <FileDownloadOutlinedIcon />
              Export
            </SmallButton>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("create")}
            >
              <AddIcon />
              New Program
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ProgramsTable
            programs={programs}
            count={totalElements || 0}
            loading={tableLoading}
            getPrograms={getProgramsHandler}
            setPageNumber={setPageNumber}
            setElementsPerPage={setElementsPerPage}
            updateProgram={(program: Program) => updateProgram(program)}
            removeProgram={(program: Program) => removeProgram(program)}
          ></ProgramsTable>
        </Grid>
      </Grid>

      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-program-dialog-title"
      >
        <DialogTitle id="remove-program-dialog-title">
          Remove Program
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-program-dialog-title">
            {`Do you want to remove ${selectedProgram?.name}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Programs;

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  Add as AddIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { Search } from "../../types/search";
import { HealthArticle } from "../../types/health-article";
import HealthArticleService from "../../services/HealthArticleService";
import HealthArticlesTable from "./HealthArticlesTable";
import useToaster from "../../hooks/useToaster";
import useLoading from "../../hooks/useLoading";
import exportData from "../../utils/downloadFile";
import SmallButton from "../../components/SmallButton";
import { useNavigate } from "react-router-dom";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);
const Divider = styled(MuiDivider)(spacing);

function HealthArticles() {
  const { showToast } = useToaster();
  const { setIsLoading } = useLoading();
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [article, setArticle] = useState<HealthArticle[]>([]);
  const [articleWillDoAction, setArticleWillDoAction] =
    useState<HealthArticle | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [elementsPerPage, setElementsPerPage] = useState<number>(25);

  const navigate = useNavigate();

  function getArticleHandler(
    page: number = 0,
    size: number = 10,
    filter: string = "",
    search: string = "",
    sortOrder: string = "",
    sortField: string = ""
  ) {
    setTableLoading(true);
    HealthArticleService.getHealthArticles(
      page,
      size,
      filter,
      search,
      sortOrder,
      sortField
    ).then((res: Search<HealthArticle>) => {
      setArticle(res.content);
      setTotalElements(res.totalElements);
      setTableLoading(false);
    });
  }

  function clickRemoveHandler() {
    HealthArticleService.removeHealthArticle(articleWillDoAction?.id!)
      .then(() => showToast("Health Article successfully removed!", "success"))
      .then(() => setOpenRemoveModal(false))
      .then(() =>
        getArticleHandler(pageNumber, elementsPerPage, "", "", "asc", "id")
      )
      .catch((err) => showToast(err.message));
  }

  function updateHealthArticle(healthArticle: HealthArticle) {
    setArticleWillDoAction(healthArticle);
    navigate("/private/health-articles/edit", {
      state: { healthArticleId: healthArticle?.id },
    });
  }

  function removeHandler(article: HealthArticle) {
    setArticleWillDoAction(article);
    setOpenRemoveModal(true);
  }

  return (
    <React.Fragment>
      <Helmet title="Health Articles" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Health Articles
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <SmallButton
              mr={2}
              onClick={() =>
                exportData(
                  HealthArticleService,
                  "healthArticles-filters",
                  "health-articles.xlsx",
                  setIsLoading
                )
              }
            >
              <FileDownloadOutlinedIcon />
              Export
            </SmallButton>
            <MuiButton
              variant="contained"
              color="primary"
              onClick={() => navigate("create")}
            >
              <AddIcon />
              New Health Article
            </MuiButton>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {article!! && (
            <HealthArticlesTable
              healthArticles={article}
              count={totalElements || 0}
              loading={tableLoading}
              getHealthArticles={getArticleHandler}
              setPageNumber={setPageNumber}
              setElementsPerPage={setElementsPerPage}
              updateHealthArticle={(healthArticle: HealthArticle) =>
                updateHealthArticle(healthArticle)
              }
              removeHealthArticle={(healthArticle: HealthArticle) =>
                removeHandler(healthArticle)
              }
            ></HealthArticlesTable>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-article-dialog-title"
      >
        <DialogTitle id="remove-article-dialog-title">
          Remove Health Article
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-article-dialog-title">
            {`Do you want to remove ID#${articleWillDoAction?.id} ${articleWillDoAction?.title} ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default HealthArticles;

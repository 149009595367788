import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import UsersTable from "./UsersTable";
import { Search } from "../../types/search";
import { UserDTO } from "../../types/user";
import UsersService from "../../services/usersService";
import CreateEditUserForm from "./CreateEditUserForm";
import useToaster from "../../hooks/useToaster";
import useLoading from "../../hooks/useLoading";
import exportData from "../../utils/downloadFile";
import SmallButton from "../../components/SmallButton";
import { GROUPS } from "../../constants";
import useAuth from "../../hooks/useAuth";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Divider = styled(MuiDivider)(spacing);
const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);
const Button = styled(MuiButton)<ButtonProps>(spacing);

function Users() {
  const { showToast } = useToaster();
  const { setIsLoading } = useLoading();
  const [totalElements, setTotalElements] = useState<number>(0);
  const [users, setUsers] = useState<UserDTO[]>([]);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [userWillDoAction, setUserWillDoAction] = useState<UserDTO | null>(
    null
  );
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [elementsPerPage, setElementsPerPage] = useState<number>(25);

  const { deleteUser } = useAuth();

  function getUsersHandler(
    page: number = 0,
    size: number = 10,
    filter: string = "",
    search: string = "",
    sortOrder: string = "",
    sortField: string = ""
  ) {
    setTableLoading(true);
    UsersService.getUsers(
      page,
      size,
      filter,
      search,
      sortOrder,
      sortField
    ).then((res: Search<UserDTO>) => {
      setUsers(res.content);
      setTotalElements(res.totalElements);
      setTableLoading(false);
    });
  }

  function createUserHandler(user: UserDTO) {
    UsersService.createUser(user)
      .then(() => showToast("User successfully created!", "success"))
      .then(() => setOpenCreateModal(false))
      .then(() =>
        getUsersHandler(pageNumber, elementsPerPage, "", "", "asc", "id")
      )
      .catch((err) => showToast(err.message));
  }

  function updateUserHandler(user: UserDTO) {
    UsersService.updateUser(user)
      .then(() => showToast("User successfully updated!", "success"))
      .then(() => setOpenUpdateModal(false))
      .then(() =>
        getUsersHandler(pageNumber, elementsPerPage, "", "", "asc", "id")
      )
      .catch((err) => showToast(err.message));
  }

  function clickRemoveHandler() {
    const userId = userWillDoAction?.id;
    const userEmail = userWillDoAction?.email;

    if (!userId || !userEmail) {
      showToast("Invalid user data", "error");
      return;
    }

    UsersService.removeUser(userId)
      .then(() => {
        return deleteUser(userEmail, GROUPS.USERS);
      })
      .then(() => {
        showToast("User successfully removed!", "success");
      })
      .then(() => {
        setOpenRemoveModal(false);
      })
      .then(() => {
        return getUsersHandler(
          pageNumber,
          elementsPerPage,
          "",
          "",
          "asc",
          "id"
        );
      })
      .catch((err) => {
        if (err.message === "User not found") {
          console.log(err.message);
          setOpenRemoveModal(false);
          return getUsersHandler(
            pageNumber,
            elementsPerPage,
            "",
            "",
            "asc",
            "id"
          );
        } else {
          showToast(err.message, "error");
        }
      });
  }

  function updateUseHandler(user: UserDTO) {
    setUserWillDoAction(user);
    setOpenUpdateModal(true);
  }

  function removeUserHandler(user: UserDTO) {
    setUserWillDoAction(user);
    setOpenRemoveModal(true);
  }

  return (
    <React.Fragment>
      <Helmet title="Users" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Users
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <SmallButton
              mr={2}
              onClick={() =>
                exportData(
                  UsersService,
                  "users-filters",
                  "users.xlsx",
                  setIsLoading
                )
              }
            >
              <FileDownloadOutlinedIcon />
              Export
            </SmallButton>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCreateModal(true)}
            >
              <AddIcon />
              New User
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {users!! && (
            <UsersTable
              users={users}
              count={totalElements || 0}
              loading={tableLoading}
              getUsers={getUsersHandler}
              setPageNumber={setPageNumber}
              setElementsPerPage={setElementsPerPage}
              updateUser={(user: UserDTO) => updateUseHandler(user)}
              removeUser={(user: UserDTO) => removeUserHandler(user)}
            ></UsersTable>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        aria-labelledby="create-user-dialog-title"
      >
        <DialogTitle id="create-user-dialog-title">New User</DialogTitle>
        <DialogContent>
          <CreateEditUserForm
            mode="create"
            onSubmit={(user) => createUserHandler(user)}
            onCancel={() => setOpenCreateModal(false)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        aria-labelledby="update-user-dialog-title"
      >
        <DialogTitle id="update-user-dialog-title">Edit User</DialogTitle>
        <DialogContent>
          <CreateEditUserForm
            mode="update"
            userId={userWillDoAction?.id!}
            onSubmit={(user) => updateUserHandler(user)}
            onCancel={() => setOpenUpdateModal(false)}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-company-dialog-title"
      >
        <DialogTitle id="remove-company-dialog-title">Remove User</DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-company-dialog-title">
            {`Do you want to remove ${userWillDoAction?.firstName} ${userWillDoAction?.lastName}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Users;

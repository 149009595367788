import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { Search } from "../../types/search";
import { useNavigate } from "react-router-dom";
import { NutritionPlan } from "../../types/nutritionPlan";
import NutritionPlansService from "../../services/nutritionPlansService";
import NutritionPlansTable from "./NutritionPlansTable";
import useToaster from "../../hooks/useToaster";
import exportData from "../../utils/downloadFile";
import useLoading from "../../hooks/useLoading";
import SmallButton from "../../components/SmallButton";

const Divider = styled(MuiDivider)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);

function NutritionPlans() {
  const { showToast } = useToaster();
  const { setIsLoading } = useLoading();
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [selectedPlan, setSelectedPlan] = useState<NutritionPlan>();
  const [totalElements, setTotalElements] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [elementsPerPage, setElementsPerPage] = useState<number>(25);
  const [nutritionPlans, setNutritionPlans] = useState<NutritionPlan[]>([]);

  const navigate = useNavigate();

  function getMealsHandler(
    page: number = 0,
    size: number = 10,
    filter: string = "",
    search: string = "",
    sortOrder: string = "",
    sortField: string = ""
  ) {
    setTableLoading(true);
    NutritionPlansService.getNutritionPlans(
      page,
      size,
      filter,
      search,
      sortOrder,
      sortField
    ).then((res: Search<NutritionPlan>) => {
      setNutritionPlans(res.content);
      setTotalElements(res.totalElements);
      setTableLoading(false);
    });
  }

  function updateNutritionPlan(nutritionPlan: NutritionPlan) {
    setSelectedPlan(nutritionPlan);
    navigate("/private/nutrition-plans/edit", {
      state: { nutritionPlan },
    });
  }

  function removeNutritionPlan(nutritionPlan: NutritionPlan) {
    setSelectedPlan(nutritionPlan);
    setOpenRemoveModal(true);
  }

  async function clickRemoveHandler() {
    await NutritionPlansService.remove(selectedPlan?.id!)
      .then(() => showToast("Nutrition plan successfully removed!", "success"))
      .then(() => setOpenRemoveModal(false))
      .then(() => getMealsHandler(pageNumber, elementsPerPage, "", "", "", ""))
      .catch((err) => showToast(err.message));
  }

  return (
    <>
      <Helmet title="Nutrition Plans" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Nutrition Plans
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <SmallButton
              mr={2}
              onClick={() =>
                exportData(
                  NutritionPlansService,
                  "nutritionPlans-filters",
                  "nutrition-plans.xlsx",
                  setIsLoading
                )
              }
            >
              <FileDownloadOutlinedIcon />
              Export
            </SmallButton>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("create")}
            >
              <AddIcon />
              New Nutrition Plan
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <NutritionPlansTable
            nutritionPlans={nutritionPlans}
            count={totalElements || 0}
            loading={tableLoading}
            getNutritionPlans={getMealsHandler}
            setPageNumber={setPageNumber}
            setElementsPerPage={setElementsPerPage}
            updateNutritionPlan={(nutritionPlan: NutritionPlan) =>
              updateNutritionPlan(nutritionPlan)
            }
            removeNutritionPlan={(nutritionPlan: NutritionPlan) =>
              removeNutritionPlan(nutritionPlan)
            }
          ></NutritionPlansTable>
        </Grid>
      </Grid>

      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-nutrition-plan-dialog-title"
      >
        <DialogTitle id="remove-nutrition-plan-dialog-title">
          Remove Meal
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-nutrition-plan-dialog-title">
            {`Do you want to remove ${selectedPlan?.name}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NutritionPlans;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import useLoading from "../../hooks/useLoading";
import NutritionProgramsService from "../../services/nutritionProgramsService";
import { NutritionProgram } from "../../types/nutritionProgram";
import { NutritionPlan } from "../../types/nutritionPlan";
import {
  DailyCaloriesType,
  ExcludedFoodType,
  MealPreferenceType,
} from "../../enums/meal";
import { NutritionProgramTitles } from "./NutritionProgramTitles";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function NutritionProgramDetails() {
  const { id } = useParams();
  const { setIsLoading } = useLoading();
  const [nutritionProgram, setNutritionProgram] = useState<NutritionProgram>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  useEffect(() => {
    setIsLoading(true);
    getCompany();
  }, []);

  function getCompany() {
    NutritionProgramsService.getById(Number(id)).then(
      (res: NutritionProgram) => {
        setNutritionProgram(res);
        setIsLoading(false);
        getKeyValuesItems(res);
      }
    );
  }

  const titles: { [key: string]: string } = NutritionProgramTitles;

  function parseValueByEnum(
    key: string,
    value: string | NutritionPlan | number
  ): string {
    switch (key) {
      case "mealPreference":
        return MealPreferenceType[value as keyof typeof MealPreferenceType];
      case "excludedFoods":
        return ExcludedFoodType[value as keyof typeof ExcludedFoodType];
      case "dailyCalories":
        return DailyCaloriesType[value as keyof typeof DailyCaloriesType];
      case "sundayNutritionPlan":
      case "mondayNutritionPlan":
      case "tuesdayNutritionPlan":
      case "wednesdayNutritionPlan":
      case "thursdayNutritionPlan":
      case "fridayNutritionPlan":
      case "saturdayNutritionPlan":
        return (value as NutritionPlan)?.name;
      default:
        return value as string;
    }
  }

  function getKeyValuesItems(nutritionProgram: NutritionProgram): void {
    const order = [
      "id",
      "name",
      "mealPreference",
      "excludedFoods",
      "dailyCalories",
      "weekNumber",
      "sundayNutritionPlan",
      "mondayNutritionPlan",
      "tuesdayNutritionPlan",
      "wednesdayNutritionPlan",
      "thursdayNutritionPlan",
      "fridayNutritionPlan",
      "saturdayNutritionPlan",
    ];

    const items = order.map((key) => {
      const value = nutritionProgram[key as keyof NutritionProgram];
      if (value === undefined) {
        return undefined;
      } else {
        return {
          key: titles[key] as string,
          value: parseValueByEnum(key, value),
        } as KeyValue;
      }
    });
    setKeyValuesItems(items as KeyValue[]);
  }

  return (
    <>
      <Helmet title="Nutrition Program Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Nutrition Program #{nutritionProgram?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/nutrition-programs">
          Nutrition Program
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            {keyValuesItems && (
              <Card px={6} pt={6}>
                <KeyValueComponent keyValueItems={keyValuesItems} />
              </Card>
            )}
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default NutritionProgramDetails;

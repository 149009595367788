import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { NutritionProgram } from "../../types/nutritionProgram";
import {
  DailyCaloriesType,
  ExcludedFoodType,
  MealPreferenceType,
} from "../../enums/meal";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import Table from "../../components/Table";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import { generateOptionsFromEnum } from "../../utils/generateOptionsFromEnum";
import useWindowWidth from "../../hooks/useWindowSize";

type NutritionProgramsTableProps = {
  nutritionPrograms: NutritionProgram[];
  count: number;
  loading: boolean;
  getNutritionPrograms: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateNutritionProgram: (nutritionProgram: NutritionProgram) => void;
  removeNutritionProgram: (nutritionProgram: NutritionProgram) => void;
};

const NutritionProgramsTable: React.FC<NutritionProgramsTableProps> = ({
  nutritionPrograms,
  count,
  loading,
  getNutritionPrograms,
  setPageNumber,
  setElementsPerPage,
  updateNutritionProgram,
  removeNutritionProgram,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowWidth();
  const SCREEN_WIDTH = 2200;

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/nutrition-programs/${params.row.id}/details`);
    }
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    nutritionProgram: NutritionProgram
  ) => {
    event.stopPropagation();
    handleMenuClose(nutritionProgram.id!);
    updateNutritionProgram(nutritionProgram);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    nutritionProgram: NutritionProgram
  ) => {
    event.stopPropagation();
    handleMenuClose(nutritionProgram.id!);
    removeNutritionProgram(nutritionProgram);
  };

  const mealPreferenceTypeOptions = generateOptionsFromEnum(MealPreferenceType);
  const excludedFoodTypeOptions = generateOptionsFromEnum(ExcludedFoodType);
  const dailyCaloriesTypeOptions = generateOptionsFromEnum(DailyCaloriesType);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Nutrition Program ID",
      width: 100,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "name",
      headerName: "Nutrition Program Name",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "mealPreference",
      headerName: "Meal Preference",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(mealPreferenceTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return MealPreferenceType[value as keyof typeof MealPreferenceType];
      },
    },
    {
      field: "excludedFoods",
      headerName: "Excluded Foods",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(excludedFoodTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return ExcludedFoodType[value as keyof typeof ExcludedFoodType];
      },
    },
    {
      field: "dailyCalories",
      headerName: "Daily Calories",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(dailyCaloriesTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return DailyCaloriesType[value as keyof typeof DailyCaloriesType];
      },
    },
    {
      field: "weekNumber",
      headerName: "Week Number",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "sundayNutritionPlan",
      headerName: "Sunday Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "mondayNutritionPlan",
      headerName: "Monday Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "tuesdayNutritionPlan",
      headerName: "Tuesday Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "wednesdayNutritionPlan",
      headerName: "Wednesday Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "thursdayNutritionPlan",
      headerName: "Thursday Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "fridayNutritionPlan",
      headerName: "Friday Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "saturdayNutritionPlan",
      headerName: "Saturday Plan",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const nutritionProgram = params.row as NutritionProgram;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={
                anchorEl[nutritionProgram.id!] ? "simple-menu" : undefined
              }
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) =>
                handleDetailButtonClick(event, nutritionProgram.id!)
              }
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[nutritionProgram.id!]}
              open={Boolean(anchorEl[nutritionProgram.id!])}
              onClose={() => handleMenuClose(nutritionProgram.id!)}
            >
              <MenuItem
                onClick={(event) => handleUpdateClick(event, nutritionProgram)}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={(event) => handleRemoveClick(event, nutritionProgram)}
              >
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="nutritionPrograms"
      rows={nutritionPrograms}
      columns={columns}
      count={count}
      loading={loading}
      getData={getNutritionPrograms}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
      initialState={{ pinnedColumns: { right: ["actions"] } }}
    />
  );
};

export default NutritionProgramsTable;

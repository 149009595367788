import {
  CardContent,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { KeyValue } from "../types/components/keyValue.type";

const Divider = styled(MuiDivider)(spacing);

type KeyValueComponentProps = {
  keyValueItems: KeyValue[];
};

function KeyValueComponent({ keyValueItems }: KeyValueComponentProps) {
  return (
    <CardContent>
      {keyValueItems.map((row: KeyValue, index) => (
        <Grid key={index} mt={1} container spacing={5}>
          <Grid item xs={6}>
            <Typography fontSize={14} variant="caption">
              {row.key}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              fontSize={14}
              variant="caption"
              align="right"
              display="block"
            >
              {row.value}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      ))}
    </CardContent>
  );
}

export default KeyValueComponent;

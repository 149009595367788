import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { Company } from "../../types/company";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import Table from "../../components/Table";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "../../hooks/useWindowSize";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";

type CompaniesTableProps = {
  companies: Company[];
  count: number;
  loading: boolean;
  getCompanies: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateCompany: (company: Company) => void;
  removeCompany: (company: Company) => void;
};

const CompaniesTable: React.FC<CompaniesTableProps> = ({
  companies,
  count,
  loading,
  getCompanies,
  setPageNumber,
  setElementsPerPage,
  updateCompany,
  removeCompany,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowWidth();
  const SCREEN_WIDTH = 2200;

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    company: Company
  ) => {
    event.stopPropagation();
    handleMenuClose(company.id!);
    updateCompany(company);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    company: Company
  ) => {
    event.stopPropagation();
    handleMenuClose(company.id!);
    removeCompany(company);
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/companies/${params.row.id}/details`);
    }
  };

  const yesNoOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const rows = companies.map((company) => ({
    id: company.id,
    name: company.name,
    active: company.active,
    address: company.address,
    city: company.city,
    state: company.state,
    zip: company.zip,
    contactName: company.contactName,
    emailAddress: company.emailAddress,
    phoneNumber: company.phoneNumber,
    activationCode: company.activationCode,
    activationUserLimit: company.activationUserLimit,
    disableUserMeasurementImage: company.disableUserMeasurementImage,
  }));

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Company ID",
      width: 100,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "name",
      headerName: "Company Name",
      width: windowWidth < SCREEN_WIDTH ? 200 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "address",
      headerName: "Address",
      width: windowWidth < SCREEN_WIDTH ? 200 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "city",
      headerName: "City",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "state",
      headerName: "State",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "zip",
      headerName: "Zip",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "contactName",
      headerName: "Contact Name",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "active",
      headerName: "Active",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(yesNoOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "activationCode",
      headerName: "Activation Code",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterable: false,
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "activationUserLimit",
      headerName: "Activation User Limit",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "disableUserMeasurementImage",
      headerName: "Disable User Measurement Image",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(yesNoOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const company = params.row as Company;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[company.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) => handleDetailButtonClick(event, company.id!)}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[company.id!]}
              open={Boolean(anchorEl[company.id!])}
              onClose={() => handleMenuClose(company.id!)}
            >
              <MenuItem onClick={(event) => handleUpdateClick(event, company)}>
                Edit
              </MenuItem>
              <MenuItem onClick={(event) => handleRemoveClick(event, company)}>
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];
  return (
    <Table
      id="companies"
      rows={rows}
      columns={columns}
      count={count}
      loading={loading}
      getData={getCompanies}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
      initialState={{ pinnedColumns: { right: ["actions"] } }}
    />
  );
};

export default CompaniesTable;

import axios from "../utils/axios";
import { Search } from "../types/search";
import { Workout } from "../types/workout";

const WorkoutsService = {
  getWorkouts: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<Workout>>("/api/workout", {
      params: { page, size, filter, search, sortOrder, sortField },
    });
    return response.data;
  },

  create: async (workout: Workout) => {
    const response = await axios.post<Search<Workout>>("/api/workout", workout);
    return response.data;
  },

  update: async (workout: Workout) => {
    const response = await axios.put<Workout>(`/api/workout`, workout);
    return response.data;
  },

  remove: async (id: number) => {
    const response = await axios.delete(`/api/workout/${id}`);
    return response.data;
  },

  getById: async (id: number) => {
    const response = await axios.get<Workout>(`/api/workout/${id}`);
    return response.data;
  },
  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/workout/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default WorkoutsService;

import React from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CardContent,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";

import { Exercise } from "../../../types/exercise";
import { WorkoutExerciseInfo } from "../../../types/workoutExerciseInfo";
import ExerciseAutocomplete from "../../../components/autocomplete/ExerciseAutocomplete";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const validationSchema = Yup.object().shape({
  exercise: Yup.object().nullable().required("Required"),
});

type CreatWorkoutExerciseInfoFormProps = {
  mode: "create" | "update";
  workoutExerciseInfo?: WorkoutExerciseInfo;
  onSubmit: (info: WorkoutExerciseInfo, infoId?: number) => void;
  onCancel: () => void;
};

export type WorkoutExerciseInfoForm = {
  numberOfSets: string | number;
  numberOfRepsPerSet: string;
  time: string;
  restBetweenSets: string;
  cardioTimeDuration: string;
  levelOfExertion: string;
  exerciseOrder: string;
  exercise?: Exercise;
};

function CreateEditWorkoutExerciseInfoForm({
  mode,
  workoutExerciseInfo,
  onSubmit,
  onCancel,
}: CreatWorkoutExerciseInfoFormProps): JSX.Element {
  const initialValues: WorkoutExerciseInfoForm = {
    numberOfSets: workoutExerciseInfo?.numberOfSets || "",
    numberOfRepsPerSet: workoutExerciseInfo?.numberOfRepsPerSet || "",
    time: workoutExerciseInfo?.time || "",
    restBetweenSets: workoutExerciseInfo?.restBetweenSets || "",
    cardioTimeDuration: workoutExerciseInfo?.cardioTimeDuration || "",
    levelOfExertion: workoutExerciseInfo?.levelOfExertion || "",
    exerciseOrder: workoutExerciseInfo?.exerciseOrder || "",
    exercise: workoutExerciseInfo?.exercise || undefined,
  };

  async function handleSubmit(values: WorkoutExerciseInfoForm) {
    const preparedData: WorkoutExerciseInfo = {
      id: workoutExerciseInfo?.id,
      numberOfSets: Number(values.numberOfSets),
      numberOfRepsPerSet: values.numberOfRepsPerSet,
      time: values.time,
      restBetweenSets: values.restBetweenSets,
      cardioTimeDuration: values.cardioTimeDuration,
      levelOfExertion: values.levelOfExertion,
      exerciseOrder: values.exerciseOrder,
      exercise: values.exercise,
    };

    await (mode === "create"
      ? onSubmit(preparedData)
      : onSubmit(preparedData, workoutExerciseInfo?.id!));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        values,
        setFieldValue,
      }) => (
        <CardContent>
          <form onSubmit={handleSubmit}>
            <ExerciseAutocomplete
              id="exercise"
              key="exercise"
              label="Exercise Name"
              selectedOption={values.exercise}
              onOptionSelect={(value) => setFieldValue("exercise", value)}
              error={!!errors.exercise}
              helperText={errors.exercise || ""}
            />

            <TextField
              name="numberOfSets"
              label="Number Of Sets"
              value={values.numberOfSets || ""}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              multiline
              my={2}
            />

            <TextField
              name="numberOfRepsPerSet"
              label="Number Of Reps Per Set"
              value={values.numberOfRepsPerSet || ""}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              multiline
              my={2}
            />

            <TextField
              name="time"
              label="Time"
              value={values.time || ""}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              multiline
              my={2}
            />

            <TextField
              name="restBetweenSets"
              label="Rest Between Sets"
              value={values.restBetweenSets || ""}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              multiline
              my={2}
            />

            <TextField
              name="cardioTimeDuration"
              label="Cardio Time Duration"
              value={values.cardioTimeDuration || ""}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              multiline
              my={2}
            />

            <TextField
              name="levelOfExertion"
              label="Level Of Exertion"
              value={values.levelOfExertion || ""}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              multiline
              my={2}
            />

            <TextField
              name="exerciseOrder"
              label="Exercise order"
              value={values.exerciseOrder || ""}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              multiline
              my={2}
            />

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditWorkoutExerciseInfoForm;

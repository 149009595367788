export enum WorkoutScoreLevel {
  LESS_30 = "<30",
  BETWEEN_31_33 = "31-33",
  BETWEEN_34_36 = "34-36",
  BETWEEN_37_39 = "37-39",
  BETWEEN_40_42 = "40-42",
  BETWEEN_43_45 = "43-45",
  BETWEEN_46_48 = "46-48",
  BETWEEN_49_51 = "49-51",
  BETWEEN_52_54 = "52-54",
  BETWEEN_55_57 = "55-57",
  BETWEEN_58_60 = "58-60",
  BETWEEN_61_63 = "61-63",
  BETWEEN_64_66 = "64-66",
  BETWEEN_67_69 = "67-69",
  BETWEEN_70_72 = "70-72",
  BETWEEN_73_75 = "73-75",
  BETWEEN_76_78 = "76-78",
  BETWEEN_79_81 = "79-81",
  BETWEEN_82_84 = "82-84",
  BETWEEN_85_87 = "85-87",
  BETWEEN_88_90 = "88-90",
  BETWEEN_91_93 = "91-93",
  MORE_THEN_93 = ">93",
}

import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";
import MonacoEditor from "@monaco-editor/react";
import {
  Backdrop,
  Box,
  Button as MuiButton,
  CardContent,
  CircularProgress,
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tabs as MuiTabs,
  Tab,
  TextField as MuiTextField,
  SelectChangeEvent,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import * as Yup from "yup";
import prettier from "prettier/standalone";
import parserHtml from "prettier/parser-html";
import {
  HealthArticle,
  HealthArticleCategory,
} from "../../types/health-article";
import HealthArticleService from "../../services/HealthArticleService";
import ImagePicker from "../../components/ImagePicker";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DescriptionContainer = styled.div`
  height: 400px;
  margin-bottom: 16px;
`;
const StyledReactQuill = styled(ReactQuill)`
  height: 358px;
  .quill {
    height: 100%;
  }
`;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);

const StyledBackdrop = styled(Backdrop)`
  color: #376fd0;
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);
const Tabs = styled(MuiTabs)<{ my?: number }>`
  min-height: unset;

  .MuiTabs-flexContainer {
    justify-content: flex-end;
  }

  & .MuiTab-root {
    min-height: 35px;

    &.Mui-selected {
      background: #376fd0;
      color: #fff;
    }
  }
`;

const validationSchema = Yup.object().shape({
  date: Yup.date().required("Required"),
  title: Yup.string().required("Required"),
  category: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  image: Yup.string().required("Required"),
});

type CreateHealthArticleFormProps = {
  mode: "create" | "update";
  healthArticleId?: number;
  onSubmit: (healthArticle: HealthArticle, id?: number) => void;
  onCancel: () => void;
};

export type HealthArticleForm = {
  date: Date | null;
  category: string;
  title: string;
  description: string;
  image: string;
};

function CreateEditHealthArticleForm({
  mode,
  healthArticleId,
  onSubmit,
  onCancel,
}: CreateHealthArticleFormProps): JSX.Element {
  const [healthArticle, setHealthArticle] = useState<HealthArticle>();
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [formData, setFormData] = useState<HealthArticleForm>({
    date: healthArticle ? new Date(healthArticle.date) : new Date(),
    title: healthArticle?.title || "",
    category: healthArticle?.category || "",
    description: healthArticle?.description || "",
    image: healthArticle?.image || "",
  });

  useLayoutEffect(() => {
    const fetchArticleAndImage = async () => {
      if (healthArticleId) {
        setIsImageLoading(true);
        try {
          const resp = await HealthArticleService.getById(healthArticleId);
          setHealthArticle({ ...resp, image: "" });
          if (resp.image) {
            const response = await axios.get(resp.image);
            const imageBlob = response.data;
            setHealthArticle({ ...resp, image: imageBlob });
            setIsImageLoading(false);
          } else {
            setIsImageLoading(false);
          }
        } catch (error) {
          console.error("Failed to fetch article or image", error);
          setIsImageLoading(false);
        }
      }
    };

    fetchArticleAndImage();
  }, [healthArticleId]);

  useEffect(() => {
    setFormData({
      date: healthArticle ? new Date(healthArticle.date) : new Date(),
      title: healthArticle?.title || "",
      category: healthArticle?.category || "",
      description: healthArticle?.description || "",
      image: healthArticle?.image || "",
    });
  }, [healthArticle]);

  useEffect(() => {
    if (tabIndex === 1 && formData.description) {
      const formattedCode = formatCode(formData.description);
      setFormData((prevData) => ({
        ...prevData,
        description: formattedCode,
      }));
    }
  }, [tabIndex]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name!]: value,
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name!]: value,
    }));
  };

  const handleDateChange = (date: Date | null) => {
    setFormData((prevData) => ({
      ...prevData,
      date,
    }));
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const formatCode = (code: string) => {
    try {
      return prettier.format(code, {
        parser: "html",
        plugins: [parserHtml],
        tabWidth: 2,
        printWidth: 120,
        useTabs: false,
      });
    } catch (error) {
      console.error("Failed to format code", error);
      return code;
    }
  };

  async function handleSubmit() {
    const preparedData: HealthArticle = {
      date: formData.date || new Date(),
      title: formData.title,
      category: formData.category,
      description: formData.description,
      image: formData.image,
    } as unknown as HealthArticle;

    setIsLoading(true);
    mode === "create"
      ? onSubmit(preparedData)
      : onSubmit(preparedData, healthArticle?.id!);
  }

  return (
    <>
      <StyledBackdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ errors, handleBlur, handleSubmit, touched }) => (
          <CardContent>
            <form onSubmit={handleSubmit}>
              <DemoContainer components={["DatePicker"]}>
                <Box
                  component="div"
                  my={2}
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <DatePicker
                    label="Health Article Date"
                    value={formData.date}
                    format="MM-dd-yyyy"
                    onChange={handleDateChange}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        fullWidth: true,
                        error: Boolean(touched.date && errors.date),
                        helperText: touched.date && errors.date,
                      },
                    }}
                  />
                </Box>
              </DemoContainer>

              <TextField
                name="title"
                label="Health Article Title"
                value={formData.title}
                error={Boolean(touched.title && errors.title)}
                fullWidth
                helperText={touched.title && errors.title}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                my={2}
              />

              <FormControl
                my={2}
                fullWidth
                error={touched.category && !!errors.category}
              >
                <InputLabel id="category-select-label">
                  Health Article Category
                </InputLabel>
                <Select
                  name="category"
                  labelId="category-select-label"
                  id="category-select"
                  value={formData.category}
                  label="Health Article Category"
                  onChange={handleSelectChange}
                >
                  {Object.entries(HealthArticleCategory).map(([key, value]) => (
                    <MenuItem key={`category-${key}`} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                {touched.category && errors.category && (
                  <FormHelperText>{errors.category}</FormHelperText>
                )}
              </FormControl>

              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Visual" />
                <Tab label="Code" />
              </Tabs>

              {tabIndex === 0 && (
                <DescriptionContainer data-name="description">
                  <StyledReactQuill
                    value={formData.description || ""}
                    modules={{
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                    onChange={(content) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        description: content || "",
                      }));
                    }}
                  />
                </DescriptionContainer>
              )}

              {tabIndex === 1 && (
                <Box
                  component="div"
                  border="1px solid rgb(204, 204, 204)"
                  marginBottom="16px"
                >
                  <MonacoEditor
                    height="400px"
                    language="html"
                    options={{
                      overviewRulerLanes: 0,
                      overviewRulerBorder: false,
                      wordWrap: "on",
                      wrappingIndent: "indent",
                      scrollBeyondLastLine: false,
                      minimap: {
                        enabled: false,
                      },
                    }}
                    value={formData.description}
                    onChange={(value: string | undefined) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        description: value || "",
                      }))
                    }
                  />
                </Box>
              )}

              <ImagePicker
                fullWidth
                name="image"
                label="Upload Image"
                value={formData.image}
                isLoading={isImageLoading}
                maxFileSize={500}
                error={Boolean(touched.image && errors.image)}
                helperText={touched.image ? errors.image : undefined}
                onFileChange={(name, value) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    image: value,
                  }));
                }}
                onBlur={handleBlur}
              />

              <Box
                component="div"
                display="flex"
                justifyContent="flex-end"
                mt={6}
              >
                <Button
                  variant="outlined"
                  color="error"
                  mt={3}
                  mr={5}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  {mode === "update" ? "Update" : "Save"}
                </Button>
              </Box>
            </form>
          </CardContent>
        )}
      </Formik>
    </>
  );
}

export default CreateEditHealthArticleForm;

import axios from "../utils/axios";
import { Search } from "../types/search";
import { Reward } from "../types/reward";

const RewardsService = {
  getRewards: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<Reward>>("/api/reward", {
      params: { page, size, filter, search, sortOrder, sortField },
    });
    return response.data;
  },

  create: async (reward: Reward) => {
    const response = await axios.post<Search<Reward>>("/api/reward", reward);
    return response.data;
  },

  update: async (reward: Reward) => {
    const response = await axios.put<Reward>(`/api/reward`, reward);
    return response.data;
  },

  remove: async (id: number) => {
    const response = await axios.delete(`/api/reward/${id}`);
    return response.data;
  },

  getById: async (id: number) => {
    const response = await axios.get<Reward>(`/api/reward/${id}`);
    return response.data;
  },

  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/reward/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default RewardsService;

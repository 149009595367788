export type HealthArticle = {
  id: number;
  category: string;
  title: string;
  description: string;
  image: string;
  date: Date;
};

export enum HealthArticleCategory {
  ACID_REFLUX = "Acid Reflux",
  ASTHMA = "Asthma",
  DIABETES = "Diabetes",
  HEART_DISEASE = "Heart Disease",
  KNEE_PAIN = "Knee Pain",
  SHOULDER_PAIN = "Shoulder Pain",
  LOWER_BACK_PAIN = "Lower Back Pain",
  HIGH_CHOLESTEROL = "High Cholesterol",
  HIGH_BLOOD_PRESSURE = "High Blood Pressure",
  ALCOHOL = "Alcohol",
  ANXIETY = "Anxiety",
  PROACTIVE = "Proactive",
  CARDIOVASCULAR_ENDURANCE = "Cardiovascular Endurance",
  EMOTIONAL_EATING = "Emotional Eating",
  EXERCISE_MOTIVATION = "Exercise Motivation",
  EXERCISE_RELATED = "Exercise Related",
  FAT_LOSS_RELATED = "Fat Loss Related",
  FINANCIAL = "Financial",
  GENERAL_NUTRITION = "General Nutrition",
  RESILIENCE = "Resilience",
  ENERGY = "Energy",
  STRENGTH = "Strength",
  MUSCLE_BUILDING = "Muscle Building",
  RELATIONSHIPS = "Relationships",
  OVER_50 = "Over 50",
  SELF_CONFIDENCE = "Self Confidence",
  SLEEP = "Sleep",
  SMOKING = "Smoking",
  STRESS = "Stress",
  POSITIVITY = "Positivity",
}

import { Injury } from "./injury";
import { Equipment } from "./equipment";
import { UserDTO } from "./user";
import { Location } from "./location";

export type HealthQuestionnaire = {
  id: number;
  date: string;
  currentWeight: number;
  imageMeasurement: boolean;
  waistSize: string;
  pantsSize: string;
  highBloodPressure: boolean;
  highCholesterol: boolean;
  diabetes: boolean;
  acidReflux: boolean;
  heartDisease: boolean;
  asthma: boolean;
  medication: boolean;
  injuries: Injury[];
  exerciseLocation: Location;
  equipments: Equipment[];
  fitnessGoal: string;
  weightTrainingLevel: string;
  cardioFitnessLevel: string;
  exerciseFrequency: string;
  exerciseIntensityLevel: string;
  workoutDifficulty: string;
  physicalGoal: string;
  selfImprovementGoal: string;
  currentDiet: string;
  mealFrequency: string;
  mealPreference: string;
  socialSupportLevel: string;
  financialSituationLevel: string;
  dailyStressLevel: string;
  sleepLevel: string;
  confidenceLevel: string;
  relationshipLevel: string;
  alcohol: string;
  smokeCigarettes: boolean;
  difficultTime: string;
  user: UserDTO;
};

export enum BooleanEnum {
  YES = "Yes",
  NO = "No",
}
export enum GoalEnum {
  LOSE_WEIGHT = "Lose Weight",
  BUILD_MUSCLE = "Build Muscle",
}

export enum WeightTrainingLevelEnum {
  HIGHLY_ADVANCED = "Highly Advanced",
  ADVANCED = "Advanced",
  AVERAGE = "Average",
  BEGINNER = "Beginner",
}

export enum CardioFitnessLevelEnum {
  GREAT = "Great",
  ABOVE_AVERAGE = "Above Average",
  AVERAGE = "Average",
  POOR = "Poor",
}

export enum ExerciseFrequencyEnum {
  FIVE_DAY_OR_MORE_A_WEEK = "Five day or more a week",
  FOUR_DAYS_A_WEEK = "Four days a week",
  THREE_DAYS_A_WEEK = "Three days a week",
  TWICE_A_WEEK = "Twice a week",
  ONCE_A_WEEK = "Once a week",
  I_DONT_EXERCISE_AT_ALL = "I Dont exercise at all",
}

export enum WorkoutIntensityLevelEnum {
  VERY_HIGH = "Very High",
  HIGH = "High",
  MEDIUM = "Medium",
  LOW = "Low",
  VERY_LOW = "Very Low",
}

export enum WorkoutDifficultyLevelEnum {
  VERY_EASY = "Very Easy",
  EASY = "Easy",
  JUST_RIGHT = "Just Right",
  HARD = "Hard",
  VERY_HARD = "Very Hard",
  NOT_APPLICABLE = "Not Applicable",
}

export enum NutritionLevelEnum {
  VERY_GOOD = "Very Good",
  GOOD = "Good",
  AVERAGE = "Average",
  POOR = "Poor",
  VERY_POOR = "Very Poor",
}

export enum MealFrequencyLevelEnum {
  FIVE_OR_MORE_TIMES_A_DAY = "Five or more times a day",
  THREE_TO_FOUR_TIMES_A_DAY = "Three to Four times a day",
  ONE_TO_TWO_TIMES_A_DAY = "One to Two times a day",
}

export enum MealPreferenceLevelEnum {
  VEGETARIAN = "Vegetarian",
  LOW_GLYCEMIC = "Low Glycemic",
  HEART_HEALTHY = "Heart Healthy",
  NO_PREFERENCE = "No Preference",
}

export enum SocialSupportLevelEnum {
  VERY_GOOD = "Very Good",
  GOOD = "Good",
  AVERAGE = "Average",
  POOR = "Poor",
  VERY_POOR = "Very Poor",
}

export enum FinancialLevelEnum {
  EXTREMELY_HAPPY = "Extremely Happy",
  VERY_HAPPY = "Very Happy",
  HAPPY = "Happy",
  UNHAPPY = "Unhappy",
  VERY_UNHAPPY = "Very Unhappy",
}

export enum StressLevelEnum {
  NO_STRESS = "No Stress",
  AVERAGE_STRESS = "Average Stress",
  VERY_STRESSED = "Very Stressed",
}

export enum SleepLevelEnum {
  ABOVE_NORMAL = "Above Normal",
  NORMAL = "Normal",
  BELOW_NORMAL = "Below Normal",
}

export enum ConfidenceLevelEnum {
  EXTREMELY_CONFIDENT = "Extremely Confident",
  VERY_CONFIDENT = "Very Confident",
  CONFIDENT = "Confident",
  UNCONFIDENT = "Unconfident",
  VERY_UNCONFIDENT = "Very Unconfident",
}

export enum RelationshipLevelEnum {
  EXTREMELY_HAPPY = "Extremely Happy",
  VERY_HAPPY = "Very Happy",
  HAPPY = "Happy",
  UNHAPPY = "Unhappy",
  VERY_UNHAPPY = "Very Unhappy",
}

export enum AlcoholLevelEnum {
  I_DONT_DRINK_ALCOHOL = "I don’t drink Alcohol",
  ONE_TO_FOUR_DRINKS_A_WEEK = "One to Four drinks a week",
  FIVE_TO_TEN_DRINKS_A_WEEK = "Five to Ten drinks a week",
  ELEVEN_TO_TWENTY_DRINKS_A_WEEK = "Eleven to Twenty drinks a week",
  TWENTY_OR_MORE_DRINKS_A_WEEK = "Twenty or more drinks a week",
}

export enum DifficultTimeReasonEnum {
  NONE = "None",
  ADDICTION = "Addiction",
  BREAKUP_OF_RELATIONSHIP = "Breakup of a Relationship",
  FAILING_AT_SOMETHING_IMPORTANT = "Failing at Something Important",
  FAMILY_CONFLICT = "Family Conflict",
  FINANCIAL_TROUBLES = "Financial Troubles",
  GRIEF_LOSS_OF_LOVED_ONE = "Grief-Loss of a Loved One",
  IDENTITY_CRISIS = "Identity Crisis",
  INJURY = "Injury",
  MENTAL_HEALTH_STRUGGLES = "Mental Health Struggles",
  SERIOUS_HEALTH_CONDITION = "Serious Health Condition",
  TRAUMATIC_EXPERIENCE = "Traumatic Experience",
}

export enum WaistSizeEnum {
  SIZE_25_TO_27 = "25-27",
  SIZE_28_TO_30 = "28-30",
  SIZE_31_TO_33 = "31-33",
  SIZE_34_TO_36 = "34-36",
  SIZE_37_TO_39 = "37-39",
  SIZE_40_TO_42 = "40-42",
  SIZE_43_TO_45 = "43-45",
  SIZE_46_TO_48 = "46-48",
  SIZE_49_TO_51 = "49-51",
  SIZE_52_TO_54 = "52-54",
  SIZE_55_TO_57 = "55-57",
  SIZE_58_TO_60 = "58-60",
  SIZE_NOT_APPLICABLE = "Not Applicable",
}

export enum PantsSizeEnum {
  SIZE_0_TO_2 = "0-2",
  SIZE_3_TO_5 = "3-5",
  SIZE_6_TO_8 = "6-8",
  SIZE_9_TO_11 = "9-11",
  SIZE_12_TO_14 = "12-14",
  SIZE_15_TO_17 = "15-17",
  SIZE_18_TO_20 = "18-20",
  SIZE_21_TO_23 = "21-23",
  SIZE_24_TO_26 = "24-26",
  SIZE_27_TO_29 = "27-29",
  SIZE_30_TO_32 = "30-32",
  SIZE_33_TO_35 = "33-35",
  SIZE_NOT_APPLICABLE = "Not Applicable",
}

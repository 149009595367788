import { format } from "date-fns";
import { GridFilterItem, GridFilterModel } from "@mui/x-data-grid-pro";

const equalsTextField = (item: GridFilterItem) => {
  switch (item.field) {
    case "company":
    case "substitute":
    case "program":
    case "breakfast":
    case "morningSnack":
    case "lunch":
    case "afternoonSnack":
    case "dinner":
    case "sundayNutritionPlan":
    case "mondayNutritionPlan":
    case "tuesdayNutritionPlan":
    case "wednesdayNutritionPlan":
    case "thursdayNutritionPlan":
    case "fridayNutritionPlan":
    case "saturdayNutritionPlan":
    case "sundayWorkout":
    case "mondayWorkout":
    case "tuesdayWorkout":
    case "wednesdayWorkout":
    case "thursdayWorkout":
    case "fridayWorkout":
    case "saturdayWorkout":
      return `${item.field}_name=${item.value}`;
    case "user":
      return `${item.field}_username=${item.value}`;
    default:
      return `${item.field}=${item.value}`;
  }
};

const containsTextField = (item: GridFilterItem) => {
  switch (item.field) {
    case "company":
    case "substitute":
    case "program":
    case "breakfast":
    case "morningSnack":
    case "lunch":
    case "afternoonSnack":
    case "dinner":
    case "sundayNutritionPlan":
    case "mondayNutritionPlan":
    case "tuesdayNutritionPlan":
    case "wednesdayNutritionPlan":
    case "thursdayNutritionPlan":
    case "fridayNutritionPlan":
    case "saturdayNutritionPlan":
    case "sundayWorkout":
    case "mondayWorkout":
    case "tuesdayWorkout":
    case "wednesdayWorkout":
    case "thursdayWorkout":
    case "fridayWorkout":
    case "saturdayWorkout":
      return `${item.field}_name:${item.value}`;
    case "user":
      return `${item.field}_username:${item.value}`;
    default:
      return `${item.field}:${item.value}`;
  }
};

export const queryFilterBuilder = (queryObject: GridFilterModel): string => {
  const queryStrings: string[] = queryObject?.items?.map((item) => {
    switch (item.operator) {
      case "equalsText":
        // if empty value, remove field
        if (item.value?.length) {
          return equalsTextField(item);
        }
        return "";
      case "multiSelectEquals":
        // if empty value, remove field
        if (item.value?.length) {
          switch (item.field) {
            case "bodyParts":
            case "equipments":
            case "injuries":
            case "location":
            case "exerciseLocation":
              return item.value.map((v: string) => `${item.field}_name=${v}`);
            default:
              return item.value.map((v: string) => `${item.field}=${v}`);
          }
        }
        return "";
      case "containsText":
        // if empty value, remove field
        if (item.value?.length) {
          return containsTextField(item);
        }
        return "";
      case "currentDate":
        // if empty value, remove field
        if (item.value) {
          const formattedDate = format(
            new Date(item.value as string),
            "MM/dd/yyyy HH:mm:ss"
          );
          return `${item.field}=${formattedDate}`;
        }
        return "";
      case "dateRange":
        // if empty value, remove field
        if (item.value?.length) {
          const [startDate, endDate] = item.value as string[];
          const formattedStartDate = format(
            new Date(startDate),
            "MM/dd/yyyy HH:mm:ss"
          );
          const formattedEndDate = format(
            new Date(endDate),
            "MM/dd/yyyy HH:mm:ss"
          );
          return `${item.field}>=${formattedStartDate},${item.field}<=${formattedEndDate}`;
        }
        return "";
      default:
        throw new Error(`Unsupported operator: ${item.operator}`);
    }
  });

  return queryStrings?.join(",");
};

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Autocomplete,
  Box,
  Button as MuiButton,
  CardContent,
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";

import { debounce } from "lodash";
import { Meal } from "../../types/meal";
import MealsService from "../../services/mealsService";
import {
  DailyCaloriesType,
  ExcludedFoodType,
  MealCategoryType,
  MealPreferenceType,
  MealTimeType,
  MealType,
} from "../../enums/meal";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type CreateEditMealFormProps = {
  mode: "create" | "update";
  meal?: Meal;
  onSubmit: (meal: Meal, id?: number) => void;
  onCancel: () => void;
};

export type MealForm = {
  name: string;
  mealPreference: string;
  excludedFoods: string;
  dailyCalories: string;
  mealCategory: string;
  mealType: string;
  mealTime: string;
  calories: string;
  carbs: string;
  protein: string;
  fat: string;
  description: string;
  substituteId?: string | number;
};

function CreateEditMealForm({
  mode,
  meal,
  onSubmit,
  onCancel,
}: CreateEditMealFormProps): JSX.Element {
  const [substituteValue] = useState("");
  const [substitutesOptions, setSubstitutesOptions] = useState<Meal[]>([]);

  const initialValues: MealForm = {
    name: meal?.name || "",
    mealPreference: meal?.mealPreference || "",
    excludedFoods: meal?.excludedFoods || "",
    dailyCalories: meal?.dailyCalories || "",
    mealCategory: meal?.mealCategory || "",
    mealType: meal?.mealType || "",
    mealTime: meal?.mealTime || "",
    calories: meal?.calories || "",
    carbs: meal?.carbs || "",
    protein: meal?.protein || "",
    fat: meal?.fat || "",
    description: meal?.description || "",
    substituteId: meal?.substitute?.id || "",
  };

  useEffect(() => {
    const debouncedFetch = debounce(() => {
      MealsService.getMeals(0, 10, substituteValue, "", "", "").then(
        ({ content }) => setSubstitutesOptions(content)
      );
    }, 300);
    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [substituteValue]);

  async function handleSubmit(values: MealForm) {
    const preparedData: Meal = {
      name: values.name,
      mealPreference: values.mealPreference,
      excludedFoods: values.excludedFoods,
      dailyCalories: values.dailyCalories,
      mealCategory: values.mealCategory,
      mealType: values.mealType,
      mealTime: values.mealTime,
      calories: values.calories,
      carbs: values.carbs,
      protein: values.protein,
      fat: values.fat,
      description: values.description,
      substitute: substitutesOptions.find((e) => e.id === values.substituteId)!,
    };

    await (mode === "create"
      ? onSubmit(preparedData)
      : onSubmit(preparedData, meal?.id!));
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Meal Name"
              value={values.name}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <FormControl my={2} fullWidth>
              <InputLabel id="mealPreference-select-label">
                Meal Preference
              </InputLabel>
              <Select
                name="mealPreference"
                labelId="mealPreference-select-label"
                id="mealPreference-select"
                value={values.mealPreference}
                label="Meal Preference"
                onChange={(e) =>
                  setFieldValue("mealPreference", e.target.value)
                }
              >
                {Object.keys(MealPreferenceType).map((mealPrefKey) => (
                  <MenuItem
                    key={`mealPreference-${mealPrefKey}`}
                    value={mealPrefKey}
                  >
                    {
                      MealPreferenceType[
                        mealPrefKey as keyof typeof MealPreferenceType
                      ]
                    }
                  </MenuItem>
                ))}
              </Select>
              {touched.mealPreference && errors.mealPreference && (
                <FormHelperText>{errors.mealPreference}</FormHelperText>
              )}
            </FormControl>

            <FormControl my={2} fullWidth>
              <InputLabel id="excludedFood-select-label">
                Excluded Foods
              </InputLabel>
              <Select
                name="excludedFood"
                labelId="excludedFood-select-label"
                id="excludedFood-select"
                value={values.excludedFoods}
                label="Excluded Foods"
                onChange={(e) => setFieldValue("excludedFoods", e.target.value)}
              >
                {Object.keys(ExcludedFoodType).map((excludedFoodKey) => (
                  <MenuItem
                    key={`excludedFood-${excludedFoodKey}`}
                    value={excludedFoodKey}
                  >
                    {
                      ExcludedFoodType[
                        excludedFoodKey as keyof typeof ExcludedFoodType
                      ]
                    }
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl my={2} fullWidth>
              <InputLabel id="dailyCalories-select-label">
                Daily Calories
              </InputLabel>
              <Select
                name="dailyCalories"
                labelId="dailyCalories-select-label"
                id="dailyCalories-select"
                value={values.dailyCalories}
                label="Daily Calories"
                onChange={(e) => setFieldValue("dailyCalories", e.target.value)}
              >
                {Object.keys(DailyCaloriesType).map((dailyCaloriesKey) => (
                  <MenuItem
                    key={`dailyCalories-${dailyCaloriesKey}`}
                    value={dailyCaloriesKey}
                  >
                    {
                      DailyCaloriesType[
                        dailyCaloriesKey as keyof typeof DailyCaloriesType
                      ]
                    }
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl my={2} fullWidth>
              <InputLabel id="mealCategory-select-label">
                Meal Category
              </InputLabel>
              <Select
                name="mealCategory"
                labelId="mealCategory-select-label"
                id="mealCategory-select"
                value={values.mealCategory}
                label="Meal Category"
                onChange={(e) => setFieldValue("mealCategory", e.target.value)}
              >
                {Object.keys(MealCategoryType).map((mealCategoryKey) => (
                  <MenuItem
                    key={`mealCategory-${mealCategoryKey}`}
                    value={mealCategoryKey}
                  >
                    {
                      MealCategoryType[
                        mealCategoryKey as keyof typeof MealCategoryType
                      ]
                    }
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl my={2} fullWidth>
              <InputLabel id="mealType-select-label">Meal Type</InputLabel>
              <Select
                name="mealType"
                labelId="mealType-select-label"
                id="mealType-select"
                value={values.mealType}
                label="Meal Type"
                onChange={(e) => setFieldValue("mealType", e.target.value)}
              >
                {Object.keys(MealType).map((mealTypeKey) => (
                  <MenuItem key={`mealType-${mealTypeKey}`} value={mealTypeKey}>
                    {MealType[mealTypeKey as keyof typeof MealType]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl my={2} fullWidth>
              <InputLabel id="mealTime-select-label">Meal Time</InputLabel>
              <Select
                name="mealTime"
                labelId="mealTime-select-label"
                id="mealTime-select"
                value={values.mealTime}
                label="Meal Time"
                onChange={(e) => setFieldValue("mealTime", e.target.value)}
              >
                {Object.keys(MealTimeType).map((mealTimeKey) => (
                  <MenuItem key={`mealTime-${mealTimeKey}`} value={mealTimeKey}>
                    {MealTimeType[mealTimeKey as keyof typeof MealTimeType]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              name="calories"
              label="Calories"
              value={values.calories}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <TextField
              name="carbs"
              label="Carbs"
              value={values.carbs}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <TextField
              name="protein"
              label="Protein"
              value={values.protein}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <TextField
              name="fat"
              label="Fat"
              value={values.fat}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <TextField
              name="description"
              label="Meal Description"
              value={values.description || ""}
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={4}
              my={2}
            />

            <Autocomplete
              id="substitute"
              options={substitutesOptions}
              getOptionLabel={(option) => option.name}
              fullWidth
              value={
                substitutesOptions.find(
                  (option) => option.id === values.substituteId
                ) || null
              }
              onChange={(event, newValue) => {
                setFieldValue("substituteId", newValue ? newValue.id : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meal Substitute"
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              )}
            />

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditMealForm;

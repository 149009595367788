import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
} from "@mui/material";
import forge from "node-forge";
import styled from "@emotion/styled";

const Image = styled(Avatar)`
  width: 200px;
  height: 200px;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 4px;

  img {
    width: initial;
  }
`;

interface Props {
  name: string;
  label: string;
  value: string;
  error?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  maxFileSize?: number; // Max file size in kilobytes (KB)
  onFileChange: (name: string, value: string) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onImageStatusChange?: (isNew: boolean) => void;
}

const ImagePicker: React.FC<Props> = ({
  name,
  label,
  value,
  error,
  isLoading = false,
  fullWidth = false,
  helperText,
  maxFileSize,
  onFileChange,
  onBlur,
  onImageStatusChange,
}) => {
  const [open, setOpen] = useState(false);
  const [currentImageHash, setCurrentImageHash] = useState<string | null>(null);
  const [fileSizeError, setFileSizeError] = useState<string | null>(null);

  useEffect(() => {
    if (value) {
      computeHash(value).then((hash) => {
        setCurrentImageHash(hash);
      });
    }
  }, [value]);

  async function computeHash(base64Data: string): Promise<string> {
    const data = atob(base64Data.split(",")[1]);
    const md = forge.md.sha256.create();
    md.update(data);
    return md.digest().toHex();
  }

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files && e.target.files[0];

    if (file) {
      const maxFileSizeInBytes = maxFileSize ? maxFileSize * 1024 : undefined;
      if (maxFileSizeInBytes && file.size > maxFileSizeInBytes) {
        setFileSizeError(`File size exceeds the limit of ${maxFileSize} KB`);
        return;
      }

      reader.onloadend = async () => {
        if (typeof reader.result === "string") {
          const newImageHash = await computeHash(reader.result);

          const isNew = newImageHash !== currentImageHash;

          if (onImageStatusChange) {
            onImageStatusChange(isNew);
          }

          if (isNew) {
            setCurrentImageHash(newImageHash);
            onFileChange(name, reader.result);
            setFileSizeError(null);
          }
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <FormControl fullWidth error={error || !!fileSizeError} variant="outlined">
      <Box component="div">
        <input
          accept="image/*"
          style={{ display: "none" }}
          id={`raised-button-file-${name}`}
          multiple
          type="file"
          onChange={handleImageChange}
          onBlur={onBlur}
        />
        <label htmlFor={`raised-button-file-${name}`}>
          <Button
            size="large"
            fullWidth={fullWidth}
            variant="outlined"
            component="span"
          >
            {isLoading && (
              <CircularProgress
                size={20}
                style={{
                  marginRight: "12px",
                }}
              />
            )}
            {label ?? "Upload"}
          </Button>
        </label>

        {value && (
          <Box component="div" mt={2} display="flex" justifyContent="end">
            <Image
              alt="Selected Image"
              src={value.toString()}
              onClick={() => setOpen(true)}
            />
            <Dialog keepMounted open={open} onClose={() => setOpen(false)}>
              <DialogContent>
                <img src={value.toString()} alt="Dialog Image" width="100%" />
              </DialogContent>
            </Dialog>
          </Box>
        )}
      </Box>
      {fileSizeError && <FormHelperText error>{fileSizeError}</FormHelperText>}
      {error && !fileSizeError && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ImagePicker;

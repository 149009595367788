import axios from "../utils/axios";
import { Search } from "../types/search";
import { BodyPart } from "../types/bodyPart";

const BodyPartsService = {
  getBodyParts: async (
    page: number,
    size: number,
    filter: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<BodyPart>>("/api/body-part", {
      params: { page, size, filter, sortOrder, sortField },
    });
    return response.data;
  },
};

export default BodyPartsService;

import axios from "../utils/axios";
import { Search } from "../types/search";
import { Injury } from "../types/injury";

const InjuriesService = {
  getInjuries: async (
    page: number,
    size: number,
    filter: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<Injury>>("/api/injury", {
      params: { page, size, filter, sortOrder, sortField },
    });
    return response.data;
  },
};

export default InjuriesService;

import axios from "../utils/axios";
import { Search } from "../types/search";
import { HelpAndSupport } from "../types/helpAndSupport";

const HelpAndSupportsService = {
  getHelpAndSupports: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<HelpAndSupport>>(
      "/api/help-and-support",
      {
        params: { page, size, filter, search, sortOrder, sortField },
      }
    );
    return response.data;
  },

  create: async (helpAndSupport: HelpAndSupport) => {
    const response = await axios.post<Search<HelpAndSupport>>(
      "/api/help-and-support",
      helpAndSupport
    );
    return response.data;
  },

  update: async (helpAndSupport: HelpAndSupport) => {
    const response = await axios.put<HelpAndSupport>(
      `/api/help-and-support`,
      helpAndSupport
    );
    return response.data;
  },

  remove: async (id: number) => {
    const response = await axios.delete(`/api/help-and-support/${id}`);
    return response.data;
  },

  getById: async (id: number) => {
    const response = await axios.get<HelpAndSupport>(
      `/api/help-and-support/${id}`
    );
    return response.data;
  },

  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/help-and-support/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default HelpAndSupportsService;

import styled from "@emotion/styled";
import { Button as MuiButton } from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const SmallButton = styled(Button)`
  min-width: 0;
  margin-right: 15px;

  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

export default SmallButton;

import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import styled from "@emotion/styled";
import { TextField as MuiTextField } from "@mui/material";
import { spacing } from "@mui/system";
import { FormikErrors, FormikTouched } from "formik";
import { Equipment, EquipmentEnum } from "../../../types/equipment";
import EquipmentsService from "../../../services/equipmentsService";
import { Location, LocationEnum } from "../../../types/location";
import HomeEquipments from "../../../pages/health-questionnaires/HomeEquipments";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface EquipmentsAutocompleteCheckboxProps {
  label: string;
  location?: Location | null;
  values: { equipments: Equipment[] };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: {
    equipments?: string | string[] | FormikErrors<Equipment>[] | undefined;
  };
  touched: { equipments?: boolean | FormikTouched<Equipment>[] };
}

export default function EquipmentsAutocompleteCheckbox({
  label,
  location,
  values,
  setFieldValue,
  errors,
  touched,
}: EquipmentsAutocompleteCheckboxProps) {
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      const page = 0;
      const size = 100;
      const filter = "";
      const sortOrder = "";
      const sortField = "";

      const equipmentsData = await EquipmentsService.getEquipments(
        page,
        size,
        filter,
        sortOrder,
        sortField
      );

      setEquipments(
        equipmentsData.content
          .filter((eq) => (location ? HomeEquipments.includes(eq.name) : eq))
          .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          )
      );
    };

    if (location === undefined || location?.name === LocationEnum.Gym) {
      setEquipments([]);
      setFieldValue("equipments", []);
      setIsDisabled(true);
    } else {
      fetchData().then(() => setIsDisabled(false));
    }
  }, [location]);

  const selectedEquipments = equipments.filter((equipment) =>
    values.equipments.some(
      (valueEquipment) => equipment.id === valueEquipment.id
    )
  );

  const getOptionDisabled = (option: Equipment) => {
    if (option.name === EquipmentEnum.NoEquipment) {
      return false;
    }
    return values.equipments.some(
      (valueEquipment) => valueEquipment.name === EquipmentEnum.NoEquipment
    );
  };

  return (
    <Autocomplete
      disabled={isDisabled}
      multiple
      id="checkboxes-tags-equipments"
      options={equipments}
      getOptionLabel={(option) => option.name}
      disableCloseOnSelect
      value={selectedEquipments}
      onChange={(event, newValue) => {
        if (
          newValue.some((option) => option.name === EquipmentEnum.NoEquipment)
        ) {
          setFieldValue("equipments", [
            equipments.find(
              (equipment) => equipment.name === EquipmentEnum.NoEquipment
            ),
          ]);
        } else {
          setFieldValue("equipments", newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionDisabled={getOptionDisabled}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox checked={selected} style={{ marginRight: 8 }} />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          my={2}
          label={label}
          error={touched.equipments && !!errors.equipments}
          helperText={
            touched.equipments &&
            (Array.isArray(errors.equipments) && errors.equipments.length > 0
              ? (errors.equipments[0] as React.ReactNode)
              : (errors.equipments as React.ReactNode))
          }
          variant="outlined"
        />
      )}
    />
  );
}

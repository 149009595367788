import React, { createContext, ReactNode, useState } from "react";
import { AlertColor } from "@mui/material";
import { ToasterValue } from "../types/toaster";

export type ToasterContextType = {
  showToast: (message: string, severity?: AlertColor) => void;
  hideToast: () => void;
  getToasterValue: () => ToasterValue;
};
export const ToasterContext = createContext<ToasterContextType | null>(null);

export const ToasterProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [severity, setSeverity] = useState<AlertColor>("error");

  const showToast = (message: string, severity: AlertColor = "error"): void => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const hideToast = (): void => {
    setOpen(false);
  };

  function getToasterValue(): ToasterValue {
    return { open, message, severity };
  }

  return (
    <ToasterContext.Provider value={{ showToast, hideToast, getToasterValue }}>
      {children}
    </ToasterContext.Provider>
  );
};

import axios from "../utils/axios";
import { Search } from "../types/search";
import { Measurement, MeasurementDTO } from "../types/measurement";

const MeasurementsService = {
  getMeasurementBy: async (id: number) => {
    const response = await axios.get<Measurement>(`/api/measurement/${id}`);
    return response.data;
  },
  getMeasurements: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<MeasurementDTO>>(
      "/api/measurement",
      {
        params: { page, size, filter, search, sortOrder, sortField },
      }
    );
    return response.data;
  },
  createMeasurement: async (measurement: Measurement) => {
    const response = await axios.post<Measurement>(
      "/api/measurement",
      measurement
    );
    return response.data;
  },
  editMeasurement: async (measurement: MeasurementDTO) => {
    const response = await axios.put<Measurement>(
      `/api/measurement`,
      measurement
    );
    return response.data;
  },
  removeMeasurement: async (id: number) => {
    const response = await axios.delete(`/api/measurement/${id}`);
    return response.data;
  },
  getById: async (id: number) => {
    const response = await axios.get<MeasurementDTO>(`/api/measurement/${id}`);
    return response.data;
  },
  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/measurement/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default MeasurementsService;

import React, { useLayoutEffect, useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { Equipment } from "../../types/equipment";
import { BodyPart } from "../../types/bodyPart";
import { Exercise } from "../../types/exercise";
import { useNavigate } from "react-router-dom";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import Table from "../../components/Table";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import EquipmentsService from "../../services/equipmentsService";
import BodyPartsService from "../../services/bodyPartsService";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import { OptionsFromEnum } from "../../utils/generateOptionsFromEnum";

type ExercisesTableProps = {
  exercises: Exercise[];
  count: number;
  loading: boolean;
  getExercises: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateExercise: (exercise: Exercise) => void;
  removeExercise: (exercise: Exercise) => void;
};

const ExercisesTable: React.FC<ExercisesTableProps> = ({
  exercises,
  count,
  loading,
  getExercises,
  setPageNumber,
  setElementsPerPage,
  updateExercise,
  removeExercise,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const [equipmentOptions, setEquipmentOptions] = useState<OptionsFromEnum[]>(
    []
  );
  const [bodyPartOptions, setBodyPartOptions] = useState<OptionsFromEnum[]>([]);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    fetchDataForSelects();
  }, []);

  async function fetchDataForSelects() {
    const page: number = 0;
    const size: number = 100;
    const filter: string = "";
    const sortOrder: string = "";
    const sortField: string = "";

    const [equipmentsData, bodyPartsData] = await Promise.all([
      EquipmentsService.getEquipments(page, size, filter, sortOrder, sortField),
      BodyPartsService.getBodyParts(page, size, filter, sortOrder, sortField),
    ]);
    setEquipmentOptions(
      equipmentsData.content.map((i) => ({ value: i.name, label: i.name }))
    );
    setBodyPartOptions(
      bodyPartsData.content
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        .map((i) => ({ value: i.name, label: i.name }))
    );
  }

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    exercise: Exercise
  ) => {
    event.stopPropagation();
    handleMenuClose(exercise.id!);
    updateExercise(exercise);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    exercise: Exercise
  ) => {
    event.stopPropagation();
    handleMenuClose(exercise.id!);
    removeExercise(exercise);
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/exercises/${params.row.id}/details`);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Exercise ID",
      width: 100,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "name",
      headerName: "Exercise Name",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "bodyParts",
      headerName: "Exercise Bodyparts",
      flex: 1,
      filterOperators: [multiSelectOperator(bodyPartOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value.map((b: BodyPart) => b.name).join(", ");
      },
    },
    {
      field: "equipments",
      headerName: "Exercise Equipment",
      flex: 1,
      filterOperators: [multiSelectOperator(equipmentOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value.map((b: Equipment) => b.name).join(", ");
      },
    },
    {
      field: "videoUrl",
      headerName: "Exercise Video Url",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "description",
      headerName: "Exercise Description",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.replace(/<\/?[^>]+(>|$)/g, "");
      },
    },
    {
      field: "substitute",
      headerName: "Exercise Substitute",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const exercise = params.row as Exercise;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[exercise.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) => handleDetailButtonClick(event, exercise.id!)}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[exercise.id!]}
              open={Boolean(anchorEl[exercise.id!])}
              onClose={() => handleMenuClose(exercise.id!)}
            >
              <MenuItem onClick={(event) => handleUpdateClick(event, exercise)}>
                Edit
              </MenuItem>
              <MenuItem onClick={(event) => handleRemoveClick(event, exercise)}>
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="exercises"
      rows={exercises}
      columns={columns}
      count={count}
      loading={loading}
      getData={getExercises}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
    />
  );
};

export default ExercisesTable;

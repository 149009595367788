export type Equipment = {
  id?: number;
  name: string;
};

export enum EquipmentEnum {
  NoEquipment = "No Equipment",
  Barbells = "Barbells",
  Dumbbells = "Dumbbells",
  Elliptical = "Elliptical",
  Treadmill = "Treadmill",
  RecumbentBike = "Recumbent Bike",
}

import React from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import CreateEditHealthArticleForm from "./CreateEditHealthArticleForm";
import useToaster from "../../hooks/useToaster";
import HealthArticleService from "../../services/HealthArticleService";
import { HealthArticle } from "../../types/health-article";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
function CreateHealthArticle() {
  const navigate = useNavigate();
  const { showToast } = useToaster();
  async function createHealthArticle(newHealthArticle: HealthArticle) {
    await HealthArticleService.createHealthArticle(newHealthArticle)
      .then(() => showToast("Health Article created successfully.", "success"))
      .then(() => navigate("/private/health-articles"))
      .catch((err) => showToast(err.message));
  }
  function cancel() {
    navigate("/private/health-articles");
  }

  return (
    <>
      <Helmet title="Create Health Article" />
      <Typography variant="h3" gutterBottom display="inline">
        Create Health Article
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/health-articles">
          Health Articles
        </Link>
        <Typography>Create</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <CreateEditHealthArticleForm
                mode="create"
                onSubmit={(healthArticle) => createHealthArticle(healthArticle)}
                onCancel={cancel}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateHealthArticle;

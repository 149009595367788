import React from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CardContent,
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import useToaster from "../../hooks/useToaster";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { GoalTypeEnum, Task } from "../../types/task";
import TasksService from "../../services/tasksService";
import * as Yup from "yup";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const DescriptionContainer = styled.div`
  height: 300px;
`;
const StyledReactQuill = styled(ReactQuill)`
  height: 258px;
  .quill {
    height: 100%;
  }
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type CreateTaskFormProps = {
  mode: "create" | "update";
  task?: Task;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

export type TaskForm = {
  name: string;
  goalType: string;
  description: string;
  link: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  goalType: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  link: Yup.string().required("Required"),
});

function CreateEditTaskForm({
  mode,
  task,
  onSubmit,
  onCancel,
}: CreateTaskFormProps): JSX.Element {
  const { showToast } = useToaster();

  const initialValues: TaskForm = {
    name: task?.name || "",
    goalType: task?.goalType || "",
    description: task?.description || "",
    link: task?.link || "",
  };

  async function handleSubmit(values: TaskForm) {
    const preparedData: Task = {
      name: values.name,
      goalType: values.goalType,
      description: values.description,
      link: values.link,
    };

    await (mode === "create"
      ? createExercise(preparedData)
      : updateExercise(preparedData, task?.id!)
    ).then(onSubmit);
  }

  async function createExercise(task: Task) {
    await TasksService.create(task)
      .then(() => showToast("Task successfully created!", "success"))
      .catch((err) => showToast(err.message));
  }

  async function updateExercise(newTask: Task, id: number) {
    await TasksService.update({ ...newTask, id })
      .then(() => showToast("Task successfully updated!", "success"))
      .catch((err) => showToast(err.message));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Task Name"
              value={values.name}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <FormControl
              my={2}
              fullWidth
              error={Boolean(touched.goalType && errors.goalType)}
            >
              <InputLabel id="goalType-select-label">Goal Type</InputLabel>
              <Select
                name="goalType"
                labelId="goalType-select-label"
                id="goalType-select"
                value={values.goalType}
                label="Goal Type"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("goalType", e.target.value)}
              >
                {Object.entries(GoalTypeEnum).map(([key, value]) => (
                  <MenuItem key={`goalType-${key}`} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {touched.goalType && errors.goalType && (
                <FormHelperText error>{errors.goalType}</FormHelperText>
              )}
            </FormControl>

            <DescriptionContainer data-name="description">
              <StyledReactQuill
                value={values.description || ""}
                modules={{
                  clipboard: {
                    matchVisual: false,
                  },
                }}
                onChange={(content) => {
                  setFieldValue("description", content);
                }}
              />
            </DescriptionContainer>

            <TextField
              name="link"
              label="Task Link"
              value={values.link}
              error={Boolean(touched.link && errors.link)}
              fullWidth
              helperText={touched.link && errors.link}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditTaskForm;

import React from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import InputMask from "react-input-mask";

import {
  Box,
  Button as MuiButton,
  CardContent,
  Grid,
  TextField as MuiTextField,
  FormControl as MuiFormControl,
  MenuItem,
  InputLabel,
  Select,
  FormHelperText,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import CompaniesService from "../../services/companiesService";
import { Company } from "../../types/company";
import useToaster from "../../hooks/useToaster";
import Tooltip from "@mui/material/Tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CachedIcon from "@mui/icons-material/Cached";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const StyledFormControlLabel = styled(FormControlLabel)<{ my?: number }>(
  spacing
);

const StyledGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
});

interface ButtonProps extends SpacingProps {
  component?: string;
  my?: number;
}
const FormControl = styled(MuiFormControl)(spacing);
const Button = styled(MuiButton)<ButtonProps>(spacing);

const FormButton = styled(Button)({
  padding: "5px 10px",
  minWidth: "51px",
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  active: Yup.boolean().required("Required"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  zip: Yup.string().required("Required"),
  contactName: Yup.string().required("Required"),
  emailAddress: Yup.string().email().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  activationCode: Yup.string(),
  activationUserLimit: Yup.number().required("Required").positive().integer(),
  disableUserMeasurementImage: Yup.boolean(),
});

type CreateCompanyFormProps = {
  mode: "create" | "update";
  company?: Company;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

function CreateEditCompanyForm({
  mode,
  company,
  onSubmit,
  onCancel,
}: CreateCompanyFormProps): JSX.Element {
  const { showToast } = useToaster();
  const initialValues: Company = {
    name: company?.name || "",
    active: company?.active || true,
    address: company?.address || "",
    city: company?.city || "",
    state: company?.state || "",
    zip: company?.zip || "",
    contactName: company?.contactName || "",
    emailAddress: company?.emailAddress || "",
    phoneNumber: company?.phoneNumber || "",
    activationCode: company?.activationCode || "",
    activationUserLimit: company?.activationUserLimit || 0,
    disableUserMeasurementImage: company?.disableUserMeasurementImage || false,
  };

  const activeOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  async function handleSubmit(values: Company) {
    await (mode === "create"
      ? createCompany(values)
      : updateCompany({ ...company, ...(values as any) })
    ).then(onSubmit);
  }

  function generateActivationCode(length: number = 20): string {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*";
    let result = "";
    const characterCount = characters.length;

    for (let i = 0; i < length; i++) {
      let randomValue: number;

      try {
        const randomValues = window.crypto.getRandomValues(new Uint32Array(1));
        randomValue = randomValues[0] / (0xffffffff + 1);
      } catch (error) {
        console.error(
          "Secure random number generation failed, using Math.random",
          error
        );
        randomValue = Math.random();
      }

      result += characters.charAt(Math.floor(randomValue * characterCount));
    }

    return result;
  }

  const copyToClipboard = (code: string) => {
    navigator.clipboard.writeText(code).then(
      () => {
        showToast("Code copied to clipboard", "success");
      },
      () => {
        showToast("Failed to copy code", "error");
      }
    );
  };

  async function createCompany(form: Company) {
    await CompaniesService.createCompany(form)
      .then(() => showToast("Company successfully created!", "success"))
      .catch((err) => showToast(err.message));
  }

  async function updateCompany(form: Company) {
    await CompaniesService.updateCompany(form)
      .then(() => showToast("Company successfully updated!", "success"))
      .catch((err) => showToast(err.message));
  }

  // @ts-ignore
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Company Name"
              value={values.name}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <TextField
              name="address"
              label="Address"
              value={values.address}
              error={Boolean(touched.address && errors.address)}
              fullWidth
              helperText={touched.address && errors.address}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <Grid container spacing={6}>
              <Grid item md={6}>
                <TextField
                  name="city"
                  label="City"
                  value={values.city}
                  error={Boolean(touched.city && errors.city)}
                  fullWidth
                  helperText={touched.city && errors.city}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="state"
                  label="State"
                  value={values.state}
                  error={Boolean(touched.state && errors.state)}
                  fullWidth
                  helperText={touched.state && errors.state}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />
              </Grid>
            </Grid>

            <Grid container spacing={6}>
              <Grid item md={6}>
                <InputMask
                  mask="99999"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.zip}
                  maskPlaceholder={" "}
                >
                  <TextField
                    name="zip"
                    label="Zip"
                    fullWidth
                    error={Boolean(touched.zip && errors.zip)}
                    helperText={touched.zip && errors.zip}
                    variant="outlined"
                    my={2}
                  />
                </InputMask>
              </Grid>
              <Grid item md={6}></Grid>
            </Grid>

            <TextField
              name="contactName"
              label="Contact Name"
              value={values.contactName}
              error={Boolean(touched.contactName && errors.contactName)}
              fullWidth
              helperText={touched.contactName && errors.contactName}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <TextField
              name="emailAddress"
              label="Email"
              value={values.emailAddress}
              error={Boolean(touched.emailAddress && errors.emailAddress)}
              fullWidth
              helperText={touched.emailAddress && errors.emailAddress}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />
            <InputMask
              mask="999 999 9999"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phoneNumber}
              maskPlaceholder={" "}
            >
              <TextField
                label="Phone number"
                fullWidth
                name="phoneNumber"
                helperText={touched.phoneNumber && errors.phoneNumber}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                variant="outlined"
                my={2}
              />
            </InputMask>

            <FormControl
              my={2}
              fullWidth
              error={Boolean(touched.active && errors.active)}
            >
              <InputLabel id="active-select-label">Active</InputLabel>
              <Select
                name="active"
                labelId="active-select-label"
                id="active-select"
                value={values.active?.toString()}
                label="Active"
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("active", e.target.value === "true")
                }
              >
                {activeOptions.map(({ label, value }) => (
                  <MenuItem key={`active-${label}`} value={value.toString()}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              {touched.active && errors.active && (
                <FormHelperText error>{errors.active}</FormHelperText>
              )}
            </FormControl>

            <TextField
              name="activationUserLimit"
              label="Activation User Limit"
              type="number"
              value={values.activationUserLimit}
              error={Boolean(
                touched.activationUserLimit && errors.activationUserLimit
              )}
              fullWidth
              helperText={
                touched.activationUserLimit && errors.activationUserLimit
              }
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <Grid container spacing={6}>
              <Grid item md={9}>
                <TextField
                  name="activationCode"
                  label="Activation Code"
                  value={values.activationCode}
                  error={Boolean(
                    touched.activationCode && errors.activationCode
                  )}
                  fullWidth
                  helperText={touched.activationCode && errors.activationCode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />
              </Grid>
              <StyledGrid item md={3}>
                <Tooltip title="Generate Activation Code" arrow>
                  <FormButton
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      setFieldValue(
                        "activationCode",
                        generateActivationCode(25)
                      )
                    }
                  >
                    <CachedIcon />
                  </FormButton>
                </Tooltip>
                {values.activationCode && (
                  <Tooltip title="Copy to Clipboard" arrow>
                    <FormButton
                      ml={2}
                      variant="outlined"
                      color="secondary"
                      onClick={() =>
                        copyToClipboard(values.activationCode || "")
                      }
                    >
                      <ContentCopyIcon />
                    </FormButton>
                  </Tooltip>
                )}
              </StyledGrid>
            </Grid>

            <FormControl fullWidth>
              <StyledFormControlLabel
                control={
                  <Checkbox
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    checked={values.disableUserMeasurementImage}
                    onChange={(e) =>
                      setFieldValue(
                        "disableUserMeasurementImage",
                        e.target.checked
                      )
                    }
                    name="disableUserMeasurementImage"
                    color="primary"
                  />
                }
                label="Disable User Measurement Image"
                my={2}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "13px",
                    fontWeight: "normal",
                    color: "rgba(0, 0, 0, 0.6)",
                  },
                }}
              />
            </FormControl>

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                onClick={onCancel}
                mr={5}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditCompanyForm;

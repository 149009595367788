import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import CompaniesTable from "./CompaniesTable";
import CreateEditCompanyForm from "./CreateEditCompanyForm";
import { Company } from "../../types/company";
import CompaniesService from "../../services/companiesService";
import useToaster from "../../hooks/useToaster";
import { Search } from "../../types/search";
import exportData from "../../utils/downloadFile";
import useLoading from "../../hooks/useLoading";
import SmallButton from "../../components/SmallButton";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Divider = styled(MuiDivider)(spacing);
const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);
const Button = styled(MuiButton)<ButtonProps>(spacing);

function Companies() {
  const { showToast } = useToaster();
  const { setIsLoading } = useLoading();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [companyWillDoAction, setCompanyWillDoAction] =
    useState<Company | null>(null);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [elementsPerPage, setElementsPerPage] = useState<number>(25);
  const [companies, setCompanies] = useState<Company[]>([]);

  async function getCompaniesHandler(
    page: number = 0,
    size: number = 10,
    filter: string = "",
    search: string = "",
    sortOrder: string = "",
    sortField: string = ""
  ) {
    setTableLoading(true);
    CompaniesService.getCompanies(
      page,
      size,
      filter,
      search,
      sortOrder,
      sortField
    ).then((res: Search<Company>) => {
      setCompanies(res.content);
      setTotalElements(res.totalElements);
      setTableLoading(false);
    });
  }

  async function submitHandle() {
    getCompaniesHandler(pageNumber, elementsPerPage, "", "", "asc", "id");
    setOpenCreateModal(false);
    setOpenUpdateModal(false);
  }

  function updateCompanyHandle(company: Company) {
    setCompanyWillDoAction(company);
    setOpenUpdateModal(true);
  }

  function removeCompanyHandle(company: Company) {
    setCompanyWillDoAction(company);
    setOpenRemoveModal(true);
  }

  async function clickRemoveHandler() {
    await CompaniesService.removeCompany(companyWillDoAction?.id!)
      .then(() => showToast("Company successfully removed!", "success"))
      .then(() => setOpenRemoveModal(false))
      .then(() =>
        getCompaniesHandler(pageNumber, elementsPerPage, "", "", "asc", "id")
      )
      .catch((err) => showToast(err.message));
  }

  return (
    <React.Fragment>
      <Helmet title="Companies" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Companies
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <SmallButton
              mr={2}
              onClick={() =>
                exportData(
                  CompaniesService,
                  "companies-filters",
                  "companies.xlsx",
                  setIsLoading
                )
              }
            >
              <FileDownloadOutlinedIcon />
              Export
            </SmallButton>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCreateModal(true)}
            >
              <AddIcon />
              New Company
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {companies!! && (
            <CompaniesTable
              companies={companies}
              count={totalElements || 0}
              loading={tableLoading}
              getCompanies={getCompaniesHandler}
              setPageNumber={setPageNumber}
              setElementsPerPage={setElementsPerPage}
              updateCompany={(company: Company) => updateCompanyHandle(company)}
              removeCompany={(company: Company) => removeCompanyHandle(company)}
            ></CompaniesTable>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        aria-labelledby="create-company-dialog-title"
      >
        <DialogTitle id="create-company-dialog-title">New Company</DialogTitle>
        <DialogContent>
          <CreateEditCompanyForm
            mode="create"
            onSubmit={submitHandle}
            onCancel={() => setOpenCreateModal(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        aria-labelledby="update-company-dialog-title"
      >
        <DialogTitle id="update-company-dialog-title">
          Update Company
        </DialogTitle>
        <DialogContent>
          <CreateEditCompanyForm
            mode="update"
            company={companyWillDoAction as Company}
            onSubmit={submitHandle}
            onCancel={() => setOpenUpdateModal(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-company-dialog-title"
      >
        <DialogTitle id="remove-company-dialog-title">
          Remove Company
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-company-dialog-title">
            {`Do you want to remove ${companyWillDoAction?.name}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Companies;

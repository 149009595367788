import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  Add as AddIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { Search } from "../../types/search";
import useToaster from "../../hooks/useToaster";
import { Notification } from "../../types/notification";
import NotificationService from "../../services/NotificationService";
import NotificationTable from "./NotificationTable";
import CreateEditNotificationForm from "./CreateEditNnotificationForm";
import useLoading from "../../hooks/useLoading";
import exportData from "../../utils/downloadFile";
import SmallButton from "../../components/SmallButton";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);
const Divider = styled(MuiDivider)(spacing);

function Notifications() {
  const { showToast } = useToaster();
  const { setIsLoading } = useLoading();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationWillDoAction, setNotificationWillDoAction] =
    useState<Notification | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [elementsPerPage, setElementsPerPage] = useState<number>(25);

  function getNotificationHandler(
    page: number = 0,
    size: number = 10,
    filter: string = "",
    search: string = "",
    sortOrder: string = "",
    sortField: string = ""
  ) {
    setTableLoading(true);
    NotificationService.getNotifications(
      page,
      size,
      filter,
      search,
      sortOrder,
      sortField
    ).then((res: Search<Notification>) => {
      setNotifications(res.content);
      setTotalElements(res.totalElements);
      setTableLoading(false);
    });
  }

  function clickRemoveHandler() {
    NotificationService.removeNotification(notificationWillDoAction?.id!)
      .then(() => showToast("Notification successfully removed!", "success"))
      .then(() => setOpenRemoveModal(false))
      .then(() =>
        getNotificationHandler(pageNumber, elementsPerPage, "", "", "asc", "id")
      )
      .catch((err) => showToast(err.message));
  }

  function updateHandler(notification: Notification) {
    setNotificationWillDoAction(notification);
    setOpenUpdateModal(true);
  }

  function removeHandler(notification: Notification) {
    setNotificationWillDoAction(notification);
    setOpenRemoveModal(true);
  }

  async function submitHandler() {
    getNotificationHandler(pageNumber, elementsPerPage, "", "", "asc", "id");
    setOpenCreateModal(false);
    setOpenUpdateModal(false);
  }

  return (
    <React.Fragment>
      <Helmet title="Notifications" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Notifications
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <SmallButton
              mr={2}
              onClick={() =>
                exportData(
                  NotificationService,
                  "notifications-filters",
                  "notifications.xlsx",
                  setIsLoading
                )
              }
            >
              <FileDownloadOutlinedIcon />
              Export
            </SmallButton>
            <MuiButton
              variant="contained"
              color="primary"
              onClick={() => setOpenCreateModal(true)}
            >
              <AddIcon />
              New Notification
            </MuiButton>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <NotificationTable
            notifications={notifications}
            count={totalElements || 0}
            loading={tableLoading}
            getNotifications={getNotificationHandler}
            setPageNumber={setPageNumber}
            setElementsPerPage={setElementsPerPage}
            updateNotification={(notification: Notification) =>
              updateHandler(notification)
            }
            removeNotification={(notification: Notification) =>
              removeHandler(notification)
            }
          />
        </Grid>
      </Grid>

      <Dialog
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Notification</DialogTitle>
        <DialogContent>
          <CreateEditNotificationForm
            mode="create"
            onSubmit={submitHandler}
            onCancel={() => setOpenCreateModal(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        aria-labelledby="update-dialog-title"
      >
        <DialogTitle id="update-dialog-title">Update Notification</DialogTitle>
        <DialogContent>
          <CreateEditNotificationForm
            mode="update"
            notification={notificationWillDoAction!}
            onSubmit={submitHandler}
            onCancel={() => setOpenUpdateModal(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-dialog-title"
      >
        <DialogTitle id="remove-dialog-title">
          Remove Health Article
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-dialog-title">
            {`Do you want to remove ID#${notificationWillDoAction?.id} ${notificationWillDoAction?.title} ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Notifications;

import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { NutritionPlan } from "../../types/nutritionPlan";
import {
  DailyCaloriesType,
  ExcludedFoodType,
  MealPreferenceType,
} from "../../enums/meal";
import { WeekDayType } from "../../enums/nutritionPlan";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import Table from "../../components/Table";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import { generateOptionsFromEnum } from "../../utils/generateOptionsFromEnum";

type NutritionPlansTableProps = {
  nutritionPlans: NutritionPlan[];
  count: number;
  loading: boolean;
  getNutritionPlans: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateNutritionPlan: (nutritionPlan: NutritionPlan) => void;
  removeNutritionPlan: (nutritionPlan: NutritionPlan) => void;
};

const NutritionPlansTable: React.FC<NutritionPlansTableProps> = ({
  nutritionPlans,
  count,
  loading,
  getNutritionPlans,
  setPageNumber,
  setElementsPerPage,
  updateNutritionPlan,
  removeNutritionPlan,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/nutrition-plans/${params.row.id}/details`);
    }
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    nutritionPlan: NutritionPlan
  ) => {
    event.stopPropagation();
    handleMenuClose(nutritionPlan.id!);
    updateNutritionPlan(nutritionPlan);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    nutritionPlan: NutritionPlan
  ) => {
    handleMenuClose(nutritionPlan.id!);
    removeNutritionPlan(nutritionPlan);
  };

  const mealPreferenceTypeOptions = generateOptionsFromEnum(MealPreferenceType);
  const excludedFoodTypeOptions = generateOptionsFromEnum(ExcludedFoodType);
  const dailyCaloriesTypeOptions = generateOptionsFromEnum(DailyCaloriesType);
  const weekDayTypeOptions = generateOptionsFromEnum(WeekDayType);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Nutrition Plan ID",
      width: 100,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "name",
      headerName: "Nutrition Plan Name",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "mealPreference",
      headerName: "Meal Preference",
      flex: 1,
      filterOperators: [multiSelectOperator(mealPreferenceTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return MealPreferenceType[value as keyof typeof MealPreferenceType];
      },
    },
    {
      field: "excludedFoods",
      headerName: "Excluded Foods",
      flex: 1,
      filterOperators: [multiSelectOperator(excludedFoodTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return ExcludedFoodType[value as keyof typeof ExcludedFoodType];
      },
    },
    {
      field: "dailyCalories",
      headerName: "Daily Calories",
      flex: 1,
      filterOperators: [multiSelectOperator(dailyCaloriesTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return DailyCaloriesType[value as keyof typeof DailyCaloriesType];
      },
    },
    {
      field: "weekNumber",
      headerName: "Week Number",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "weekDay",
      headerName: "Week Day",
      flex: 1,
      filterOperators: [multiSelectOperator(weekDayTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return WeekDayType[value as keyof typeof WeekDayType];
      },
    },
    {
      field: "breakfast",
      headerName: "Breakfast",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "morningSnack",
      headerName: "Morning Snack",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "lunch",
      headerName: "Lunch",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "afternoonSnack",
      headerName: "Afternoon Snack",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "dinner",
      headerName: "Dinner",
      flex: 1,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name || "";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const nutritionPlan = params.row as NutritionPlan;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={
                anchorEl[nutritionPlan.id!] ? "simple-menu" : undefined
              }
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) =>
                handleDetailButtonClick(event, nutritionPlan.id!)
              }
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[nutritionPlan.id!]}
              open={Boolean(anchorEl[nutritionPlan.id!])}
              onClose={() => handleMenuClose(nutritionPlan.id!)}
            >
              <MenuItem
                onClick={(event) => handleUpdateClick(event, nutritionPlan)}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={(event) => handleRemoveClick(event, nutritionPlan)}
              >
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="nutritionPlans"
      rows={nutritionPlans}
      columns={columns}
      count={count}
      loading={loading}
      getData={getNutritionPlans}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
    />
  );
};

export default NutritionPlansTable;

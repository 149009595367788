import axios from "axios";
import { baseConfig, cognitoConfig } from "../config";
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

const axiosInstance = axios.create({
  baseURL: baseConfig.backendUrl,
});

const UserPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.userPoolId || "",
  ClientId: cognitoConfig.clientId || "",
});

let isRefreshing = false;

const refreshSession = (user: CognitoUser): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    user.getSession((err: Error | null, session: CognitoUserSession | null) => {
      if (err || !session) {
        reject(err);
        return;
      }
      user.refreshSession(
        session.getRefreshToken(),
        (
          refreshErr: Error | null,
          refreshSession: CognitoUserSession | null
        ) => {
          if (refreshErr || !refreshSession) {
            reject(refreshErr);
            return;
          }
          const newToken = refreshSession.getIdToken().getJwtToken();
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${newToken}`;
          resolve();
        }
      );
    });
  });
};

axiosInstance.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401 && !isRefreshing) {
      isRefreshing = true;

      const user = UserPool.getCurrentUser();
      if (user) {
        try {
          await refreshSession(user);
          const configClone = {
            ...error.config,
            headers: {
              ...error.config.headers,
              Authorization:
                axiosInstance.defaults.headers.common.Authorization,
            },
          };
          // Retry the request with new token
          const result = await axiosInstance.request(configClone);
          isRefreshing = false;
          return result;
        } catch (e) {
          console.error("Failed to refresh token", e);
          isRefreshing = false;
        }
      }

      isRefreshing = false;
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Meal } from "../../types/meal";
import {
  DailyCaloriesType,
  ExcludedFoodType,
  MealCategoryType,
  MealPreferenceType,
  MealTimeType,
  MealType,
} from "../../enums/meal";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import Table from "../../components/Table";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import { generateOptionsFromEnum } from "../../utils/generateOptionsFromEnum";

type MealsTableProps = {
  meals: Meal[];
  count: number;
  loading: boolean;
  getMeals: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateMeal: (meal: Meal) => void;
  removeMeal: (meal: Meal) => void;
};

const MealsTable: React.FC<MealsTableProps> = ({
  meals,
  count,
  loading,
  getMeals,
  setPageNumber,
  setElementsPerPage,
  updateMeal,
  removeMeal,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    meal: Meal
  ) => {
    event.stopPropagation();
    handleMenuClose(meal.id!);
    updateMeal(meal);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    meal: Meal
  ) => {
    event.stopPropagation();
    handleMenuClose(meal.id!);
    removeMeal(meal);
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/meals/${params.row.id}/details`);
    }
  };
  const mealPreferenceTypeOptions = generateOptionsFromEnum(MealPreferenceType);
  const excludedFoodTypeOptions = generateOptionsFromEnum(ExcludedFoodType);
  const dailyCaloriesTypeOptions = generateOptionsFromEnum(DailyCaloriesType);
  const mealCategoryTypeOptions = generateOptionsFromEnum(MealCategoryType);
  const mealTypeOptions = generateOptionsFromEnum(MealType);
  const mealTimeTypeOptions = generateOptionsFromEnum(MealTimeType);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Meal ID",
      width: 50,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "name",
      headerName: "Meal Name",
      width: 150,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "mealPreference",
      headerName: "Meal Preference",
      width: 150,
      filterOperators: [multiSelectOperator(mealPreferenceTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return MealPreferenceType[value as keyof typeof MealPreferenceType];
      },
    },
    {
      field: "excludedFoods",
      headerName: "Excluded Foods",
      width: 150,
      filterOperators: [multiSelectOperator(excludedFoodTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return ExcludedFoodType[value as keyof typeof ExcludedFoodType];
      },
    },
    {
      field: "dailyCalories",
      headerName: "Daily Calories",
      width: 150,
      filterOperators: [multiSelectOperator(dailyCaloriesTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return DailyCaloriesType[value as keyof typeof DailyCaloriesType];
      },
    },
    {
      field: "mealCategory",
      headerName: "Meal Category",
      width: 150,
      filterOperators: [multiSelectOperator(mealCategoryTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return MealCategoryType[value as keyof typeof MealCategoryType];
      },
    },
    {
      field: "mealType",
      headerName: "Meal Type",
      width: 150,
      filterOperators: [multiSelectOperator(mealTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return MealType[value as keyof typeof MealType];
      },
    },
    {
      field: "mealTime",
      headerName: "Meal Time",
      width: 150,
      filterOperators: [multiSelectOperator(mealTimeTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return MealTimeType[value as keyof typeof MealTimeType];
      },
    },
    {
      field: "calories",
      headerName: "Calories",
      width: 100,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "carbs",
      headerName: "Carbs",
      width: 100,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "protein",
      headerName: "Protein",
      width: 100,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "fat",
      headerName: "Fat",
      width: 100,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "description",
      headerName: "Meal Description",
      width: 150,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "substitute",
      headerName: "Meal Substitute",
      width: 150,
      sortingOrder: ["desc", "asc"],
      filterable: false,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value ? value.name : "";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const meal = params.row as Meal;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[meal.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) => handleDetailButtonClick(event, meal.id!)}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[meal.id!]}
              open={Boolean(anchorEl[meal.id!])}
              onClose={() => handleMenuClose(meal.id!)}
            >
              <MenuItem onClick={(event) => handleUpdateClick(event, meal)}>
                Edit
              </MenuItem>
              <MenuItem onClick={(event) => handleRemoveClick(event, meal)}>
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="meals"
      rows={meals}
      columns={columns}
      count={count}
      loading={loading}
      getData={getMeals}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
      initialState={{ pinnedColumns: { right: ["actions"] } }}
    />
  );
};

export default MealsTable;

import React from "react";
import { Autocomplete, TextField, Checkbox, Typography } from "@mui/material";
import {
  GridCellParams,
  GridFilterInputValueProps,
  GridFilterItem,
} from "@mui/x-data-grid-pro";
import styled from "@emotion/styled";

const CurrentFilterTypography = styled(Typography)`
  font-size: 10px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
`;

interface FilterOption {
  label: string;
  value: string | boolean | number;
}

function MultiSelectInputValue(
  props: GridFilterInputValueProps & { filterOptions: FilterOption[] }
) {
  const { item, applyValue, filterOptions } = props;

  const value = item.value
    ? filterOptions.filter((option) => item.value.includes(option.value))
    : [];

  const [open, setOpen] = React.useState(false);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: FilterOption[]
  ) => {
    applyValue({ ...item, value: newValue.map((item) => item.value) });
  };

  return (
    <>
      <CurrentFilterTypography variant="caption" display="inline">
        Value
      </CurrentFilterTypography>
      <Autocomplete
        multiple
        options={filterOptions}
        value={value}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder={value.length === 0 ? "Select options" : ""}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} />
            {option.label}
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.length > 0 ? `${value.length} selected` : null
        }
        getOptionLabel={(option) => option.label}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(true);
        }}
      />
    </>
  );
}

export const multiSelectOperator = (filterOptions: FilterOption[]) => ({
  value: "multiSelectEquals",
  label: "Equals",
  InputComponent: (props: GridFilterInputValueProps) => (
    <MultiSelectInputValue {...props} filterOptions={filterOptions} />
  ),
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value || !Array.isArray(filterItem.value)) {
      return null;
    }

    const selectedOptions = filterItem.value;
    return (params: GridCellParams): boolean =>
      selectedOptions.includes(params.value);
  },
});

import {
  GridCellParams,
  GridFilterInputValueProps,
  GridFilterItem,
} from "@mui/x-data-grid-pro";
import {
  isWithinInterval,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DateRangeFilterTypography = styled(Typography)`
  font-size: 10px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.6);
`;

const CurrentFilterTypography = styled(Typography)`
  font-size: 10px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
`;

function DateRangeInputValue(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;

  const handleChange = (newValue: [Date | null, Date | null]) => {
    if (newValue[1]) {
      newValue[1] = setSeconds(setMinutes(setHours(newValue[1], 23), 59), 59);
    }
    applyValue({ ...item, value: newValue });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangeFilterTypography variant="caption" display="inline">
        Value
      </DateRangeFilterTypography>
      <DemoContainer components={["SingleInputDateRangeField"]}>
        <DateRangePicker
          value={item.value || [null, null]}
          format="MM-dd-yyyy"
          onChange={handleChange}
          slots={{ field: SingleInputDateRangeField }}
          slotProps={{ textField: { variant: "standard" } }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

export const dateRangeOperator = {
  value: "dateRange",
  label: "Date Range",
  InputComponent: DateRangeInputValue,
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value || !Array.isArray(filterItem.value)) {
      return null;
    }

    const dateRange = filterItem.value as string[];
    if (dateRange.length !== 2) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      const date = parseISO(params.value as string);
      const [start, end] = dateRange.map((dateStr: string) =>
        parseISO(dateStr)
      );
      return isWithinInterval(date, { start, end });
    };
  },
};

function DateFilterInput(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;

  const handleChange = (newValue: any) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <CurrentFilterTypography variant="caption" display="inline">
        Value
      </CurrentFilterTypography>
      <DatePicker
        format="MM-dd-yyyy"
        value={item.value || null}
        onChange={handleChange}
        slotProps={{ textField: { variant: "standard" } }}
      />
    </LocalizationProvider>
  );
}

export const currentDateOperator = {
  value: "currentDate",
  label: "Current",
  InputComponent: DateFilterInput,
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.value) {
      return null;
    }
    return (params: GridCellParams): boolean =>
      params.value === filterItem.value;
  },
};

import React, { useLayoutEffect, useState } from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Backdrop,
  Box,
  Button as MuiButton,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";

import { Equipment } from "../../types/equipment";
import { Program } from "../../types/program";
import { Location } from "../../types/location";
import { Injury, InjuryEnum } from "../../types/injury";
import { Workout } from "../../types/workout";

import ExerciseLocationsService from "../../services/exerciseLocationsService";
import InjuriesService from "../../services/injuriesService";
import { FitnessGoalType } from "../../enums/program";
import ProgramAutocomplete from "../../components/autocomplete/ProgramAutocomplete";
import { convertCamelCaseToTitle } from "../../utils/convertCamelCaseToTitle";
import WorkoutAutocomplete from "../../components/autocomplete/WorkoutAutocomplete";
import EquipmentsAutocompleteCheckbox from "../../components/autocomplete/checkbox/EquipmentsAutocompleteCheckbox";
import WorkoutScoreLevelAutocomplete from "../../components/autocomplete/WorkoutScoreLevelAutocomplete";
import useToaster from "../../hooks/useToaster";
import ProgramsService from "../../services/programsService";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);
const StyledBackdrop = styled(Backdrop)`
  color: #376fd0;
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type CreateProgramFormProps = {
  mode: "create" | "update";
  programId?: number;
  onSubmit: (program: Program, programId?: number) => void;
  onCancel: () => void;
};

export type ProgramForm = {
  [key: string]: any;
  programName: string;
  exerciseLocation?: Location;
  equipments: Equipment[];
  workoutScoreLevel: string;
  injuryIds: number[];
  fitnessGoal: string;
  sundayWorkout?: Workout;
  mondayWorkout?: Workout;
  tuesdayWorkout?: Workout;
  wednesdayWorkout?: Workout;
  thursdayWorkout?: Workout;
  fridayWorkout?: Workout;
  saturdayWorkout?: Workout;
  easySubstitute?: Program;
  hardSubstitute?: Program;
};

function CreateEditProgramForm({
  mode,
  programId,
  onSubmit,
  onCancel,
}: CreateProgramFormProps): JSX.Element {
  const [locations, setLocations] = useState<Location[]>([]);
  const [injuries, setInjuries] = useState<Injury[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [program, setProgram] = useState<Program>();
  const { showToast } = useToaster();

  const initialValues: ProgramForm = {
    programName: program?.name || "",
    exerciseLocation: program?.location || undefined,
    equipments: program?.equipments || [],
    workoutScoreLevel: program?.workoutScoreLevel || "",
    injuryIds: program?.injuries.map((e) => e.id!) || [],
    fitnessGoal: program?.fitnessGoal || "",
    sundayWorkout: program?.sundayWorkout! || undefined,
    mondayWorkout: program?.mondayWorkout! || undefined,
    tuesdayWorkout: program?.tuesdayWorkout! || undefined,
    wednesdayWorkout: program?.wednesdayWorkout! || undefined,
    thursdayWorkout: program?.thursdayWorkout! || undefined,
    fridayWorkout: program?.fridayWorkout! || undefined,
    saturdayWorkout: program?.saturdayWorkout! || undefined,
    easySubstitute: program?.easySubstitute || undefined,
    hardSubstitute: program?.hardSubstitute || undefined,
  };

  const workoutDays = [
    "sundayWorkout",
    "mondayWorkout",
    "tuesdayWorkout",
    "wednesdayWorkout",
    "thursdayWorkout",
    "fridayWorkout",
    "saturdayWorkout",
  ];

  useLayoutEffect(() => {
    fetchDataForSelects().then(() => setIsLoading(false));
  }, []);

  async function fetchDataForSelects() {
    const page: number = 0;
    const size: number = 100;
    const filter: string = "";
    const sortOrder: string = "";
    const sortField: string = "";
    let programData;

    setIsLoading(true);

    const [locationsData, injuriesData] = await Promise.all([
      ExerciseLocationsService.getLocations(
        page,
        size,
        filter,
        sortOrder,
        sortField
      ),
      InjuriesService.getInjuries(page, size, filter, sortOrder, sortField),
    ]);

    if (programId) {
      try {
        programData = await ProgramsService.getById(programId);
      } catch (error) {
        if (error instanceof Error) {
          showToast(error.message);
        } else {
          showToast("An unexpected error occurred with loading program.");
        }
      }
    }
    setLocations(locationsData.content);
    setInjuries(injuriesData.content);
    if (programData) setProgram(programData);
  }

  async function handleSubmit(values: ProgramForm) {
    const preparedData: Program = {
      name: values.programName,
      location: values.exerciseLocation!,
      equipments: values.equipments,
      workoutScoreLevel: values.workoutScoreLevel,
      injuries: injuries.filter((i) => values.injuryIds?.includes(i.id!)),
      fitnessGoal: values.fitnessGoal,
      sundayWorkout: values.sundayWorkout!,
      mondayWorkout: values.mondayWorkout!,
      tuesdayWorkout: values.tuesdayWorkout!,
      wednesdayWorkout: values.wednesdayWorkout!,
      thursdayWorkout: values.thursdayWorkout!,
      fridayWorkout: values.fridayWorkout!,
      saturdayWorkout: values.saturdayWorkout!,
      easySubstitute: values.easySubstitute!,
      hardSubstitute: values.hardSubstitute!,
    };

    mode === "create"
      ? onSubmit(preparedData)
      : onSubmit(preparedData, program?.id!);
  }

  function locationFieldChangeHandler(
    e: SelectChangeEvent<unknown>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) {
    const selectedLocation = locations.find(
      (l) => l.id === parseInt(e.target.value as string)
    );

    setFieldValue("exerciseLocation", selectedLocation);
  }

  function getNoneInjuresID(): number {
    return injuries.find((inj) => inj.name === InjuryEnum.None)!.id || 1;
  }

  return (
    <>
      {isLoading ? (
        <StyledBackdrop open={isLoading}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      ) : (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <CardContent>
              <form onSubmit={handleSubmit}>
                <TextField
                  name="programName"
                  label="Program Name"
                  value={values.programName}
                  error={Boolean(touched.programName && errors.programName)}
                  fullWidth
                  helperText={touched.programName && errors.programName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                />

                <FormControl
                  my={2}
                  fullWidth
                  error={
                    touched.exerciseLocationId && !!errors.exerciseLocationId
                  }
                >
                  <InputLabel id="location-select-label">
                    Program Location
                  </InputLabel>
                  <Select
                    name="exerciseLocationId"
                    labelId="location-select-label"
                    id="location-select"
                    value={values.exerciseLocation?.id ?? ""}
                    label="Program Location"
                    onBlur={handleBlur}
                    onChange={(e) =>
                      locationFieldChangeHandler(e, setFieldValue)
                    }
                  >
                    {locations.length > 0 &&
                      locations.map((location) => (
                        <MenuItem
                          key={`location-${location.id}`}
                          value={location.id}
                        >
                          {location.name}
                        </MenuItem>
                      ))}
                  </Select>
                  {touched.exerciseLocation && errors.exerciseLocation && (
                    <FormHelperText>{errors.exerciseLocation}</FormHelperText>
                  )}
                </FormControl>

                <EquipmentsAutocompleteCheckbox
                  label="Program Equipment"
                  location={null}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                />

                <WorkoutScoreLevelAutocomplete
                  id="workout-score-level-type-select"
                  label="Workout Score Level"
                  selectedOption={values.workoutScoreLevel}
                  onOptionSelect={(value) => {
                    setFieldValue("workoutScoreLevel", value || "");
                  }}
                />

                <FormControl my={2} fullWidth>
                  <InputLabel id="injury-select-label">
                    Program Injuries
                  </InputLabel>
                  <Select
                    labelId="injury-select-label"
                    id="injury-select"
                    multiple
                    value={values.injuryIds}
                    onChange={(e) =>
                      (e.target.value as number[]).includes(getNoneInjuresID())
                        ? setFieldValue("injuryIds", [getNoneInjuresID()])
                        : setFieldValue("injuryIds", e.target.value as number[])
                    }
                    input={<OutlinedInput label="Program Injuries" />}
                    renderValue={(selected) =>
                      injuries
                        .filter(
                          (injury) =>
                            injury.id !== undefined &&
                            (selected as number[]).includes(injury.id)
                        )
                        .map((injury) => injury.name)
                        .join(", ")
                    }
                  >
                    {injuries.length > 0 &&
                      injuries.map((injury) => (
                        <MenuItem
                          key={`injury-${injury.id}`}
                          value={injury.id}
                          disabled={
                            Boolean(injury.id !== getNoneInjuresID()) &&
                            Boolean(
                              values.injuryIds.includes(getNoneInjuresID())
                            )
                          }
                        >
                          <Checkbox
                            checked={
                              injury.id !== undefined &&
                              values.injuryIds.includes(injury.id)
                            }
                          />
                          <ListItemText primary={injury.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl my={2} fullWidth>
                  <InputLabel id="fitness-Goal-select-label">
                    Fitness Goal
                  </InputLabel>
                  <Select
                    name="fitnessGoal"
                    labelId="fitness-Goal-select-label"
                    id="fitness-Goal-select-label"
                    value={values.fitnessGoal}
                    label="Fitness Goal"
                    onChange={(e) =>
                      setFieldValue("fitnessGoal", e.target.value)
                    }
                  >
                    {Object.entries(FitnessGoalType).map(([key, value]) => (
                      <MenuItem key={`fitness-Goal-${key}`} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {workoutDays.map((day) => (
                  <WorkoutAutocomplete
                    id={day}
                    key={day}
                    label={convertCamelCaseToTitle(day)}
                    selectedOption={values[day as keyof ProgramForm] as Workout}
                    onOptionSelect={(value) => setFieldValue(day, value)}
                  />
                ))}

                <ProgramAutocomplete
                  id="easySubstitute"
                  label="Easy Program Substitute"
                  selectedOption={values.easySubstitute}
                  onOptionSelect={(value) =>
                    setFieldValue("easySubstitute", value)
                  }
                />

                <ProgramAutocomplete
                  id="hardSubstitute"
                  label="Hard Program Substitute"
                  selectedOption={values.hardSubstitute}
                  onOptionSelect={(value) =>
                    setFieldValue("hardSubstitute", value)
                  }
                />

                <Box
                  component="div"
                  display="flex"
                  justifyContent="flex-end"
                  mt={6}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    mt={3}
                    mr={5}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    {mode === "update" ? "Update" : "Save"}
                  </Button>
                </Box>
              </form>
            </CardContent>
          )}
        </Formik>
      )}
    </>
  );
}

export default CreateEditProgramForm;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import { Meal } from "../../types/meal";
import MealsService from "../../services/mealsService";
import { MealTitles } from "./MealTitles";
import {
  DailyCaloriesType,
  ExcludedFoodType,
  MealCategoryType,
  MealPreferenceType,
  MealTimeType,
  MealType,
} from "../../enums/meal";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function MealDetails() {
  const { id } = useParams();
  const [meal, setMeal] = useState<Meal>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  useEffect(() => {
    getMeal();
  }, []);

  function getMeal() {
    MealsService.getById(Number(id)).then((res: Meal) => {
      setMeal(res);
      getKeyValuesItems(res);
    });
  }

  const titles: { [key: string]: string } = MealTitles;

  function parseValueByEnum(
    key: string,
    value: string | Meal | number
  ): string {
    switch (key) {
      case "mealPreference":
        return MealPreferenceType[value as keyof typeof MealPreferenceType];
      case "excludedFoods":
        return ExcludedFoodType[value as keyof typeof ExcludedFoodType];
      case "dailyCalories":
        return DailyCaloriesType[value as keyof typeof DailyCaloriesType];
      case "mealCategory":
        return MealCategoryType[value as keyof typeof MealCategoryType];
      case "mealType":
        return MealType[value as keyof typeof MealType];
      case "mealTime":
        return MealTimeType[value as keyof typeof MealTimeType];
      case "substitute":
        return (value as Meal)?.name;
      default:
        return value as string;
    }
  }

  function getKeyValuesItems(meal: Meal): void {
    const order: (keyof Meal)[] = [
      "id",
      "name",
      "mealPreference",
      "excludedFoods",
      "dailyCalories",
      "mealCategory",
      "mealType",
      "mealTime",
      "calories",
      "carbs",
      "protein",
      "fat",
      "description",
      "substitute",
    ];

    const items = order.map((key) => {
      const value = meal[key as keyof Meal];
      if (value === undefined) {
        return undefined;
      } else {
        return {
          key: titles[key as string],
          value: parseValueByEnum(key, value),
        } as KeyValue;
      }
    });
    setKeyValuesItems(items as KeyValue[]);
  }

  return (
    <>
      <Helmet title="Meal Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Meal #{meal?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/meals">
          Meals
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <KeyValueComponent keyValueItems={keyValuesItems} />
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default MealDetails;

import axios from "../utils/axios";
import { Search } from "../types/search";
import { Company } from "../types/company";

const CompaniesService = {
  getCompanies: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<Company>>("/api/company", {
      params: { page, size, filter, search, sortOrder, sortField },
    });
    return response.data;
  },

  createCompany: async (company: Company) => {
    const response = await axios.post<Company>("/api/company", company);
    return response.data;
  },

  updateCompany: async (company: Company) => {
    const response = await axios.put<Company>(`/api/company`, company);
    return response.data;
  },

  removeCompany: async (id: number) => {
    const response = await axios.delete(`/api/company/${id}`);
    return response.data;
  },
  getById: async (id: number) => {
    const response = await axios.get<Company>(`/api/company/${id}`);
    return response.data;
  },

  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/company/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default CompaniesService;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import {
  HealthArticle,
  HealthArticleCategory,
} from "../../types/health-article";
import HealthArticleService from "../../services/HealthArticleService";
import { HealthArticleTitles } from "./HealthArticleTitles";
import { format } from "date-fns";
import axios from "axios";
import useToaster from "../../hooks/useToaster";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Description = styled.div`
  text-align: initial;

  p,
  pre,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ol,
  ul {
    margin: 0;
  }
`;

function HealthArticleDetails() {
  const { id } = useParams();
  const [healthArticle, setHealthArticle] = useState<HealthArticle>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  const [open, setOpen] = useState(false);
  const [dialogImageSrc, setDialogImageSrc] = useState<string | null>(null);
  const titles: { [key: string]: string } = HealthArticleTitles;
  const { showToast } = useToaster();

  useEffect(() => {
    getHealthArticle();
  }, []);

  function getHealthArticle() {
    HealthArticleService.getById(Number(id)).then(
      async (res: HealthArticle) => {
        const image = !res?.image ? null : "";
        setHealthArticle({ ...res, image: image! });
        getKeyValuesItems({ ...res, image: image! });
        if (res.image) {
          try {
            const response = await axios.get(res.image);
            const imageBlob = response.data;
            setHealthArticle({ ...res, image: imageBlob });
            getKeyValuesItems({ ...res, image: imageBlob });
          } catch (error) {
            showToast(`Failed to load image`);
          }
        }
      }
    );
  }

  const handleClickOpen = (imageSrc: string) => {
    setOpen(true);
    setDialogImageSrc(imageSrc);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function parseValueByEnum(key: string, value: any): JSX.Element | string {
    switch (key) {
      case "date":
        return format(new Date(value), "MM-dd-yyyy");
      case "category":
        return HealthArticleCategory[
          value as keyof typeof HealthArticleCategory
        ];
      case "description":
        return (
          <Description>
            {parse(DOMPurify.sanitize((value as string) || ""))}
          </Description>
        );
      case "image":
        if (value === null) {
          return "";
        } else if (value === "") {
          return <CircularProgress size={20} />;
        } else {
          return (
            <img
              src={value}
              alt={key}
              height="200px"
              onClick={() => handleClickOpen(value)}
              style={{
                cursor: "pointer",
                border: "1px solid #000",
                borderRadius: "4px",
              }}
            />
          );
        }
      default:
        return value;
    }
  }

  function getKeyValuesItems(healthArticle: HealthArticle): void {
    const order: (keyof HealthArticle)[] = [
      "id",
      "date",
      "title",
      "description",
      "category",
      "image",
    ];

    const items = order.map((key) => {
      const value = healthArticle[key as keyof HealthArticle];
      if (value === undefined) {
        return undefined;
      } else {
        return {
          key: titles[key as string],
          value: parseValueByEnum(key, value),
        } as KeyValue;
      }
    });
    setKeyValuesItems(items as KeyValue[]);
  }

  return (
    <>
      <Helmet title="Health Article Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Health Article #{healthArticle?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/health-articles">
          Health Articles
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <KeyValueComponent keyValueItems={keyValuesItems} />
            </Card>
          </Shadow>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <img src={dialogImageSrc || ""} alt="Dialog" width="100%" />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default HealthArticleDetails;

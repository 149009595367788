import axios from "../utils/axios";
import { Search } from "../types/search";
import { UserDTO } from "../types/user";

const UsersService = {
  getUsers: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<UserDTO>>("/api/user", {
      params: { page, size, filter, search, sortOrder, sortField },
    });
    return response.data;
  },
  createUser: async (user: UserDTO) => {
    const response = await axios.post<UserDTO>("/api/user", user);
    return response.data;
  },
  updateUser: async (user: UserDTO) => {
    const response = await axios.put<UserDTO>(`/api/user`, user);
    return response.data;
  },
  removeUser: async (id: number) => {
    const response = await axios.delete(`/api/user/${id}`);
    return response.data;
  },
  getById: async (id: number) => {
    const response = await axios.get<UserDTO>(`/api/user/${id}`);
    return response.data;
  },
  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/user/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default UsersService;

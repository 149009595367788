import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { KeyValue } from "../../types/components/keyValue.type";
import { BodyPart } from "../../types/bodyPart";
import { Equipment } from "../../types/equipment";
import { Helmet } from "react-helmet-async";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import KeyValueComponent from "../../components/KeyValueComponents";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import WorkoutsService from "../../services/workoutsService";
import { Workout } from "../../types/workout";
import { Location } from "../../types/location";
import { Injury } from "../../types/injury";
import { WorkoutTitles } from "./WorkoutTitles";
import { Exercise } from "../../types/exercise";
import { WorkoutExerciseInfo } from "../../types/workoutExerciseInfo";
import { WorkoutScoreLevel } from "../../enums/WorkoutScoreLevel";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import TableWithoutPagination from "../../components/TableWithoutPagination";
import useWindowWidth from "../../hooks/useWindowSize";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TableWrapper = styled.div`
  margin: 20px 10px;
  height: 540px;
`;

function WorkoutDetails() {
  const { id } = useParams();
  const [workout, setWorkout] = useState<Workout>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [workoutExerciseInfo, setWorkoutExerciseInfo] = useState<
    WorkoutExerciseInfo[]
  >([]);
  const { width: windowWidth } = useWindowWidth();
  const SCREEN_WIDTH = 2200;

  useEffect(() => {
    getWorkout();
  }, []);

  function getWorkout() {
    setLoading(true);
    WorkoutsService.getById(Number(id)).then((res: Workout) => {
      setWorkout(res);
      getKeyValuesItems(res);
      setWorkoutExerciseInfo(
        [...res.workoutExerciseInfos].sort(
          (a, b) => Number(a?.exerciseOrder) - Number(b?.exerciseOrder)
        )
      );
      setLoading(false);
    });
  }

  const titles: { [key: string]: string } = WorkoutTitles;

  function parseValueByEnum(
    key: string,
    value:
      | string
      | BodyPart[]
      | number
      | Equipment[]
      | Workout
      | Location
      | WorkoutExerciseInfo[]
  ) {
    switch (key) {
      case "equipments":
        return (value as Equipment[]).map((e) => e.name).join(", ");
      case "injuries":
        return (value as Injury[]).map((e) => e.name).join(", ");
      case "bodyParts":
        return (value as BodyPart[]).map((e) => e.name).join(", ");
      case "location":
        return (value as Location).name;
      case "substitute":
        return (value as Workout)?.name;
      case "id":
      case "name":
      case "type":
      case "description":
      case "workoutScoreLevel":
        return value?.toString();
    }
  }

  function getKeyValuesItems(workout: Workout): void {
    const order = [
      "id",
      "name",
      "location",
      "type",
      "equipments",
      "injuries",
      "bodyParts",
      "workoutScoreLevel",
      "description",
      "substitute",
    ];

    const items = order.map((key) => {
      const value = workout[key as keyof Workout];
      return value === undefined
        ? undefined
        : ({
            key: titles[key] as string,
            value: parseValueByEnum(key, value),
          } as KeyValue);
    });
    setKeyValuesItems(items as KeyValue[]);
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Workout Exercise Info ID",
      width: windowWidth < SCREEN_WIDTH ? 100 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterable: false,
      sortable: false,
    },
    {
      field: "exercise",
      headerName: "Exercise",
      width: windowWidth < SCREEN_WIDTH ? 200 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterable: false,
      sortable: false,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name;
      },
    },
    {
      field: "numberOfSets",
      headerName: "Number Of Sets",
      width: windowWidth < SCREEN_WIDTH ? 125 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterable: false,
      sortable: false,
    },
    {
      field: "numberOfRepsPerSet",
      headerName: "Number Of Reps Rer Set",
      width: windowWidth < SCREEN_WIDTH ? 125 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterable: false,
      sortable: false,
    },
    {
      field: "time",
      headerName: "Time",
      width: windowWidth < SCREEN_WIDTH ? 100 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterable: false,
      sortable: false,
    },
    {
      field: "restBetweenSets",
      headerName: "Rest Between Sets",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterable: false,
      sortable: false,
    },
    {
      field: "cardioTimeDuration",
      headerName: "Cardio Time Duration",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterable: false,
      sortable: false,
    },
    {
      field: "levelOfExertion",
      headerName: "Level Of Exertion",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterable: false,
      sortable: false,
    },
    {
      field: "exerciseOrder",
      headerName: "Exercise Order",
      width: windowWidth < SCREEN_WIDTH ? 100 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterable: false,
      sortable: false,
    },
  ];

  return (
    <>
      <Helmet title="Workout Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Workout #{workout?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/workouts">
          Workouts
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <KeyValueComponent keyValueItems={keyValuesItems} />
              <Typography mt={5} ml={4} mr={4} fontSize={20} fontWeight="bold">
                Workout Exercise Infos
              </Typography>
              <TableWrapper>
                <TableWithoutPagination
                  rows={workoutExerciseInfo}
                  columns={columns}
                  loading={loading}
                />
              </TableWrapper>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default WorkoutDetails;

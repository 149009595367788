import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import {
  HealthArticle,
  HealthArticleCategory,
} from "../../types/health-article";
import { format } from "date-fns";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import {
  currentDateOperator,
  dateRangeOperator,
} from "../../utils/filters/dateField";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import Table from "../../components/Table";
import { useNavigate } from "react-router-dom";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";

type HealthArticlesTableProps = {
  healthArticles: HealthArticle[];
  count: number;
  loading: boolean;
  getHealthArticles: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateHealthArticle: (healthArticle: HealthArticle) => void;
  removeHealthArticle: (healthArticle: HealthArticle) => void;
};

const HealthArticlesTable: React.FC<HealthArticlesTableProps> = ({
  healthArticles,
  count,
  loading,
  getHealthArticles,
  setPageNumber,
  setElementsPerPage,
  updateHealthArticle,
  removeHealthArticle,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    healthArticle: HealthArticle
  ) => {
    event.stopPropagation();
    handleMenuClose(healthArticle.id!);
    updateHealthArticle(healthArticle);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    healthArticle: HealthArticle
  ) => {
    event.stopPropagation();
    handleMenuClose(healthArticle.id!);
    removeHealthArticle(healthArticle);
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/health-articles/${params.row.id}/details`);
    }
  };

  const healthArticleCategoryOptions = Object.entries(
    HealthArticleCategory
  ).map(([value, label]) => ({
    value,
    label,
  }));

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Health Article ID",
      flex: 1,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "date",
      headerName: "Health Article Date",
      flex: 1,
      filterOperators: [dateRangeOperator, currentDateOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return format(new Date(value), "MM-dd-yyyy");
      },
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      type: "string",
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      filterOperators: [multiSelectOperator(healthArticleCategoryOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return HealthArticleCategory[
          value as keyof typeof HealthArticleCategory
        ];
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const healthArticle = params.row as HealthArticle;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={
                anchorEl[healthArticle.id!] ? "simple-menu" : undefined
              }
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) =>
                handleDetailButtonClick(event, healthArticle.id!)
              }
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[healthArticle.id!]}
              open={Boolean(anchorEl[healthArticle.id!])}
              onClose={() => handleMenuClose(healthArticle.id!)}
            >
              <MenuItem
                onClick={(event) => handleUpdateClick(event, healthArticle)}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={(event) => handleRemoveClick(event, healthArticle)}
              >
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="healthArticles"
      rows={healthArticles}
      columns={columns}
      count={count}
      loading={loading}
      getData={getHealthArticles}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
    />
  );
};

export default HealthArticlesTable;

import React from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";
import {
  Box,
  Button as MuiButton,
  CardContent,
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import defaultDayjs from "dayjs";
import { spacing, SpacingProps } from "@mui/system";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import * as Yup from "yup";
import useToaster from "../../hooks/useToaster";
import { Notification } from "../../types/notification";
import NotificationService from "../../services/NotificationService";
import { GenderEnum } from "../../types/user";
import { GoalEnum } from "../../types/health-questionnaire";
import CompanyAutocomplete from "../../components/autocomplete/CompanyAutocomplete";
import { Company } from "../../types/company";
import dayjs from "dayjs";
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const validationSchema = Yup.object().shape({
  notifyDate: Yup.date().required("Required"),
  company: Yup.object().test(
    "has-properties",
    "Company is required",
    (value) => value && Object.keys(value).length > 0
  ),
  title: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

type CreateNotificationFormProps = {
  mode: "create" | "update";
  notification?: Notification;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

export type NotificationForm = {
  notifyDate: Date | null;
  company?: Company;
  gender: string;
  goal: string;
  title: string;
  message: string;
};

function CreateEditNotificationForm({
  mode,
  notification,
  onSubmit,
  onCancel,
}: CreateNotificationFormProps): JSX.Element {
  const { showToast } = useToaster();

  const initialValues: NotificationForm = {
    notifyDate: notification
      ? dayjs(notification.notifyDate).utc(true)
      : new Date(),
    company: notification?.company || undefined,
    gender: notification?.gender || "",
    goal: notification?.goal || "",
    title: notification?.title || "",
    message: notification?.message || "",
  } as unknown as NotificationForm;

  async function handleSubmit(values: NotificationForm) {
    const preparedData: Notification = {
      notifyDate: dayjs(values?.notifyDate).utc(true),
      title: values?.title,
      company: values?.company,
      gender: values?.gender,
      goal: values?.goal,
      message: values?.message,
    } as unknown as Notification;

    await (mode === "create"
      ? createNotification(preparedData)
      : updateNotification(preparedData, notification?.id!)
    ).then(onSubmit);
  }

  async function createNotification(notification: Notification) {
    await NotificationService.createNotification(notification)
      .then(() => showToast("Notification created successfully.", "success"))
      .catch((err) => showToast(err.message));
  }

  async function updateNotification(newNotification: Notification, id: number) {
    await NotificationService.editNotification({
      ...newNotification,
      id,
    })
      .then(() =>
        showToast(`Notification #${id} updated successfully.`, "success")
      )
      .catch((err) => showToast(err.message));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent>
          <form onSubmit={handleSubmit}>
            <CompanyAutocomplete
              id="company-select"
              label="Company"
              selectedOption={values.company}
              onOptionSelect={(value) => setFieldValue("company", value)}
              isTouched={!!touched.company}
              error={errors.company || ""}
            />

            <FormControl
              my={2}
              fullWidth
              error={Boolean(touched.gender && errors.gender)}
            >
              <InputLabel id="gender-select-label">Gender</InputLabel>
              <Select
                name="gender"
                labelId="gender-select-label"
                id="gender-select"
                value={values.gender}
                label="Gender"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("gender", e.target.value)}
              >
                {Object.entries(GenderEnum).map(([key, value]) => (
                  <MenuItem key={`gender-${key}`} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {touched.gender && errors.gender && (
                <FormHelperText error>{errors.gender}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              my={2}
              fullWidth
              error={Boolean(touched.goal && errors.goal)}
            >
              <InputLabel id="goal-select-label">Goal</InputLabel>
              <Select
                name="goal"
                labelId="goal-select-label"
                id="goal-select"
                value={values.goal}
                label="Goal"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("goal", e.target.value)}
              >
                {Object.entries(GoalEnum).map(([key, value]) => (
                  <MenuItem key={`goal-${key}`} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {touched.goal && errors.goal && (
                <FormHelperText error>{errors.goal}</FormHelperText>
              )}
            </FormControl>

            <TextField
              name="title"
              label="Notification Title"
              value={values.title}
              error={Boolean(touched.title && errors.title)}
              fullWidth
              helperText={touched.title && errors.title}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <TextField
              name="message"
              label="Notification Message"
              value={values.message}
              error={Boolean(touched.message && errors.message)}
              fullWidth
              helperText={touched.message && errors.message}
              rows={5}
              multiline
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <DemoContainer components={["DateTimePicker"]}>
              <Box
                component="div"
                my={2}
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  dateLibInstance={defaultDayjs}
                >
                  <DateTimePicker
                    label="Notify Date & Time"
                    value={dayjs(values.notifyDate)}
                    format="MM-DD-YYYY hh:mm a"
                    onChange={(date) =>
                      setFieldValue("notifyDate", dayjs(date)?.utc(true))
                    }
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        fullWidth: true,
                        error: Boolean(touched.notifyDate && errors.notifyDate),
                        helperText: touched.notifyDate && errors.notifyDate,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </DemoContainer>

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditNotificationForm;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper as MuiPaper,
} from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { WorkoutExerciseInfo } from "../../../types/workoutExerciseInfo";
import {
  DataGridPro,
  GridColDef,
  GridEventListener,
  GridRowOrderChangeParams,
  GridRowParams,
  GridRowsProp,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

const Paper = styled(MuiPaper)(spacing);

const WorkoutExerciseInfoTable: React.FC<{
  workoutExerciseInfos: WorkoutExerciseInfo[];
  updateWorkoutExerciseInfo: (info: WorkoutExerciseInfo) => void;
  removeWorkoutExerciseInfo: (info: WorkoutExerciseInfo) => void;
  setWorkoutExerciseInfos: (infos: WorkoutExerciseInfo[]) => void;
}> = ({
  workoutExerciseInfos,
  updateWorkoutExerciseInfo,
  removeWorkoutExerciseInfo,
  setWorkoutExerciseInfos,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const [draggingRowId, setDraggingRowId] = useState<number | null>(null);
  const apiRef = useGridApiRef();

  useEffect(() => {
    const handleRowDragEnd: GridEventListener<"rowDragEnd"> = () => {
      setDraggingRowId(null);
    };

    const handleRowDragStart: GridEventListener<"rowDragStart"> = (params) => {
      setDraggingRowId(params.row.id);
    };

    const unsubscribeRowDragEnd = apiRef.current.subscribeEvent(
      "rowDragEnd",
      handleRowDragEnd
    );
    const unsubscribeRowDragStart = apiRef.current.subscribeEvent(
      "rowDragStart",
      handleRowDragStart
    );

    return () => {
      unsubscribeRowDragEnd();
      unsubscribeRowDragStart();
    };
  }, [apiRef]);

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (info: WorkoutExerciseInfo) => {
    handleMenuClose(info.id!);
    updateWorkoutExerciseInfo(info);
  };

  const handleRemoveClick = (info: WorkoutExerciseInfo) => {
    handleMenuClose(info.id!);
    removeWorkoutExerciseInfo(info);
  };

  const handleRowReorder = (params: GridRowOrderChangeParams) => {
    const reorderedData = [...workoutExerciseInfos];

    const startIndex = reorderedData.findIndex(
      (row) => row.id === params.row.id
    );
    const endIndex = params.targetIndex;

    const [removed] = reorderedData.splice(startIndex, 1);
    reorderedData.splice(endIndex, 0, removed);

    reorderedData.forEach((row, index) => {
      row.exerciseOrder = String(index + 1);
    });
    setWorkoutExerciseInfos(reorderedData);
  };

  const columns: GridColDef[] = [
    { field: "exercise", headerName: "Exercise", flex: 1 },
    { field: "numberOfSets", headerName: "Number of sets", flex: 1 },
    {
      field: "numberOfRepsPerSet",
      headerName: "Number of reps per set",
      flex: 1,
    },
    { field: "time", headerName: "Time", flex: 1 },
    { field: "restBetweenSets", headerName: "Rest between sets", flex: 1 },
    {
      field: "cardioTimeDuration",
      headerName: "Cardio time duration",
      flex: 1,
    },
    { field: "levelOfExertion", headerName: "Level of exertion", flex: 1 },
    { field: "exerciseOrder", headerName: "Exercise order", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        const workoutExerciseInfo = params.row as WorkoutExerciseInfo;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={
                anchorEl[workoutExerciseInfo.id!] ? "simple-menu" : undefined
              }
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) =>
                handleDetailButtonClick(event, workoutExerciseInfo.id!)
              }
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[workoutExerciseInfo.id!]}
              open={Boolean(anchorEl[workoutExerciseInfo.id!])}
              onClose={() => handleMenuClose(workoutExerciseInfo.id!)}
            >
              <MenuItem
                onClick={() =>
                  handleUpdateClick(
                    workoutExerciseInfos.find(
                      (info) => info.id === workoutExerciseInfo.id
                    )!
                  )
                }
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() =>
                  handleRemoveClick(
                    workoutExerciseInfos.find(
                      (info) => info.id === workoutExerciseInfo.id
                    )!
                  )
                }
              >
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  function generateTempID() {
    return Date.now() * 1000 + Math.floor(Math.random() * 1000);
  }

  const rows: GridRowsProp = workoutExerciseInfos
    .sort((a, b) => Number(a?.exerciseOrder) - Number(b?.exerciseOrder))
    .map((info) => {
      return {
        id: info.id,
        exercise: info.exercise?.name,
        numberOfSets: info.numberOfSets,
        numberOfRepsPerSet: info.numberOfRepsPerSet,
        time: info.time,
        restBetweenSets: info.restBetweenSets,
        cardioTimeDuration: info.cardioTimeDuration,
        levelOfExertion: info.levelOfExertion,
        exerciseOrder: info.exerciseOrder,
      };
    });

  return (
    <Paper>
      <DataGridPro
        rows={rows}
        columns={columns}
        autoHeight
        disableColumnMenu
        rowReordering
        onRowOrderChange={handleRowReorder}
        rowSelection={false}
        hideFooter={true}
        apiRef={apiRef}
        getRowId={(row) => row.id || generateTempID()}
        getRowClassName={(params: GridRowParams) => {
          return params.id === draggingRowId ? "dragging-row" : "";
        }}
        sx={{
          ".MuiDataGrid-cell:focus": {
            outline: "none",
          },
          ".dragging-row": {
            outline: "solid #376fd0 1px",
          },
        }}
      />
    </Paper>
  );
};

export default WorkoutExerciseInfoTable;

import React from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";
import {
  Box,
  Button as MuiButton,
  CardContent,
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import HelpAndSupportsService from "../../services/helpAndSupportsService";
import * as Yup from "yup";
import useToaster from "../../hooks/useToaster";
import { HelpAndSupport } from "../../types/helpAndSupport";
import { CategoryType } from "../../enums/helpAndSupport";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

const validationSchema = Yup.object().shape({
  category: Yup.string().required("Required"),
  question: Yup.string().required("Required"),
  answer: Yup.string()
    .required("Required")
    .max(2000, "Answer cannot exceed 2000 characters"),
  active: Yup.bool().required("Required"),
});

type CreateEditHelpAndSupportFormProps = {
  mode: "create" | "update";
  helpAndSupport?: HelpAndSupport;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
};

function CreateEditHelpAndSupportForm({
  mode,
  helpAndSupport,
  onSubmit,
  onCancel,
}: CreateEditHelpAndSupportFormProps): JSX.Element {
  const { showToast } = useToaster();

  const initialValues: HelpAndSupport = {
    category: helpAndSupport?.category || "",
    question: helpAndSupport?.question || "",
    answer: helpAndSupport?.answer || "",
    active: helpAndSupport?.active || true,
  };

  async function handleSubmit(values: HelpAndSupport) {
    await (mode === "create"
      ? createHelpAndSupport(values)
      : updateHelpAndSupport(values, helpAndSupport?.id!)
    ).then(onSubmit);
  }

  async function createHelpAndSupport(helpAndSupport: HelpAndSupport) {
    await HelpAndSupportsService.create(helpAndSupport)
      .then(() => showToast("HelpAndSupport successfully created!", "success"))
      .catch((err) => showToast(err.message));
  }

  async function updateHelpAndSupport(
    newHelpAndSupport: HelpAndSupport,
    id: number
  ) {
    await HelpAndSupportsService.update({ ...newHelpAndSupport, id })
      .then(() => showToast("HelpAndSupport successfully updated!", "success"))
      .catch((err) => showToast(err.message));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent style={{ width: "430px" }}>
          <form onSubmit={handleSubmit}>
            <FormControl
              my={2}
              fullWidth
              error={touched.category && Boolean(errors.category)}
            >
              <InputLabel id="category-select-label">Category</InputLabel>
              <Select
                name="category"
                labelId="category-select-label"
                id="category-select"
                value={values.category}
                label="Category"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("category", e.target.value)}
              >
                {Object.keys(CategoryType).map((categoryKey) => (
                  <MenuItem key={`category-${categoryKey}`} value={categoryKey}>
                    {CategoryType[categoryKey as keyof typeof CategoryType]}
                  </MenuItem>
                ))}
              </Select>
              {touched.category && errors.category && (
                <FormHelperText>{errors.category}</FormHelperText>
              )}
            </FormControl>

            <TextField
              name="question"
              label="Question"
              value={values.question}
              error={Boolean(touched.question && errors.question)}
              fullWidth
              helperText={touched.question && errors.question}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <TextField
              name="answer"
              label="Answer"
              value={values.answer}
              error={Boolean(touched.answer && errors.answer)}
              fullWidth
              helperText={touched.answer && errors.answer}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              multiline
              my={2}
              rows={6}
            />

            <FormControl
              my={2}
              fullWidth
              error={touched.active && Boolean(errors.active)}
            >
              <InputLabel id="active-select-label">Active</InputLabel>
              <Select
                name="active"
                labelId="active-select-label"
                id="active-select"
                value={values.active.toString()}
                label="Active"
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("active", e.target.value === "true")
                }
              >
                <MenuItem value={"true"}>Active</MenuItem>
                <MenuItem value={"false"}>Inactive</MenuItem>
              </Select>

              {touched.active && errors.active && (
                <FormHelperText>{errors.active}</FormHelperText>
              )}
            </FormControl>

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditHelpAndSupportForm;

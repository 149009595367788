import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import useLoading from "../../hooks/useLoading";
import { Reward } from "../../types/reward";
import RewardsService from "../../services/rewardsService";
import { UserDTO } from "../../types/user";
import { PointsType, TransactionType } from "../../enums/reward";
import { RewardTitles } from "./RewardTitles";
import { format } from "date-fns";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function RewardsDetailsPage() {
  const { id } = useParams();
  const { setIsLoading } = useLoading();
  const [reward, setReward] = useState<Reward>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  const titles: { [key: string]: string } = RewardTitles;

  useEffect(() => {
    setIsLoading(true);
    getExercise();
  }, []);

  function getExercise() {
    RewardsService.getById(Number(id)).then((res: Reward) => {
      setReward(res);
      setIsLoading(false);
      getKeyValuesItems(res);
    });
  }

  function parseValueByEnum(key: string, value: any): string {
    switch (key) {
      case "transactionType":
        return TransactionType[value as keyof typeof TransactionType];
      case "transactionDate":
        return format(new Date(value), "MM-dd-yyyy");
      case "points":
        return String(PointsType[value as keyof typeof PointsType]);
      case "user":
        return (value as UserDTO)?.username || "";
      default:
        return String(value);
    }
  }

  function getKeyValuesItems(reward: Reward): void {
    const data = {
      ...reward,
      company: reward.user?.company.name,
      email: reward.user?.email,
    };

    const order: (keyof typeof data)[] = [
      "id",
      "transactionDate",
      "company",
      "user",
      "email",
      "points",
      "balance",
      "transactionType",
    ];

    const items = order.map((key) => ({
      key: titles[key] as string,
      value: parseValueByEnum(key, data[key]),
    }));

    setKeyValuesItems(items);
  }

  return (
    <>
      <Helmet title="Reward Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Reward #{reward?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/rewards">
          Reward
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <KeyValueComponent keyValueItems={keyValuesItems} />
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default RewardsDetailsPage;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import { GoalPlanTitles } from "./GoalPlanTitles";
import { GoalPlan } from "../../types/goalPlan";
import GoalPlansService from "../../services/goalPlansService";
import { Task } from "../../types/task";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function GoalPlanDetails() {
  const { id } = useParams();
  const [goalPlan, setGoalPlan] = useState<GoalPlan>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  useEffect(() => {
    getExercise();
  }, []);

  function getExercise() {
    GoalPlansService.getById(Number(id)).then((res: GoalPlan) => {
      setGoalPlan(res);
      getKeyValuesItems(res);
    });
  }

  const titles: { [key: string]: string } = GoalPlanTitles;

  function parseValueByEnum(
    key: string,
    value: string | number | Task
  ): JSX.Element | string {
    switch (key) {
      case "taskOne":
      case "taskTwo":
        return (value as Task)?.name || "";
      default:
        return value as string;
    }
  }

  function getKeyValuesItems(goalPlan: GoalPlan): void {
    const items = Object.entries(goalPlan).map(
      ([k, val]) =>
        ({
          key: titles[k] as string,
          value: parseValueByEnum(k, val),
        } as KeyValue)
    );
    setKeyValuesItems(items);
  }

  return (
    <>
      <Helmet title="Goal Plan Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Goal Plan #{goalPlan?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/goal-plans">
          Goal Plans
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <KeyValueComponent keyValueItems={keyValuesItems} />
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default GoalPlanDetails;

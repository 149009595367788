export const RewardTitles = {
  id: "Reward ID",
  transactionDate: "Reward Transaction Date",
  transactionType: "Transaction Type",
  points: "Reward Points",
  balance: "Reward Balance",
  company: "Company Name",
  user: "User",
  email: "Email Address",
};

import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import BodyPartsService from "../../../services/bodyPartsService";
import { BodyPart, BodyPartEnum } from "../../../types/bodyPart";
import styled from "@emotion/styled";
import { TextField as MuiTextField } from "@mui/material";
import { spacing } from "@mui/system";
import { FormikErrors, FormikTouched } from "formik";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface BodyPartsAutocompleteCheckboxProps {
  label: string;
  values: { bodyParts: BodyPart[] };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: {
    bodyParts?: string | string[] | FormikErrors<BodyPart>[] | undefined;
  };
  touched: { bodyParts?: boolean | FormikTouched<BodyPart>[] };
}

export default function BodyPartsAutocompleteCheckbox({
  label,
  values,
  setFieldValue,
  errors,
  touched,
}: BodyPartsAutocompleteCheckboxProps) {
  const [bodyParts, setBodyParts] = useState<BodyPart[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const page = 0;
      const size = 100;
      const filter = "";
      const sortOrder = "";
      const sortField = "";

      const bodyPartsData = await BodyPartsService.getBodyParts(
        page,
        size,
        filter,
        sortOrder,
        sortField
      );

      setBodyParts(
        bodyPartsData.content.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
      );
    };

    fetchData();
  }, []);

  const selectedBodyParts = bodyParts.filter((bodyPart) =>
    values.bodyParts.some((valueBodyPart) => bodyPart.id === valueBodyPart.id)
  );

  const getOptionDisabled = (option: BodyPart) => {
    if (option.name === BodyPartEnum.FullBody) {
      return false;
    }
    return values.bodyParts.some(
      (valueBodyPart) => valueBodyPart.name === BodyPartEnum.FullBody
    );
  };

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-bodyparts"
      options={bodyParts}
      getOptionLabel={(option) => option.name}
      disableCloseOnSelect
      value={selectedBodyParts}
      onChange={(event, newValue) => {
        if (newValue.some((option) => option.name === BodyPartEnum.FullBody)) {
          setFieldValue("bodyParts", [
            bodyParts.find(
              (bodyPart) => bodyPart.name === BodyPartEnum.FullBody
            ),
          ]);
        } else {
          setFieldValue("bodyParts", newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionDisabled={getOptionDisabled}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox checked={selected} style={{ marginRight: 8 }} />
          {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          my={2}
          label={label}
          error={touched.bodyParts && !!errors.bodyParts}
          helperText={
            touched.bodyParts &&
            (Array.isArray(errors.bodyParts) && errors.bodyParts.length > 0
              ? (errors.bodyParts[0] as React.ReactNode)
              : (errors.bodyParts as React.ReactNode))
          }
          variant="outlined"
        />
      )}
    />
  );
}

import React, { useLayoutEffect, useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { Workout } from "../../types/workout";
import { Equipment } from "../../types/equipment";
import { Injury } from "../../types/injury";
import { BodyPart } from "../../types/bodyPart";
import { useNavigate } from "react-router-dom";
import { WorkoutScoreLevel } from "../../enums/WorkoutScoreLevel";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import Table from "../../components/Table";
import {
  textContainsOperator,
  textEqualsOperator,
} from "../../utils/filters/textFild";
import ExerciseLocationsService from "../../services/exerciseLocationsService";
import InjuriesService from "../../services/injuriesService";
import {
  generateOptionsFromEnum,
  OptionsFromEnum,
} from "../../utils/generateOptionsFromEnum";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import EquipmentsService from "../../services/equipmentsService";
import BodyPartsService from "../../services/bodyPartsService";
import useWindowWidth from "../../hooks/useWindowSize";

type WorkoutsTableProps = {
  workouts: Workout[];
  count: number;
  loading: boolean;
  getWorkouts: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateWorkout: (workout: Workout) => void;
  duplicateWorkout: (workoutId: number) => void;
  removeWorkout: (workout: Workout) => void;
};

const WorkoutsTable: React.FC<WorkoutsTableProps> = ({
  workouts,
  count,
  loading,
  getWorkouts,
  setPageNumber,
  setElementsPerPage,
  updateWorkout,
  duplicateWorkout,
  removeWorkout,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const [locationOptions, setLocationOptions] = useState<OptionsFromEnum[]>([]);
  const [injuryOptions, setInjuryOption] = useState<OptionsFromEnum[]>([]);
  const [equipmentOptions, setEquipmentOptions] = useState<OptionsFromEnum[]>(
    []
  );
  const [bodyPartOptions, setBodyPartOptions] = useState<OptionsFromEnum[]>([]);
  const navigate = useNavigate();
  const { width: windowWidth } = useWindowWidth();
  const SCREEN_WIDTH = 2200;

  useLayoutEffect(() => {
    fetchDataForSelects();
  }, []);

  async function fetchDataForSelects() {
    const page: number = 0;
    const size: number = 100;
    const filter: string = "";
    const sortOrder: string = "";
    const sortField: string = "";

    const [equipmentsData, locationsData, injuriesData, bodyPartsData] =
      await Promise.all([
        EquipmentsService.getEquipments(
          page,
          size,
          filter,
          sortOrder,
          sortField
        ),
        ExerciseLocationsService.getLocations(
          page,
          size,
          filter,
          sortOrder,
          sortField
        ),
        InjuriesService.getInjuries(page, size, filter, sortOrder, sortField),
        BodyPartsService.getBodyParts(page, size, filter, sortOrder, sortField),
      ]);

    setEquipmentOptions(
      equipmentsData.content.map((i) => ({ value: i.name, label: i.name }))
    );
    setLocationOptions(
      locationsData.content.map((i) => ({ value: i.name, label: i.name }))
    );
    setInjuryOption(
      injuriesData.content.map((i) => ({ value: i.name, label: i.name }))
    );
    setBodyPartOptions(
      bodyPartsData.content
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        .map((i) => ({ value: i.name, label: i.name }))
    );
  }

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      navigate(`/private/workouts/${params.row.id}/details`);
    }
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    workout: Workout
  ) => {
    event.stopPropagation();
    handleMenuClose(workout.id!);
    updateWorkout(workout);
  };

  const handleDuplicateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    workout: Workout
  ) => {
    event.stopPropagation();
    handleMenuClose(workout.id!);
    duplicateWorkout(workout.id!);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    workout: Workout
  ) => {
    event.stopPropagation();
    handleMenuClose(workout.id!);
    removeWorkout(workout);
  };

  const workoutScoreLevelOptions = generateOptionsFromEnum(WorkoutScoreLevel);
  const typeOptions = [
    { value: "Weights", label: "Weights" },
    { value: "Cardio", label: "Cardio" },
  ];

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Workout ID",
      width: 100,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "name",
      headerName: "Workout Name",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "location",
      headerName: "Workout Location",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(locationOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value.name;
      },
    },
    {
      field: "type",
      headerName: "Workout Type",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [multiSelectOperator(typeOptions)],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "equipments",
      headerName: "Workout Equipment",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortable: false,
      filterOperators: [multiSelectOperator(equipmentOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.map((i: Equipment) => i.name).join(", ");
      },
    },
    {
      field: "injuries",
      headerName: "Workout Injuries",
      filterOperators: [multiSelectOperator(injuryOptions)],
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortable: false,
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.map((i: Injury) => i.name).join(", ");
      },
    },
    {
      field: "bodyParts",
      headerName: "Workout Bodyparts",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortable: false,
      filterOperators: [multiSelectOperator(bodyPartOptions)],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.map((i: BodyPart) => i.name).join(", ");
      },
    },
    {
      field: "workoutScoreLevel",
      headerName: "Workout Score Level",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      sortingOrder: ["desc", "asc"],
      filterOperators: [multiSelectOperator(workoutScoreLevelOptions)],
    },
    {
      field: "description",
      headerName: "Work Description",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "substitute",
      headerName: "Workout Substitute",
      width: windowWidth < SCREEN_WIDTH ? 150 : undefined,
      flex: windowWidth >= SCREEN_WIDTH ? 1 : undefined,
      filterOperators: [textContainsOperator, textEqualsOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const workout = params.row as Workout;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[workout.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) => handleDetailButtonClick(event, workout.id!)}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[workout.id!]}
              open={Boolean(anchorEl[workout.id!])}
              onClose={() => handleMenuClose(workout.id!)}
            >
              <MenuItem onClick={(event) => handleUpdateClick(event, workout)}>
                Edit
              </MenuItem>
              <MenuItem
                onClick={(event) => handleDuplicateClick(event, workout)}
              >
                Duplicate
              </MenuItem>
              <MenuItem onClick={(event) => handleRemoveClick(event, workout)}>
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="workouts"
      rows={workouts}
      columns={columns}
      count={count}
      loading={loading}
      getData={getWorkouts}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
    />
  );
};

export default WorkoutsTable;

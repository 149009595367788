import axios from "../utils/axios";
import { Search } from "../types/search";
import { HealthArticle } from "../types/health-article";

const HealthArticleService = {
  getHealthArticleBy: async (id: number) => {
    const response = await axios.get<HealthArticle>(
      `/api/health-article/${id}`
    );
    return response.data;
  },
  getHealthArticles: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<HealthArticle>>(
      "/api/health-article",
      {
        params: { page, size, filter, search, sortOrder, sortField },
      }
    );
    return response.data;
  },
  createHealthArticle: async (article: HealthArticle) => {
    const response = await axios.post<HealthArticle>(
      "api/health-article",
      article
    );
    return response.data;
  },
  editHealthArticle: async (article: HealthArticle) => {
    const response = await axios.put<HealthArticle>(
      `/api/health-article`,
      article
    );
    return response.data;
  },
  removeHealthArticle: async (id: number) => {
    const response = await axios.delete(`/api/health-article/${id}`);
    return response.data;
  },
  getById: async (id: number) => {
    const response = await axios.get<HealthArticle>(
      `/api/health-article/${id}`
    );
    return response.data;
  },
  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/health-article/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default HealthArticleService;

import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
} from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import useToaster from "../../hooks/useToaster";
import { WorkoutCheckin } from "../../types/workoutCheckin";
import WorkoutCheckinsService from "../../services/workoutCheckinsService";
import { Search } from "../../types/search";
import WorkoutCheckinsTable from "./WorkoutCheckinsTable";
import CreateEditWorkoutCheckinForm from "./CreateEditWorkoutCheckinForm";
import useLoading from "../../hooks/useLoading";
import exportData from "../../utils/downloadFile";
import SmallButton from "../../components/SmallButton";

const Divider = styled(MuiDivider)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const ModalButtonMedium = styled(MuiButton)`
  width: 85px;
`;
const ModalButton = styled(ModalButtonMedium)<ButtonProps>(spacing);
const Button = styled(MuiButton)<ButtonProps>(spacing);

function WorkoutCheckins() {
  const { showToast } = useToaster();
  const { setIsLoading } = useLoading();
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [selectedWorkoutCheckin, setSelectedWorkoutCheckin] =
    useState<WorkoutCheckin>();
  const [totalElements, setTotalElements] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [elementsPerPage, setElementsPerPage] = useState<number>(25);
  const [workoutCheckins, setWorkoutCheckins] = useState<WorkoutCheckin[]>([]);

  function getWorkoutCheckinsHandler(
    page: number = 0,
    size: number = 10,
    filter: string = "",
    search: string = "",
    sortOrder: string = "",
    sortField: string = ""
  ) {
    setTableLoading(true);
    WorkoutCheckinsService.getWorkoutCheckins(
      page,
      size,
      filter,
      search,
      sortOrder,
      sortField
    ).then((res: Search<WorkoutCheckin>) => {
      setWorkoutCheckins(res.content);
      setTotalElements(res.totalElements);
      setTableLoading(false);
    });
  }

  async function submitHandler() {
    getWorkoutCheckinsHandler(pageNumber, elementsPerPage, "", "", "asc", "id");
    setOpenCreateModal(false);
    setOpenUpdateModal(false);
  }

  function updateWorkoutCheckin(workoutCheckin: WorkoutCheckin) {
    setSelectedWorkoutCheckin(workoutCheckin);
    setOpenUpdateModal(true);
  }

  function removeWorkoutCheckin(workoutCheckin: WorkoutCheckin) {
    setSelectedWorkoutCheckin(workoutCheckin);
    setOpenRemoveModal(true);
  }

  async function clickRemoveHandler() {
    await WorkoutCheckinsService.remove(selectedWorkoutCheckin?.id!)
      .then(() => showToast("Workout Checkin successfully removed!", "success"))
      .then(() => setOpenRemoveModal(false))
      .then(() =>
        getWorkoutCheckinsHandler(
          pageNumber,
          elementsPerPage,
          "",
          "",
          "asc",
          "id"
        )
      )
      .catch((err) => showToast(err.message));
  }

  return (
    <>
      <Helmet title="Workout Checkins" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Workout Checkins
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <SmallButton
              mr={2}
              onClick={() =>
                exportData(
                  WorkoutCheckinsService,
                  "workoutCheckins-filters",
                  "workout-checkins.xlsx",
                  setIsLoading
                )
              }
            >
              <FileDownloadOutlinedIcon />
              Export
            </SmallButton>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenCreateModal(true)}
            >
              <AddIcon />
              New Workout Checkin
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <WorkoutCheckinsTable
            workoutCheckins={workoutCheckins}
            count={totalElements || 0}
            loading={tableLoading}
            getWorkoutCheckins={getWorkoutCheckinsHandler}
            setPageNumber={setPageNumber}
            setElementsPerPage={setElementsPerPage}
            updateWorkoutCheckin={(workoutCheckin: WorkoutCheckin) =>
              updateWorkoutCheckin(workoutCheckin)
            }
            removeWorkoutCheckin={(workoutCheckin: WorkoutCheckin) =>
              removeWorkoutCheckin(workoutCheckin)
            }
          ></WorkoutCheckinsTable>
        </Grid>
      </Grid>

      <Dialog
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Workout Checkin</DialogTitle>
        <DialogContent>
          <CreateEditWorkoutCheckinForm
            mode="create"
            onSubmit={submitHandler}
            onCancel={() => setOpenCreateModal(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        aria-labelledby="update-company-dialog-title"
      >
        <DialogTitle id="update-company-dialog-title">
          Update WorkoutCheckin
        </DialogTitle>
        <DialogContent>
          <CreateEditWorkoutCheckinForm
            mode="update"
            workoutCheckin={selectedWorkoutCheckin}
            onSubmit={submitHandler}
            onCancel={() => setOpenUpdateModal(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={openRemoveModal}
        keepMounted
        fullWidth={true}
        maxWidth="xs"
        onClose={() => setOpenRemoveModal(false)}
        aria-labelledby="remove-workoutCheckin-dialog-title"
      >
        <DialogTitle id="remove-workoutCheckin-dialog-title">
          Remove Workout Checkin
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-workoutCheckin-dialog-title">
            {`Do you want to remove Workout Checkin with Id ${selectedWorkoutCheckin?.id}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ModalButton
            color="primary"
            mt={3}
            mr={2}
            onClick={() => setOpenRemoveModal(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            color="error"
            mt={3}
            onClick={clickRemoveHandler}
          >
            Remove
          </ModalButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default WorkoutCheckins;

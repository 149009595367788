import { useContext } from "react";
import { ToasterContext } from "../contexts/ToasterContext";

const useToaster = () => {
  const context = useContext(ToasterContext);

  if (!context)
    throw new Error("ToasterContext must be placed within CompanyProvider");

  return context;
};

export default useToaster;

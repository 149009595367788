import axios from "../utils/axios";
import { Search } from "../types/search";
import { Exercise } from "../types/exercise";
import { NutritionPlan } from "../types/nutritionPlan";

const NutritionPlansService = {
  getNutritionPlans: async (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => {
    const response = await axios.get<Search<NutritionPlan>>(
      "/api/nutrition-plan",
      {
        params: { page, size, filter, search, sortOrder, sortField },
      }
    );
    return response.data;
  },

  create: async (nutritionPlan: NutritionPlan) => {
    const response = await axios.post<Search<Exercise>>(
      "/api/nutrition-plan",
      nutritionPlan
    );
    return response.data;
  },

  update: async (nutritionPlan: NutritionPlan) => {
    const response = await axios.put<NutritionPlan>(
      `/api/nutrition-plan`,
      nutritionPlan
    );
    return response.data;
  },

  remove: async (id: number) => {
    const response = await axios.delete(`/api/nutrition-plan/${id}`);
    return response.data;
  },

  getById: async (id: number) => {
    const response = await axios.get<NutritionPlan>(
      `/api/nutrition-plan/${id}`
    );
    return response.data;
  },
  export: async (filter: string) => {
    const response = await axios.get<BlobPart>(`/api/nutrition-plan/export`, {
      params: filter ? { filter } : undefined,
      responseType: "blob",
    });
    return response.data;
  },
};

export default NutritionPlansService;

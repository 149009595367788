import React from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import useToaster from "../../hooks/useToaster";
import { NutritionProgram } from "../../types/nutritionProgram";
import NutritionProgramsService from "../../services/nutritionProgramsService";
import CreateEditNutritionProgramForm from "./CreateEditNutritionProgramForm";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
function EditNutritionProgram() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showToast } = useToaster();

  function updateNutritionPlan(newNutritionPlan: NutritionProgram, id: number) {
    NutritionProgramsService.update({ ...newNutritionPlan, id })
      .then(() =>
        showToast("Nutrition Program successfully updated!", "success")
      )
      .then(() => navigate("/private/nutrition-programs"))
      .catch((err) => showToast(err.message));
  }

  function cancel() {
    navigate(-1);
  }

  return (
    <>
      <Helmet title="Edit Nutrition Program" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Nutrition Program
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/nutrition-programs">
          Nutrition Program
        </Link>
        <Typography>Edit</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <CreateEditNutritionProgramForm
                mode="update"
                nutritionProgram={state.nutritionProgram}
                onSubmit={(nutritionProgram, nutritionProgramId) =>
                  updateNutritionPlan(nutritionProgram, nutritionProgramId!)
                }
                onCancel={cancel}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default EditNutritionProgram;

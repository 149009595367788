import React from "react";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CardContent,
  FormControl as MuiFormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { GoalPlan } from "../../types/goalPlan";
import { convertCamelCaseToTitle } from "../../utils/convertCamelCaseToTitle";
import {
  GoalProgram,
  PhysicalGoalType,
  SelfImprovementGoalType,
} from "../../types/goalProgram";
import GoalPlanAutocomplete from "../../components/autocomplete/GoalPlanAutocomplete";
import * as Yup from "yup";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type CreateEditGoalProgramFormProps = {
  mode: "create" | "update";
  goalProgram?: GoalProgram;
  onSubmit: (goalProgram: GoalProgram, goalPlanId?: number) => void;
  onCancel: () => void;
};

export type GoalProgramForm = {
  name: string;
  physicalGoal: string;
  selfImprovementGoal: string;
  sundayGoalPlan?: GoalPlan;
  mondayGoalPlan?: GoalPlan;
  tuesdayGoalPlan?: GoalPlan;
  wednesdayGoalPlan?: GoalPlan;
  thursdayGoalPlan?: GoalPlan;
  fridayGoalPlan?: GoalPlan;
  saturdayGoalPlan?: GoalPlan;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  physicalGoal: Yup.string().required("Required"),
  selfImprovementGoal: Yup.string().required("Required"),
});
function CreateEditGoalProgramForm({
  mode,
  goalProgram,
  onSubmit,
  onCancel,
}: CreateEditGoalProgramFormProps): JSX.Element {
  const dailyGoalPlans = [
    "sundayGoalPlan",
    "mondayGoalPlan",
    "tuesdayGoalPlan",
    "wednesdayGoalPlan",
    "thursdayGoalPlan",
    "fridayGoalPlan",
    "saturdayGoalPlan",
  ];

  const initialValues: GoalProgramForm = {
    name: goalProgram?.name || "",
    physicalGoal: goalProgram?.physicalGoal || "",
    selfImprovementGoal: goalProgram?.selfImprovementGoal || "",
    sundayGoalPlan: goalProgram?.sundayGoalPlan || undefined,
    mondayGoalPlan: goalProgram?.mondayGoalPlan || undefined,
    tuesdayGoalPlan: goalProgram?.tuesdayGoalPlan || undefined,
    wednesdayGoalPlan: goalProgram?.wednesdayGoalPlan || undefined,
    thursdayGoalPlan: goalProgram?.thursdayGoalPlan || undefined,
    fridayGoalPlan: goalProgram?.fridayGoalPlan || undefined,
    saturdayGoalPlan: goalProgram?.saturdayGoalPlan || undefined,
  };

  async function handleSubmit(values: GoalProgramForm) {
    const preparedData: GoalProgram = {
      name: values.name,
      physicalGoal: values.physicalGoal,
      selfImprovementGoal: values.selfImprovementGoal,
      sundayGoalPlan: values.sundayGoalPlan!,
      mondayGoalPlan: values.mondayGoalPlan!,
      tuesdayGoalPlan: values.tuesdayGoalPlan!,
      wednesdayGoalPlan: values.wednesdayGoalPlan!,
      thursdayGoalPlan: values.thursdayGoalPlan!,
      fridayGoalPlan: values.fridayGoalPlan!,
      saturdayGoalPlan: values.saturdayGoalPlan!,
    } as unknown as GoalProgram;

    await (mode === "create"
      ? onSubmit(preparedData)
      : onSubmit(preparedData, goalProgram?.id!));
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <CardContent>
          <form onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Goal Program Name"
              value={values.name}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              my={2}
            />

            <FormControl
              my={2}
              fullWidth
              error={Boolean(touched.physicalGoal && errors.physicalGoal)}
            >
              <InputLabel id="physicalGoal-select-label">
                Physical Goal
              </InputLabel>
              <Select
                name="physicalGoal"
                labelId="physicalGoal-select-label"
                id="physicalGoal-select"
                value={values.physicalGoal}
                label="Physical Goal"
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("physicalGoal", e.target.value)}
              >
                {Object.entries(PhysicalGoalType).map(([key, value]) => (
                  <MenuItem key={`physicalGoal-${key}`} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {touched.physicalGoal && errors.physicalGoal && (
                <FormHelperText error>{errors.physicalGoal}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              my={2}
              fullWidth
              error={Boolean(
                touched.selfImprovementGoal && errors.selfImprovementGoal
              )}
            >
              <InputLabel id="selfImprovementGoal-select-label">
                Self Improvement Goal
              </InputLabel>
              <Select
                name="selfImprovementGoal"
                labelId="selfImprovementGoal-select-label"
                id="selfImprovementGoal-select"
                value={values.selfImprovementGoal}
                label="Self Improvement Goal"
                onBlur={handleBlur}
                onChange={(e) =>
                  setFieldValue("selfImprovementGoal", e.target.value)
                }
              >
                {Object.entries(SelfImprovementGoalType).map(([key, value]) => (
                  <MenuItem key={`physicalGoal-${key}`} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
              {touched.selfImprovementGoal && errors.selfImprovementGoal && (
                <FormHelperText error>
                  {errors.selfImprovementGoal}
                </FormHelperText>
              )}
            </FormControl>

            {dailyGoalPlans.map((plan) => (
              <GoalPlanAutocomplete
                id={plan}
                key={plan}
                label={convertCamelCaseToTitle(plan)}
                selectedOption={
                  values[plan as keyof GoalProgramForm] as GoalPlan
                }
                onOptionSelect={(value) => setFieldValue(plan, value)}
              />
            ))}

            <Box
              component="div"
              display="flex"
              justifyContent="flex-end"
              mt={6}
            >
              <Button
                variant="outlined"
                color="error"
                mt={3}
                mr={5}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary" mt={3}>
                {mode === "update" ? "Update" : "Save"}
              </Button>
            </Box>
          </form>
        </CardContent>
      )}
    </Formik>
  );
}

export default CreateEditGoalProgramForm;

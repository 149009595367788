import React, { SyntheticEvent } from "react";
import { Alert, Snackbar, SnackbarCloseReason } from "@mui/material";
import useToaster from "../hooks/useToaster";

const Toaster: React.FC = () => {
  const { getToasterValue, hideToast } = useToaster();
  const { message, open, severity } = getToasterValue();
  const handleClose = (
    event: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    hideToast();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        variant="filled"
        severity={severity}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import NutritionPlansService from "../../services/nutritionPlansService";
import { NutritionPlan } from "../../types/nutritionPlan";
import { Meal } from "../../types/meal";
import { NutritionPlanTitles } from "./NutririonPlanTitles";
import {
  DailyCaloriesType,
  ExcludedFoodType,
  MealPreferenceType,
} from "../../enums/meal";
import { WeekDayType } from "../../enums/nutritionPlan";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function NutritionPlanDetails() {
  const { id } = useParams();
  const [nutritionPlan, setNutritionPlan] = useState<NutritionPlan>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  useEffect(() => {
    getExercise();
  }, []);

  function getExercise() {
    NutritionPlansService.getById(Number(id)).then((res: NutritionPlan) => {
      setNutritionPlan(res);
      getKeyValuesItems(res);
    });
  }

  const titles: { [key: string]: string } = NutritionPlanTitles;

  function parseValueByNutritionPlan(
    key: string,
    value: string | number | Meal | NutritionPlan
  ): string {
    switch (key) {
      case "id":
      case "weekNumber":
        return (value as number).toString();
      case "mealPreference":
        return MealPreferenceType[value as keyof typeof MealPreferenceType];
      case "excludedFoods":
        return ExcludedFoodType[value as keyof typeof ExcludedFoodType];
      case "dailyCalories":
        return DailyCaloriesType[value as keyof typeof DailyCaloriesType];
      case "weekDay":
        return WeekDayType[value as keyof typeof WeekDayType];
      case "breakfast":
      case "morningSnack":
      case "lunch":
      case "afternoonSnack":
      case "dinner":
        return (value as Meal)?.name || "";
      default:
        return value as string;
    }
  }

  function getKeyValuesItems(nutritionPlan: NutritionPlan): void {
    const order: (keyof NutritionPlan)[] = [
      "id",
      "name",
      "mealPreference",
      "excludedFoods",
      "dailyCalories",
      "weekNumber",
      "weekDay",
      "breakfast",
      "morningSnack",
      "lunch",
      "afternoonSnack",
      "dinner",
    ];

    const items = order.map((key) => {
      const value = nutritionPlan[key as keyof NutritionPlan];
      if (value === undefined) {
        return undefined;
      } else {
        return {
          key: titles[key] as string,
          value: parseValueByNutritionPlan(key, value),
        } as KeyValue;
      }
    });
    setKeyValuesItems(items as KeyValue[]);
  }

  return (
    <>
      <Helmet title="Nutrition Plan Details" />
      <Typography variant="h3" gutterBottom display="inline">
        Nutrition Plan #{nutritionPlan?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/nutrition-plans">
          Nutrition Plans
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <KeyValueComponent keyValueItems={keyValuesItems} />
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default NutritionPlanDetails;

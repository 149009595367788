import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import KeyValueComponent from "../../components/KeyValueComponents";
import { KeyValue } from "../../types/components/keyValue.type";
import useLoading from "../../hooks/useLoading";
import GoalProgramsService from "../../services/goalProgramsService";
import {
  GoalProgram,
  PhysicalGoalType,
  SelfImprovementGoalType,
} from "../../types/goalProgram";
import { GoalPlan } from "../../types/goalPlan";
import {
  DailyCaloriesType,
  ExcludedFoodType,
  MealPreferenceType,
} from "../../enums/meal";
import { GoalProgramTitles } from "./GoalProgramTitles";

const Card = styled(MuiCard)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function GoalProgramDetails() {
  const { id } = useParams();
  const { setIsLoading } = useLoading();
  const [goalProgram, setGoalProgram] = useState<GoalProgram>();
  const [keyValuesItems, setKeyValuesItems] = useState<KeyValue[]>([]);
  useEffect(() => {
    setIsLoading(true);
    getCompany();
  }, []);

  function getCompany() {
    GoalProgramsService.getById(Number(id)).then((res: GoalProgram) => {
      setGoalProgram(res);
      setIsLoading(false);
      getKeyValuesItems(res);
    });
  }

  const titles: { [key: string]: string } = GoalProgramTitles;

  function parseValueByEnum(
    key: string,
    value: string | GoalPlan | number
  ): string {
    switch (key) {
      case "physicalGoal":
        return PhysicalGoalType[value as keyof typeof PhysicalGoalType];
      case "selfImprovementGoal":
        return SelfImprovementGoalType[
          value as keyof typeof SelfImprovementGoalType
        ];
      case "sundayGoalPlan":
      case "mondayGoalPlan":
      case "tuesdayGoalPlan":
      case "wednesdayGoalPlan":
      case "thursdayGoalPlan":
      case "fridayGoalPlan":
      case "saturdayGoalPlan":
        return (value as GoalPlan)?.name;
      default:
        return value as string;
    }
  }

  function getKeyValuesItems(goalProgram: GoalProgram): void {
    const order = [
      "id",
      "name",
      "physicalGoal",
      "selfImprovementGoal",
      "sundayGoalPlan",
      "mondayGoalPlan",
      "tuesdayGoalPlan",
      "wednesdayGoalPlan",
      "thursdayGoalPlan",
      "fridayGoalPlan",
      "saturdayGoalPlan",
    ];

    const items = order.map((key) => {
      const value = goalProgram[key as keyof GoalProgram];
      if (value === undefined) {
        return undefined;
      } else {
        return {
          key: titles[key] as string,
          value: parseValueByEnum(key, value),
        } as KeyValue;
      }
    });
    setKeyValuesItems(items as KeyValue[]);
  }

  return (
    <>
      <Helmet title="Goal Program Detail" />
      <Typography variant="h3" gutterBottom display="inline">
        Goal Program #{goalProgram?.id}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/private/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/private/goal-programs">
          Goal Program
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            {keyValuesItems && (
              <Card px={6} pt={6}>
                <KeyValueComponent keyValueItems={keyValuesItems} />
              </Card>
            )}
          </Shadow>
        </Grid>
      </Grid>
    </>
  );
}

export default GoalProgramDetails;
